.ant-input {
    background-color: white;
    box-shadow: none;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    max-width: 100%;
    outline: 0px;
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    padding: 8px 10px;
    border-radius: 7px;
}

.ant-input:focus,
.ant-input-focused {
    border-color: #fa7f0d !important;
    box-shadow: 0 0 0 2px rgb(250 127 13 / 0%);
    border-right-width: 1px;
    outline: 0;
}

.ant-input:hover {
    border-color: #fa7f0d !important;
    border-right-width: 1px;
}

.ant-input-number {
    background-color: white;
    box-shadow: none;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    max-width: 100%;
    outline: 0px;
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    padding: 3px 0px;
    border-radius: 7px;
}

.ant-input-password {
    background-color: white;
    box-shadow: none;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    max-width: 100%;
    outline: 0px;
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    padding: 8px 10px;
    border-radius: 7px;
}

// ant-picker ant-picker-focused
.ant-picker {
    background-color: white;
    box-shadow: none;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    max-width: 100%;
    outline: 0px;
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    padding: 8px 10px;
    border-radius: 7px;
}

.PhoneInput {
    padding: 0px 10px;
    border-radius: 7px;
    border: 1px solid #d9d9d9;
}

.PhoneInputInput {
    font-size: 14px;
    padding: 8px 10px;
    border: 1px solid #d9d9d9;
    border-top: 0px;
    border-bottom: 0px;
    border-right: 0px;
}

.PhoneInputInput:focus-visible {
    outline: 1px solid transparent;
    outline-offset: 3px;
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--primary-color);
}

.ant-radio:hover {
    border-color: var(--primary-color) !important;
    border: none !important;
}

.ant-radio-inner::after {
    background-color: var(--primary-color);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 7px;
}

.ant-select-selector:focus-within{
    box-shadow: 0 0 0 !important;
}

.ant-input-group-addon {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
    color: white;
    opacity: 0.5;
}

.ant-checkbox-checked {
    .ant-checkbox-inner {
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;
    }
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    margin: -15px -10px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 7px;
    padding: 20px;
}


.searchForm {
    position: absolute;
    float: right;
    right: 0px;
    top: -47px;
    width: 340px;

    .ant-input {
        background-color: white;
        box-shadow: none;
        box-sizing: border-box;
        color: rgb(0, 0, 0);
        max-width: 100%;
        outline: 0px;
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        padding: 5px 10px;
        border-radius: 35px;
    }
}


:where(.css-mzwlov).ant-switch.ant-switch-checked {
    background-color: var(--primary-color);
}

:where(.css-mzwlov).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background-color: var(--primary-color);
}