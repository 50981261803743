.adresse {
    .ant-input {
        background-color: white !important;
        border-radius: 7px;
        border-color: #d9d9d9 !important;
        border: 1px solid #d9d9d9 !important;
        padding: 10px 10px;
        font-family: Calibri;
        color: black !important;
    }

    .ant-input:focus,
    .ant-input-focused {
        border-color: #fa7f0d !important;
        box-shadow: 0 0 0 2px rgba(250, 127, 13, 0);
        border-right-width: 1px;
        outline: 0;
    }

    .contentInstruction {
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 20px;
        height: 500px;
    }

    ::-webkit-scrollbar {
        width: 7px;
    }

    /* Fond de la scrollbar */
    ::-webkit-scrollbar-track {
        background: white;
    }

    /* Curseur de la scrollbar */
    ::-webkit-scrollbar-thumb {
        //background: var(--primary-color);
        background: grey;
        border-radius: 10px;
    }

    /* Au survol du curseur de la scrollbar */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--yellow-color);
    }

    .closeButton {
        cursor: pointer;
        position: absolute;
        right: 20px;
        margin-top: 7px;
        background-color: gray;
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }
}

.exemple-instructions {
    margin-top: 10px;

    .title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 30px;
    }

    h3 {
        margin: initial;
    }

    .blc {
        float: right;

        .img {
            width: 150px;
            height: 100px;
            background: gray;
            border-radius: 20px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}