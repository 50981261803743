.rapport-page{
  padding-top: 130px;
  padding-left: 30px;
  font-size: 80px;
  font-family: "Times New Roman";
  .underline{
    border-bottom: 3px solid black;
  }
  .normal{
    color: #8080806b;
    opacity: 0.8;
  }
  .line{
    margin-top: 85px;
    width: 90%;
    height: 8px;
    background-color: var(--disabled-light);
    border-radius: 5px;
    position: relative;
  }
  .coverLine{
    position: relative;
    height: 8px;
    border-radius: 5px;
    width: 40%;
    background-color: white;
    box-shadow: 2px 2px 10px 2px var(--disabled-light);
    z-index: 1;
  }
  img{
    position: relative;
    z-index: 999;
    vertical-align: top;
    margin-top: -26px;
    margin-left: 39%;
    width: 45px;
  }
}