.responsive-page{
  background-color: var(--primary-color);
  height: 100vh;
  width: 100%;
  .message{
    margin-top: 10vh;
    margin-left: 15px;
    margin-right: 15px;
    background-color: white;
    border-radius: 40px;
    padding: 15px;
    text-align: center;
    .logo{
      display: block;
      img{
        width: 170px;
      }
    }
    .caution{
      display: block;
      img{
        width: 50px;
      }
    }
    .title{
      margin-top: 35px;
      font-size: 20px;
    }
    .description{
      padding: 35px 5px;
      font-size: 14px;
      line-height: 1.4;
    }
    .link{
      button{
        padding: 10px 80px;
        font-size: 16px;
        height: 45px;
      }
      margin-bottom: 30px;
    }
  }
}