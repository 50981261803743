@media screen and (max-width: 1700px){
  .equipe-page {
    margin: 0px 18px;

    .page-header {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      h1 {
        font-size: 22px;
        font-weight: 600;
      }
    }

    .switch-menu {
      margin-top: 30px;

      .menu {
        border: 1px solid #d9d9d9;
        display: inline-block;
        border-radius: 10px;
        padding: 0 0;

        .switch-menu-btn {
          display: inline-block;
          padding: 15px 25px;
        }

        .left {
          display: inline-block;
          padding: 15px 25px;
          margin-right: 5px;
        }

        .right {
          display: inline-block;
          padding: 10px 25px;
          margin-left: 5px;
        }

        .active {
          background-color: var(--primary-color);
          color: white;
          border-radius: 9px;
        }
      }
    }

    .header {
      margin-top: 30px;
      margin-bottom: 25px;

      .blc-left {
        display: inline-block;
      }

      .blc-right {
        .cree-planning {
          box-shadow: 0 0 5px var(--primary-color);
          border-radius: 5px;
          //background-color: var(--primary-color);

          .ant-btn-compact-first-item {
            background-color: rgba(255, 127, 10, 1);
            width: 200px;
            font-size: 12px;
            border-radius: 5px;
            border: none !important;
            // border-right: 0 solid white !important;
            margin-inline-end: 0 !important;
            .icon-logement-add {
              display: inline-block;
              vertical-align: top;
              width: 20px;
              padding-top: 2px;
              margin-right: 10px;

              img {
                width: 100%;
              }
            }
          }

          .ant-dropdown-trigger {
            display: none;
          }

          .ant-btn-compact-first-item:hover {
            // background-color: var(--primary-color);
            color: white;
          }

          .ant-btn-compact-last-item:hover {
            // background-color: var(--primary-color);
            color: white;
          }

          .ant-btn-compact-last-item {
            .icon-logement-add {
              display: inline-block;
              width: 20px;

              img {
                width: 100%;
              }
            }
          }
        }

        .ant-btn {
          border-radius: 0% !important
        }

        .ant-dropdown-trigger {
          background-color: var(--primary-color);
          color: white;
        }

        .ant-btn-compact-first-item {
          background-color: var(--primary-color);
          color: white;
          border-radius: 5px !important;
        }

        .ant-btn-compact-last-item {
          border-top-right-radius: 10px !important;
          border-bottom-right-radius: 10px !important;
        }
      }

      .blc-filter {
        background-color: white;
        display: inline-block;
        padding: 3px 0px 3px 14px;
        border-radius: 10px;
        border: 1px solid rgba(5, 5, 5, 0.06);
        margin-right: 14px;

        .ant-select-selector {
          padding: 0% 22px !important;
          border-radius: 10px !important;
          border: 0px !important;
          box-shadow: 0 0 0 !important;
        }

        .ant-select-focused:where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
          box-shadow: none !important;
        }
      }

      .icon {
        display: inline-block;
        vertical-align: top;
        padding-top: 2px;
        margin-right: 5px;
        width: 20px;
        height: 20px;

        img {
          width: 100%;
        }
      }
    }

    .empty-blc {
      justify-content: center;
      margin-bottom: 60px;
      margin-top: 60px;
      display: flex;

      .blc {
        text-align: left;
        width: 350px;
      }

      h4 {
        font-size: 20px;
        color: black;
        margin: inherit;
      }

      p {
        margin: 0px;
        margin-bottom: 9px;
      }

      .en-savoir-plus {
        margin-bottom: 20px;
        display: block;
      }

      .home-icon {
        margin-bottom: 6px;
        background: #f5f5f5;
        display: inline-block;
        border-radius: 7px;
        padding: 7px 11px;

        img {
          width: 30px;
        }
      }

    }

    .stats {
      .content {
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        padding: 20px 20px;

        .head {
          margin-bottom: 12px;

          .image {
            display: inline-block;
            width: 35px;
            height: 35px;
            border: 1px solid #F6F6F6;
            background-color: #F6F6F6;
            text-align: center;
            border-radius: 5px;
            padding-top: 2px;

            img {
              width: 90%;
              border-radius: 5px;
            }
          }

          .text {
            margin-left: 15px;
            vertical-align: top;
            display: inline-block;
            font-size: 11px;
            .title {
              font-size: 16px;
            }
            .description {}
          }
        }

        .statistique {
          .blockBlue {
            background-color: #EBF3FE;
            border: 1px solid #BAC8FB;
            margin-right: 10px;
          }

          .blockOrange {
            background-color: #FCF2ED;
            border: 1px solid var(--tertiary-color);
            margin-left: 10px;
          }

          .blockGreyLeft {
            background-color: #FBFBFB;
            border: 1px solid var(--disabled-light);
            margin-right: 10px;
          }

          .blockGreyRight {
            background-color: #FBFBFB;
            border: 1px solid var(--disabled-light);
            margin-left: 10px;
          }

          .blocStat {
            border-radius: 5px;
            padding: 6px 10px;

            .indication {
              position: relative;

              .info-equipe {
                position: absolute;
                right: 0;
                width: 12px;
                height: 12px;
                top: 1px;

                img {
                  width: 100%;
                }
              }
            }

            .number {
              font-size: 18px;
              text-align: center;
              padding-top: 10px;
              margin-bottom: 10px;
            }

            .label {
              font-size: 10px !important;
              text-align: center;
              margin-bottom: 10px;
            }
          }
        }
      }

      .statistiqueLeft {
        margin-right: 8px;
        background-color: white;
      }

      .statistiqueRight {
        margin-left: 8px;
        background-color: white;
      }
    }

    .content {
      padding: 40px 0;

      .equipe-table {
        .wait {
          .info-equipe {
            display: inline-block;
            margin-left: 10px;
            width: 12px;
            height: 12px;

            img {
              width: 100%;
            }
          }
        }

        .avatar {
          display: inline-block;
          width: 40px;

          img {
            width: 100%;
          }
        }

        .info {
          vertical-align: top;
          padding-left: 10px;
          display: inline-block;

          .desc {
            font-size: 12px;
          }
        }

        .icon {
          display: inline-block;
          vertical-align: top;
          width: 21px;
          height: 21px;

          img {
            width: 100%;
          }
        }

        .nbLogement {
          color: var(--primary-color);
        }

        .ant-table-selection-column {
          padding-left: 15px;
          padding-inline-start: 0;
          padding-inline-end: 0;
        }

        .ant-table-selection-column {
          padding: 0 5px !important;
          width: 10px !important;
        }

        .ant-table-thead {
          tr {
            .ant-table-cell {
              padding: 5px 10px !important;
              font-family: 'Source Sans Pro', sans-serif;
              font-weight: 600;
              font-size: 10px !important;
              color: #263754;
            }

            .ant-table-selection-column {
              padding: 0 5px !important;
              width: 10px !important;
            }
          }
        }

        tbody {
          tr {
            td {
              font-family: 'Source Sans Pro', sans-serif;
              font-weight: 400;
              color: #566F8F !important;
              font-size: 12px;
            }
          }
        }

        .tab-option {
          cursor: pointer;
          padding-top: 10px;
          padding-left: 10px;
          border-radius: 5px;
        }

        .tab-option:hover {
          background-color: var(--tertiary-color);
        }
      }
    }

  }

  .responsable-classe-select {
    top: 325px !important;
    left: 535px !important;
    width: 250px !important;
    background-color: white;

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: white !important;
    }

    .element-filter-tab {
      .icone {
        display: inline-block;
        vertical-align: top;
        width: 13px;
        margin-left: 6px;
        margin-right: 6px;
        margin-top: 1px;

        img {
          width: 100%;
        }
      }

      .text {
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  .apercu-equipes {
    .blocId {
      text-align: center;

      .image {
        display: inline-block;
        width: 150px;

        img {
          width: 100%;
        }
      }

      .name {
        margin-top: 20px;
        font-size: 18px;
        position: relative;
      }

      .corp {
        margin-top: 7px;
      }

      .stats {
        margin-top: 30px;

        .prestations {
          margin-left: 50px;
          display: inline-block;

          .number {
            color: black;
            font-size: 20px;
          }

          .label {
            color: black;
            margin-top: 5px;
          }
        }

        .traitStat {
          display: inline-block;
          margin-left: 40px;
          margin-right: 25px;
          width: 1px;
          height: 40px;
          background-color: var(--disabled-light);
        }

        .percent {
          padding-left: 10px;
          display: inline-block;

          .number {
            font-size: 20px;
            color: black;

            .icon {
              width: 17px;
              display: inline-block;
              margin-left: 10px;

              img {
                width: 100%;
              }
            }
          }

          .label {
            color: black;
            margin-top: 5px;
          }
        }
      }

      .telephone {
        margin-top: 30px;
        padding: 0 15%;
        margin-left: 20px;

        .number-chef {
          margin-top: -10px;
          margin-left: -10px;
          display: inline-block;
          width: 200px;
          vertical-align: top;
          padding-top: 0;
          padding-left: 0;

          .PhoneInputCountrySelectArrow {
            display: none;
          }

          .PhoneInputCountry {
            background-color: var(--disabled-light) !important;
            margin-right: 0 !important;
            border: 0 !important;
            padding: 3px 15px;
            border-radius: 6px 0 0 6px !important;
            border-right: 0 !important;
          }

          .PhoneInputInput {
            font-size: 17px;
            background-color: var(--disabled-light) !important;
            padding: 9px 15px 6px 0 !important;
            border-radius: 0 6px 6px 0 !important;
            border-left: 0 !important;
          }
        }
      }

      .logement-head {
        margin-top: 30px;
        background-color: var(--primary-color);
        padding: 12px 0;
        font-size: 18px;
        color: white;
        border-radius: 10px;
      }

      .tabList {
        margin-top: 30px;

        .ant-tabs-tab:hover {
          color: var(--primary-color) !important;
        }

        .ant-tabs-tab {
          padding: 8px 45px;
          font-size: 15px;
        }

        .ant-tabs-tab+.ant-tabs-tab {
          margin: 0 0 0 100px;
        }

        .ant-tabs-tab-btn {
          color: #A6A6A6;
        }

        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: var(--primary-color) !important;
          }
        }

        .ant-tabs-ink-bar {
          background-color: var(--primary-color) !important;
        }

        .tout {
          padding: 30px 0;

          .disclaimer {
            border: 1px solid var(--disabled-light);
            color: #98A1AC;
            border-radius: 10px;
            text-align: center;
            padding: 15px 0;
            margin-bottom: 50px;
          }

          .listElement {
            text-align: left;

            .element {
              .icone {
                display: inline-block;
                width: 20px;

                img {
                  width: 100%;
                }
              }

              .zone {
                display: inline-block;
                margin-left: 20px;
              }

              .ville {
                display: inline-block;
                margin-left: 10px;
                margin-right: 15px;
              }

              .forfait {
                margin-left: 15px;
                display: inline-block;
                color: #98A1AC;
              }
            }
          }
        }

        .tarifsLibres {
          padding: 30px 0;

          .disclaimer {
            border: 1px solid var(--disabled-light);
            color: #98A1AC;
            border-radius: 10px;
            text-align: center;
            padding: 15px 0;
            margin-bottom: 50px;
          }

          .listElement {
            text-align: left;

            .element {
              .icone {
                display: inline-block;
                width: 25px;
                text-align: center;
                font-size: 14px;
                padding-top: 4px;
                border: 1px solid var(--disabled);
                border-radius: 5px;
                cursor: pointer;

                img {
                  width: 100%;
                }
              }

              .zone {
                display: inline-block;
                background-color: var(--disabled-light);
                color: #494949;
                border: 1px solid var(--disabled-light);
                border-radius: 10px;
                font-size: 16px;
                padding: 7px 20px;
                width: 28%;
                margin-right: 4%;
              }

              .villeBoss {
                display: inline-block;
                width: 15%;
                margin-right: 10%;
                vertical-align: top;

                .ville {
                  width: 100%;
                  border: 1px solid var(--disabled);
                  border-radius: 10px;
                  padding: 4px 15px;
                  vertical-align: top;
                  font-size: 16px;

                  .text {
                    vertical-align: top;
                    padding-top: 4px;
                    display: inline-block;
                  }

                  .iconMore {
                    padding-top: 5px;
                    padding-left: 6px;
                    vertical-align: top;
                    display: inline-block;

                    img {
                      display: inline-block;
                      vertical-align: top;
                      width: 16px;
                      cursor: pointer;
                    }
                  }

                }

                .span {
                  text-align: center;
                  font-size: 12px;
                  padding: 0 2px;
                  color: #98A1AC;
                  line-height: 1.3;
                  width: 150%;
                }
              }

              .forfaitBoss {
                display: inline-block;
                width: 20%;
                margin-right: 3%;
                vertical-align: top;

                .forfait {
                  font-size: 16px;
                  background-color: var(--disabled-light);
                  color: #494949;
                  border: 1px solid var(--disabled-light);
                  border-radius: 10px;
                  padding: 6px 20px;
                }

                .span {
                  text-align: center;
                  font-size: 12px;
                  padding: 0 2px;
                  color: #98A1AC;
                  line-height: 1.3;
                }
              }
            }
          }

          .add {
            margin-top: 30px;
            text-align: center;
            padding: 10px 0;
            color: var(--primary-color);
            cursor: pointer;
          }
        }
      }
    }
  }

  .equipe-custom-drawer-class {
    .ant-drawer-header {
      border: 0 !important;
    }
  }

  .choisir-logement2 {
    .choisir {
      height: 450px !important;

      .tabList {
        margin-top: 30px;

        .ant-tabs-tab:hover {
          color: var(--primary-color) !important;
        }

        .ant-tabs-tab {
          padding: 8px 10px;
          font-size: 15px;
        }

        .ant-tabs-tab+.ant-tabs-tab {
          margin: 0 0 0 50px;
        }

        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: var(--primary-color) !important;
          }
        }

        .ant-tabs-ink-bar {
          background-color: var(--primary-color) !important;
        }
      }
    }

  }

  .choisir-logement {
    border-radius: 25px !important;
    height: 250px;

    .ant-modal-content {
      border-radius: 15px !important;
      padding: 10px 20px;
    }

    .result {
      margin-bottom: 10px;
      vertical-align: top;

      .icone {
        display: inline-block;
        width: 20px;
        vertical-align: top;
        padding-top: 8px;

        img {
          width: 100%;
        }
      }

      .text {
        margin-left: 5px;
        display: inline-block;

        .quartier {}

        .address {
          font-size: 11px;
        }
      }

      .check {
        vertical-align: top;
        padding-top: 10px;
        float: right;
        display: inline-block;

        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
        .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
          border: 1px solid var(--primary-color) !important;
        }

        input[type="checkbox"]:checked {
          background-color: var(--primary-color) !important;
        }

        input[type="checkbox"] {
          background-color: var(--primary-color) !important;
        }
      }
    }

    .choisir {
      padding: 1px 1px 1px 1px;
      height: 350px;
      position: relative;

      .head {
        vertical-align: top;

        .arrow {
          width: 5%;
          font-weight: bold;
          display: inline-block;
        }

        .title {
          width: 85%;
          font-weight: bold;
          margin-left: 20px;
          display: inline-block;
          vertical-align: top;

          .title-in {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 0px;
          }
        }
      }

      .separator {
        text-align: center;
        padding-top: 10px;
      }

      .signe {
        padding: 6px 7px;
        font-size: 19px;
      }

      .footer-modal {
        position: absolute;
        width: 98%;
        bottom: 10px;

        .button-left {
          display: flex;
          justify-content: flex-start;
          padding-left: 10px;
        }

        .button-right {
          display: flex;
          justify-content: flex-end;
        }

        .suivant {
          height: 20px;
          background-color: var(--primary-color);
          padding: 7px 20px 10px 20px;
          color: white;
          font-size: 16px;
          text-align: center;
          font-weight: 500;
          border-radius: 7px;
          width: 100%;
          //box-shadow: 0 0 5px var(--primary-color);
        }

        .modifier {
          height: 18px;
          background-color: white;
          padding: 7px 20px 10px 20px;
          color: var(--primary-color);
          font-weight: 500;
          border: 1px solid var(--primary-color);
          border-radius: 7px;
        }
      }
    }
  }

  .ant-layout-content:has(.addtierce-page) {
    margin: 0 0 0 2px !important;
  }

  .addtierce-page {
    height: 100vh;

    .ant-layout-header {
      padding: 0 0 !important;

      .titre {
        padding-left: 40px;
        font-size: 30px;
      }
    }

    .context {
      height: 87vh;
      margin: 2px 0 0 2px !important;
      background-color: white;

      .content-in {
        padding: 50px 40px;

        .head {
          margin-top: 10px;
          margin-bottom: 40px;
          vertical-align: top;

          .image {
            display: inline-block;
            width: 100px;

            img {
              width: 100%;
              border-radius: 50%;
            }
          }

          .name {
            vertical-align: top;
            padding-top: 30px;
            padding-left: 20px;
            display: inline-block;
            font-size: 30px;
          }
        }

        .titlePage {
          font-size: 40px;
          margin-bottom: 20px;
        }

        .subTitle {
          font-size: 20px;
          color: #9AA1AB;
          margin-bottom: 70px;
          padding-right: 19%;

          a {
            display: block;
            margin-top: 5px;
          }
        }

        .choice {
          .blockChoice.active {
            border: 1px solid var(--primary-color) !important;
          }

          .blockChoice {
            cursor: pointer;
            border: 1px solid var(--disabled-light);
            margin-right: 50px;
            padding: 20px 20px 40px 20px;
            margin-bottom: 50px;
            border-radius: 20px;

            .image {
              width: 80px;
              height: 55px;
              border: 1px solid var(--disabled-light);
              text-align: center;
              border-radius: 10px;
              padding-top: 10px;

              img {
                width: 45%;
              }
            }

            .blockTitle {
              margin-top: 15px;
              margin-bottom: 15px;
              font-size: 19px;
            }

            .blockDesc {
              font-size: 17px;
              color: #A7A7A7;
            }
          }
        }

        .form-partenaire {
          label {
            font-size: 19px;
          }

          input,
          .phone-input {
            margin-top: 8px;
          }

          input {
            font-size: 15px;
            padding-top: 12px;
            height: 34px;
            background-color: #f5f5f5 !important;
            border: 1px solid rgba(239, 239, 239, 0.431372549) !important;
          }

          .PhoneInput {
            //background-color: #EFEFEF !important;
            background-color: white !important;
            //padding: 3px 10px;
            border-radius: 7px;
            padding-left: 0 !important;
            padding-right: 0 !important;
            border: 0 !important;
          }

          .PhoneInputCountry {
            background-color: #f5f5f5 !important;
            border: 1px solid rgba(239, 239, 239, 0.431372549) !important;
            margin-right: 10px !important;
            padding: 2px 15px;
            border-radius: 6px;
            height: 35px;
          }

          input.PhoneInputInput {
            margin-top: 0 !important;
          }

          .PhoneInputInput {
            background-color: #f5f5f5 !important;
            border: 1px solid rgba(239, 239, 239, 0.431372549) !important;
            border-radius: 6px;
            height: 34px;
            padding-left: 70px !important;
            padding-right: 34px !important;
          }

          .PhoneInputCountrySelectArrow {
            margin-left: 15px !important;
          }

          .PhoneInputCountryIcon--border {
            background-color: transparent;
            box-shadow: none;
          }

          .PhoneInputCountryIcon--border {
            background-color: transparent !important;
            box-shadow: 0 0 0 transparent !important;
          }
        }

        .form-partenaire-remuneration {
          label {
            font-size: 19px;
          }

          .search-input {
            margin-top: 15px;
            border: 1px solid rgba(239, 239, 239, 0.931372549) !important;
            box-shadow: 0 0 0 !important;

            input {
              margin-top: 0;
              background-color: white !important;
            }
          }

          .block-tarif {
            margin-top: 80px;

            .label {
              color: var(--disabled);
            }

            .logement {
              margin-right: 25px;
            }

            p {
              color: #A7A7A7 !important;
              text-align: center;
              font-size: 13px;
            }

            .label2 {
              color: #A7A7A7;
              text-align: center;
            }

            .input.textcenter {
              text-align: center;
            }

            .input {
              height: 34px;
              margin-top: 10px;
              color: grey;
              background-color: #f5f5f5 !important;
              border: 1px solid #f5f5f5 !important;
              padding: 6px 13px 0 13px;
              border-radius: 8px;
            }

            .input-clear {
              height: 34px;
              text-align: center;
              margin-top: 10px;
              background-color: white !important;
              border: 1px solid #f5f5f5 !important;
              padding: 5px 13px 0 13px;
              border-radius: 6px;

              .iconMore {
                cursor: pointer;
                padding-top: 1px;
                padding-left: 10px;
                vertical-align: top;
                display: inline-block;

                img {
                  display: inline-block;
                  margin-top: 0px;
                  vertical-align: top;
                  width: 17px;
                  cursor: pointer;
                }
              }
            }

            .close {
              cursor: pointer;
              margin-left: 10px;
              margin-top: 38px;
              border: 1px solid #f5f5f5 !important;
              display: inline-block;
              border-radius: 5px;
              width: 25px;
              height: 25px;
              text-align: center;
              padding: 1px 4px;
            }
          }

          .info-remuneration {
            vertical-align: top;
            margin-left: 20px;
            display: inline-block;
            width: 12px;

            img {
              width: 100%;
            }
          }

          .info-remuneration-ttc {
            vertical-align: top;
            margin-left: 7px;
            display: inline-block;
            width: 12px;

            img {
              width: 100%;
            }
          }
        }

        .invitationSend {
          margin-top: 150px;
          text-align: center;

          .image {
            margin-bottom: 15px;

            img {
              width: 400px;
            }
          }

          .titre {
            color: #70E6A7;
            font-size: 27px;
            margin-bottom: 15px;
          }

          .message {
            color: #595959;
            font-size: 18px;
            margin-bottom: 15px;
          }

          .greet {
            color: black;
            font-size: 18px;
          }
        }
      }
    }

    .footer {
      bottom: -3.7vh !important;
      height: 7.5vh;
      width: 100%;
      margin: 2px 0 0 2px !important;
      background-color: white;
      padding: 25px 75px;
      text-align: center;

      .button-retour {
        vertical-align: top;
        float: left;
        font-size: 18px;
        border: 1px solid var(--disabled);
        border-radius: 50px;
        padding: 12px 25px;
        cursor: pointer;

        svg {
          vertical-align: top;
          margin-top: 3px;
          margin-right: 10px;
        }
      }

      .button-next {
        float: right;
        cursor: pointer;
        font-size: 18px;
        border: 1px solid var(--primary-color);
        background-color: var(--primary-color);
        color: white;
        border-radius: 50px;
        padding: 12px 30px;

        a {
          color: white;
        }
      }

      .button-next-center {
        text-align: center;
        display: inline-block;
        cursor: pointer;
        font-size: 18px;
        border: 1px solid var(--primary-color);
        background-color: var(--primary-color);
        color: white;
        border-radius: 50px;
        padding: 12px 30px;

        a {
          color: white;
        }
      }
    }
  }

  .infoRemuneration {
    color: white !important;

    .ant-popover-arrow {
      top: 1px !important;
    }

    .ant-popover-inner {
      background-color: var(--text-gray-color);
      color: white !important;
      border-radius: 25px;
      padding: 1px 8px;

      .ant-popover-inner-content {
        color: white !important;

        .infoRemu {
          p {
            color: white !important;
            font-size: 14px;
            text-align: center;
          }
        }
      }
    }

    .ant-popover-arrow::before,
    .ant-popover-arrow::after {
      background-color: var(--text-gray-color);
    }
  }
}