@media screen and (max-width: 1700px){
  .parrainage-page{
    margin: 0px 18px;

    .page-header {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 10px;
      h1 {
        font-size: 22px;
        font-weight: 600;
      }
    }

    .content-parrainage{
      background-color: white;
      width: 100%;
      border-radius: 10px;
      padding: 30px 40px;
      .titre{
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
        span{
          color: var(--primary-color);
        }
      }
      .explication{
        p{
          font-size: 13px;
        }
        a{
          color: black !important;
          font-size: 10px;
        }
        .linkIN{
          display: inline-block;
          color: black !important;
          border-bottom: 3px solid black;
        }
      }
      .percent{

      }
      .size{
        clear: both;
        display: block;
        width: 100%;
        height: 20px;
        .left{
          float: left;
          font-size: 20px;
        }
        .right{
          float: right;
          font-size: 20px;
        }
      }
      .share{
        margin-right: 20px;
        margin-top: 60px;
        padding: 15px 12px 10px 15px;
        border: 1px solid var(--disabled);
        border-radius: 10px;
        vertical-align: top;
        font-size: 13px;
        .button{
          margin-top: -10px;
          height: 35px;
          width: 80px;
          float: right;
          font-size: 17px;
        }
      }
      .shareText{
        margin-top: 20px;
      }
      .headerDown{
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
        margin-bottom: 50px;
      }
      .number{
        border: 3px solid black;
        border-radius: 50%;
        display: inline-block;
        width: 35px;
        height: 35px;
        text-align: center;
        vertical-align: top;
        font-size: 20px;
        padding-top: 5px;
      }
      .title{
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 15px;
      }
      .description{
        font-size: 13px;
      }
    }

    .tabZone{
      margin-top: 40px;
      .parrainage-table{
        .ant-table-selection-column {
          padding-left: 15px;
        }

        .ant-table-thead {
          tr {
            .ant-table-cell {
              padding: 4px 16px !important;
              font-family: 'Source Sans Pro', sans-serif;
              font-weight: 600;
              color: #263754;
            }
          }
          .statusHeadColClass{
            .info-logement {
              display: inline-block;
              vertical-align: top;
              margin-left: 10px;
              width: 12px;
              height: 12px;
              padding-top: 2px;

              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .partagerModal{
    .ant-modal-close{
      left: 25px;
    }
    .partager{
      padding: 30px 0 0 0;
      height: 650px;
      position: relative;
      .title {
        text-align: left;
        font-weight: 500;
        font-size: 19px;
        line-height: 1.1;
        margin-bottom: 50px;
        .title-label {
          display: inline-block;
          vertical-align: top;
          padding-left: 10px;
        }
      }
      .element{
        padding: 5px 10px;
        .icone{
          float: right;
          width: 40px;
          vertical-align: top;
          img{
            margin-top: -5px;
            width: 70%;
          }
        }
      }
    }
  }
}