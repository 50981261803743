    :where(.css-mzwlov).ant-message .ant-message-notice-wrapper .ant-message-notice-content {
        background: #E3F0FC !important;
        border: 1.2px solid #5070e9 !important;
    }

    :where(.css-mzwlov).ant-message .ant-message-notice-wrapper .ant-message-success>.anticon {
        color: #5070e9 !important;
    }

    .ant-message-success {
        color: #5070e9 !important;
        font-weight: 500;
    }