@media screen and (max-width: 1700px){
  @import './adresse.scss';

  .logement-page {
    margin: 0px 18px;

    .CircularProgressbar-path {
      stroke: red !important;
    }

    .page-header {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      h1 {
        font-size: 22px;
        font-weight: 600;
      }
    }

    .ant-input-outlined:focus-within {
      border-color: var(--primary-color) !important;
      box-shadow: 0 0 0;
    }

    .search-input {
      input {
        font-size: 12px;
      }
    }
    .search-input:hover {
      border-color: var(--primary-color) !important;
    }

    .empty-blc {
      justify-content: center;
      margin-bottom: 60px;
      margin-top: 60px;
      display: flex;

      .blc {
        text-align: left;
        width: 350px;
      }

      h4 {
        font-size: 20px;
        color: black;
        margin: inherit;
      }

      p {
        margin: 0px;
        margin-bottom: 9px;
      }

      .en-savoir-plus {
        margin-bottom: 20px;
        display: block;
      }

      .home-icon {
        margin-bottom: 6px;
        background: #f5f5f5;
        display: inline-block;
        border-radius: 7px;
        padding: 7px 11px;

        img {
          width: 30px;
        }
      }

    }

    .blc-left {
      display: inline-block;
    }

    .blc-right {
      .cree-logement {
        box-shadow: 0 0 5px var(--primary-color);
        border-radius: 10px;

        .ant-btn-compact-first-item {
          width: 220px;
          font-size: 16px;
          border-right: 1px solid white !important;
          margin-inline-end: 0 !important;

          .icon-logement-add {
            display: inline-block;
            vertical-align: top;
            width: 30px;
            padding-top: 2px;
            margin-right: 10px;

            img {
              width: 100%;
            }
          }
        }

        .ant-btn-compact-first-item:hover {
          background-color: var(--primary-color);
          color: white;
        }

        .ant-btn-compact-last-item:hover {
          background-color: var(--primary-color);
          color: white;
        }

        .ant-btn-compact-last-item {
          .icon-logement-add {
            display: inline-block;
            width: 20px;

            img {
              width: 100%;
            }
          }
        }
      }

      .ant-btn {
        border-radius: 0% !important
      }

      .ant-dropdown-trigger {
        background-color: var(--primary-color);
        color: white;
      }

      .ant-btn-compact-first-item {
        background-color: var(--primary-color);
        color: white;
        border-radius: 5px 0px 0px 5px !important;
      }

      .ant-btn-compact-last-item {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }
    }

    .blc-filter {
      background-color: white;
      display: inline-block;
      padding: 0 0px 0 14px;
      border-radius: 6px;
      border: 1px solid rgba(5, 5, 5, 0.06);
      margin-right: 14px;
      font-size: 13px;

      .ant-select-selector {
        padding: 0% 22px !important;
        border-radius: 10px !important;
        border: 0px !important;
      }

      .ant-select-selector:focus-within {
        box-shadow: 0 0 0 !important;
      }

      .ant-select-focused:where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
        box-shadow: none !important;
      }
    }


    .logement-table {
      .ant-table-selection-column {
        padding-left: 15px;
      }

      .ant-table-thead {
        tr {
          .ant-table-cell {
            padding: 5px 16px !important;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            color: #263754;
            font-size: 10px;
          }
        }

        .statusHeadColClass {
          .info-logement {
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
            width: 12px;
            height: 12px;
            padding-top: 2px;

            img {
              width: 100%;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 400;
            color: #566F8F !important;
            font-size: 12px;
            padding: 7px 16px;
            .logement-libelle-table{
              font-size: 12px;
            }
            .assignation-en-cour{
              padding: 0 0 0 10px;
              background-color: rgb(255, 181, 44);
              display: inline-flex;
              border-radius: 20px;
              .texte{
                display: inline-block;
                vertical-align: top;
                margin-top: 5px;
                color: black;
                font-size: 10px;
                font-weight: 600;
              }
              .ant-avatar{
                width: 25px;
                height: 25px;
                margin-left: 15px;
                margin-right: -2px;
              }
            }
            .offre-envoyee{
              padding: 0 0 0 10px;
              background-color: var(--disabled-light);
              display: inline-flex;
              border-radius: 15px;
              .texte{
                display: inline-block;
                vertical-align: top;
                margin-top: 5px;
                color: black;
                font-size: 10px;
                font-weight: 600;
              }
              .ant-avatar{
                width: 25px;
                height: 25px;
                margin-left: 15px;
                margin-right: -2px;
              }
            }
          }
        }
      }

      .tab-option {
        cursor: pointer;
        padding-top: 10px;
        padding-left: 10px;
        border-radius: 5px;
      }

      .tab-option:hover {
        background-color: var(--tertiary-color);
      }
    }

    .tarif-reduit {
      background-color: #DBEDD0;
      border-radius: 6px;
      text-align: center;
      color: #38B962;
      font-weight: 600;
      padding: 6px 0px;
    }

    .selectBar {
      position: absolute;
      bottom: 50px;

      .contentBar {
        color: white;
        padding: 8px 10px 10px 32px;
        background-color: var(--primary-color);
        height: 40px;
        width: 600px;
        border-radius: 50px;
        font-size: 12px;
        margin-left: 30%;
        box-shadow: 0 5px 5px var(--tertiary-color);

        .disable-selectBar {
          opacity: 0.4;
          cursor: default !important;
        }

        .selection {
          padding-top: 2px;
          vertical-align: top;
          display: inline-block;
        }

        .divider {
          margin-top: -2px;
          margin-left: 20px;
          margin-right: 20px;
          display: inline-block;
          height: 25px;
          width: 1px;
          background-color: white;
        }

        .demande-equipe {
          cursor: pointer;
          padding-top: 2px;
          vertical-align: top;
          display: inline-block;

          .image {
            vertical-align: top;
            display: inline-block;
            margin-right: 2px;
            width: 15px;
            margin-top: 1px;

            img {
              width: 100%;
            }
          }
        }
      }
    }

    .ant-select-selection-item {
      color: #213856;

      .element-filter-tab {
        .text {
          color: #213856;
        }
      }
    }
  }

  .logement-create {
    top: 30px;

    .logement-input {
      .ant-input {
        font-family: Calibri, serif !important;
        text-align: center;
        background-color: white !important;
        padding: 10px 10px;
        font-size: 16px;
      }
    }


    .ant-modal-content {
      border-radius: 10px !important;
      padding: 0;
      height: 95vh;
    }

    .ant-modal-body {
      height: 100%;
      overflow: hidden;
      border-radius: 10px !important;
    }

    .blc-left {
      padding: 20px;
      background-color: white;
      height: 100%;
      border-radius: 10px !important;
      font-family: 'Source Sans Pro', sans-serif !important;

      .icon-sidebar {
        width: 18px;
        margin-right: 6px;
      }

      .title {
        border: 1px solid #80808038;
        padding: 10px;
        text-align: center;
        font-size: 12px;
        border-radius: 10px;
      }
      .title:has(.setNewName){
        padding: 5px;
      }

      .time {
        margin-top: 5px;
        text-align: center;
        position: relative;
        font-size: 12px;
        .info-logement {
          position: absolute;
          right: 0;
          width: 9px;
          height: 9px;
          top: 1px;

          img {
            width: 100%;
          }
        }
      }


      .menu {
        margin-top: 70px;

        h4 {
          color: #9AA1AB;
          margin: 10px 0px;
          font-weight: 400;
          margin-top: 20px;
          font-size: 14px;
        }

        ul {
          list-style: none;
          padding: 0px;

          .active,
          .active:hover {
            background: rgba(249, 235, 224, 0.5);
            padding: 5px 10px;
            border-radius: 7px;
            font-weight: 600;
            color: #F67504;
          }

          li {
            font-weight: 400;
            padding: 5px 10px;
            border-radius: 9px;
            margin-bottom: 8px;
            color: #203864;
            cursor: pointer;
            display: flex;
            align-items: center;
            position: relative;
            font-size: 12px;
            .check-image {
              position: absolute;
              width: 18px;
              height: 20px;
              right: 8px;
              top: 7.5px;
              z-index: 999;

              img {
                width: 100%;
              }
            }
          }

          li:hover {
            background-color: rgba(128, 128, 128, 0.1196078431);
          }
        }
      }
    }

    .blc-right {
      background-color: #FBFBFB;
      padding: 30px 30px 30px 50px;
      //height: 900px;
      height: 150%;
      overflow-y: scroll;

      // border-radius: 10px !important;
      input,
      textarea {
        border-color: #EDEDED !important;
      }

      input.ant-input-focused,
      input.ant-input-focus,
      textarea.ant-input-focused,
      textarea:focus,
      input:focus {
        border: 1px solid var(--primary-color) !important;
        box-shadow: 1px 1px 8px 0.2px var(--primary-color);
      }
      .pac-target-input{
        margin-top: 15px;
        height: 30px;
      }

      input::placeholder,
      textarea::placeholder {
        color: #ADB2BB !important;
      }

      .title {
        font-size: 20px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        color: #213856;
      }

      .subTitle {
        margin-top: 5px;
        font-size: 15px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 400;
        color: #213856;
      }

      .blc-content {
        background-color: white;
        border-radius: 15px;
        padding: 26px 16px 26px 30px;
        margin-top: 16px;
      }

      .blc-content-no-text {
        padding: 16px 16px 16px 16px !important;
      }

      .ajouter-instruction {
        border-radius: 5px;
        border: 1px solid #EDEDED;
        padding: 10px;
        display: inline;
        cursor: pointer;
        font-size: 11px;
        span {
          color: var(--primary-color);
        }
      }

      .ajouter-photo {
        border-radius: 5px;
        border: 1px solid #EDEDED;
        padding: 10px;
        display: block;
        cursor: pointer;
        text-align: center;

        span {
          color: var(--primary-color);
        }
      }

      p.mintitle {
        color: #213856 !important;
        font-size: 12px;
        .info-logement {
          display: inline-block;
          vertical-align: top;
          margin-left: 6px;
          width: 12px;
          height: 12px;
          padding-top: 2px;

          img {
            width: 100%;
          }
        }
      }

      p {
        padding: 0px;
        margin: auto;
        font-size: 12px;
      }

      span {
        color: #9AA1AB;
        font-size: 12px;
      }

      .blc {
        p {
          text-align: center;
        }

        .content {
          border-radius: 16px;
          border: 1px solid #00000014;
          padding: 16px;
          height: 212px;
          color: #0000004d;
        }
      }

      .add-step {
        display: flex;
        align-items: center;
        height: 245px;

        span {
          color: #0a94ff;
          cursor: pointer;
        }
      }
    }

    .blc-right::-webkit-scrollbar {
      display: none;
    }

    .blc-right {
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

  }

  .surface-a-nettoyer {
    height: 100vh !important;

    .blc-content {
      background-color: white;
      border-radius: 10px !important;
      padding: 26px 16px;
      margin-top: 16px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .contour-plein-gris {
        margin-bottom: 10px;
        border: 1px solid rgba(128, 128, 128, 0.2);
        border-radius: 10px;
        padding: 10px;

        p {
          padding-top: 6px;
        }
      }

      .contour-point-gris {
        margin-bottom: 10px;
        border: 1px dashed rgba(128, 128, 128, 0.2);
        border-radius: 10px;
        padding: 10px;
        height: 80px;

        p {
          padding-top: 6px;
        }
      }
    }

    .surface-a-nettoyer-first-p {
      color: #5B6E8C !important;
      font-weight: 400 !important;
      font-family: 'Calibri';
    }

    .surface-a-nettoyer-second-p {
      color:var(--text-gray-color) !important;

      p {
        color: var(--text-gray-color) !important;
      }
    }

    input,
    textarea {
      font-family: 'Calibri';
      font-weight: 300 !important;
    }
  }

  .gestion-linge {
    .switch-side.first {
      padding-top: 10px;
    }

    .switch-side {
      padding-top: 30px;
    }

    .longue-duree {
      float: right;
      margin-right: 5px;
      display: inline-block;

      span {
        margin-left: 15px;
      }
    }

    .info-linge {
      display: inline-block;
      vertical-align: top;
      margin-top: 2px;
      margin-left: 10px;
      width: 12px;
      height: 12px;

      img {
        width: 100%;
      }
    }
  }

  .gestion-complete {
    height: inherit !important;

    .contour-point-gris {
      height: 30px;
    }

    .moins {
      // user-select: none;
      padding: 5.5px 3px 5.5px 5px;
      border-radius: 10px 0 0 10px;
      border: 1px solid rgba(128, 128, 128, 0.2);
      cursor: pointer;
      font-size: 12px;
    }

    .plus {
      // user-select: none;
      padding: 5.5px 6px 5.5px 2px;
      border-radius: 0 10px 10px 0;
      border: 1px solid rgba(128, 128, 128, 0.2);
      cursor: pointer;
      font-size: 12px;
    }

    .subTitle {
      margin-top: 20px;
    }

    .blc-content {
      padding-bottom: 20px !important;
    }

    .qualite-linge-image-zone {
      width: 100%;
      margin-top: 10px;
      height: 50px;
      border-radius: 10px;
      overflow: hidden;
      border: 3px solid transparent;

      .qualite-linge-image {
        width: 100%;
        background-position: center;
      }
    }

    .qualite-linge-image-zone-selected {
      border: 3px solid var(--primary-color) !important;

      .checkButton {
        display: block !important;
      }
    }

    .qualite-linge-image-zone {
      .checkButton {
        display: none;
      }
    }

    .qualite-linge-choix {
      position: relative;

      .qualite-linge-image-zone {
        cursor: pointer;
      }

      .checkButton {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 0;
        margin-left: -8px;
        margin-top: 4px;
        z-index: 999;
        background-color: white;
        border-radius: 50%;

        img {
          width: 96%;
        }
      }
    }

    .qualite-linge-label {
      margin-top: 5px !important;
    }

    .qualite-linge-description {
      margin-bottom: 10px !important;
    }
  }

  .vous-fournissez {
    margin-top: 30px;
    height: inherit !important;

    p {
      margin-bottom: 10px !important;
    }
  }

  .lavomatic {
    margin-top: 30px;
    height: inherit !important;

    p {
      margin-bottom: 10px !important;
    }

    .ant-input {
      background-color: white !important;
    }
  }

  .kit-accueil {
    .blc-content {
      background-color: white;
      border-radius: 10px !important;
      padding: 26px 16px;
      margin-top: 16px;
      //desactivation de la selection du texte
      -webkit-user-select: none;
      /* Safari */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* IE 10+ */
      user-select: none;
      /* Standard syntax */

      .kit-accueil-image-zone {
        width: 100%;
        height: 80px;
        border-radius: 10px;
        overflow: hidden;

        .kit-accueil-image {
          width: 100%;
        }
      }

      .description {
        padding-left: 10px;
      }
    }

    .contour-item {
      .quantity-controls {
        vertical-align: top;
        padding-top: 30px;

        .nombreDefault.st_0 {
          margin-top: 0 !important;
        }

        .nombreDefault {
          // user-select: none;
          width: 30px;
          height: 19px !important;
          vertical-align: top;
          display: inline-block;
          text-align: center;
          padding-top: 1px;
          padding-bottom: 0;
          border-top: 1px solid rgba(128, 128, 128, 0.2);
          border-bottom: 1px solid rgba(128, 128, 128, 0.2);

          .quantity {
            font-family: Calibri;
            color: black !important;
          }
        }

        .moins {
          // user-select: none;
          padding: 5.5px 3px 5.5px 5px;
          border-radius: 10px 0 0 10px;
          border: 1px solid rgba(128, 128, 128, 0.2);
          cursor: pointer;
          margin-top: 10px;
        }

        .plus {
          // user-select: none;
          padding: 5.5px 6px 5.5px 2px;
          border-radius: 0 10px 10px 0;
          border: 1px solid rgba(128, 128, 128, 0.2);
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
  }

  .materiel-produit-menager {
    .contour-item {
      margin-bottom: 10px;
      border: 1px solid rgba(128, 128, 128, 0.2);
      border-radius: 10px;
      padding: 3px 0 8px 20px;

      p {
        padding-top: 16px;
      }

      .switch-side {
        padding-top: 16px;
      }

      .price {
        display: block;
        text-align: center;
        font-size: 1.4em;
      }

      .price-label {
        margin-top: -5px;
        display: block;
        text-align: center;
        font-size: 1.1em;
      }
    }

    .mat-border-rigth {
      padding: 7px 17px 1px 17px !important;
    }
  }

  .automatisation {
    .contour-item {
      border: 1px solid rgba(128, 128, 128, 0.2);
      border-radius: 10px;
      padding: 15px 15px;

      p {
        padding-top: 10px;
      }

      .plateforme-img {
        width: 45px;
        border-radius: 50%;
      }

      .plateforme-label {
        display: inline-block;
        vertical-align: top;
        font-weight: 600;
        font-size: 16px;
        padding-top: 12px;
        padding-left: 10px;
      }

      .plateforme-status {
        color: #00B050;
        vertical-align: top;
        padding-top: 12px;
        font-size: 15px;
        font-weight: 500;

        .icon-green-bold {
          color: #00B050 !important;
          font-weight: bold;
        }
      }
    }

    .contour-item-a {
      margin-top: 1px;
      border: 1px solid rgba(128, 128, 128, 0.2);
      border-radius: 7px;
      padding: 10px 13px;
      height: 20px;
      font-size: 12px;
    }

    .boutton-gerer {
      padding-top: 12px;

      .contour-item-a2 {
        border: 1px solid rgba(128, 128, 128, 0.2);
        border-radius: 7px;
        padding: 10px 13px;
      }
    }


    .blc-content {
      border-radius: 10px !important;
      padding: 10px;
    }

    .button-side {
      display: flex;
      justify-content: flex-end;
    }
  }

  .add-automatisation-modal {

    .add-automatisation {
      padding: 20px;
      height: 450px;

      .title {
        font-weight: 500;
        font-size: 20px;

        .title-img {
          width: 40px;
          border-radius: 50%;
        }

        .title-label {
          display: inline-block;
          vertical-align: top;
          padding-top: 7px;
          padding-left: 10px;
        }
      }

      .select-plateforme {
        span {
          font-size: 13px;
          color: #000000ad;
        }

        .ant-select-selection-placeholder {
          color: #5B6E8C !important;
        }

        .ant-select-selector:hover {
          border-color: var(--primary-color) !important;
        }

        .ant-select-focused {
          border-color: var(--primary-color) !important;
        }

        .ant-select-selector:focus-within {
          box-shadow: 0 0 0 !important;
          border-color: var(--primary-color) !important;
        }
      }


      .ant-select-item-option-content {
        img {
          width: 60px !important;
        }
      }

      .ant-select-dropdown {
        background-color: white;
      }

      .rc-virtual-list-holder-inner {
        background-color: white;
      }

      .ant-select-selection-item {
        padding-top: 8px;

        div {
          padding: 0px 10px !important;
        }
      }

      .footer-modal {
        position: absolute;
        width: 620px;
        bottom: 30px;

        .button-left {
          display: flex;
          justify-content: flex-start;
        }

        .button-right {
          display: flex;
          justify-content: flex-end;
        }

        .suivant {
          background-color: var(--primary-color);
          padding: 15px 20px;
          color: white;
          font-weight: 500;
          border-radius: 10px;
        }

        .annuler {
          padding: 15px 20px;
          color: #404040;
          font-weight: 500;
          cursor: pointer;

          span {
            color: #404040;
            padding-bottom: 2px;
            padding-left: 3px;
            padding-right: 3px;
            border-bottom: 2.3px solid #404040;
          }
        }

        .annuler-red {
          padding: 15px 20px;
          color: red !important;
          font-weight: 500;
          cursor: pointer;

          span {
            padding-bottom: 2px;
            padding-left: 3px;
            padding-right: 3px;
            border-bottom: 3px solid red;
          }
        }
      }

      .connection-plateforme {
        span {
          font-size: 12px;
          color: #000000ad;
        }

        .form-control {
          width: 60%;
        }
      }

      .verifier-plateforme {
        .button-right {
          padding-top: 10px;
          display: flex;
          justify-content: flex-end;
        }

        .ant-input {
          background-color: white !important;
        }
      }

      .synchroniser-plateforme {
        .montion-bottom {
          position: absolute;
          width: 85%;
          bottom: 100px;
          text-align: center;

          span {
            font-size: 14px;
            color: #000000ad;
          }
        }
      }

      .associer-plateforme {
        .contour-item {
          margin-bottom: 10px;
          border: 1px solid rgba(128, 128, 128, 0.2);
          border-radius: 10px;
          padding: 10px;
        }
      }

      .ajouter-ical {
        .indication-lien-ical {
          font-size: 13px;
          color: var(--primary-color);
        }

        .ant-input {
          background-color: white !important;
        }
      }
    }
  }


  .synchonisation-dropdown {
    width: 400px;

    .ant-dropdown-menu {
      padding: 15px;
    }
  }

  .equipe-de-menage {
    .boutton-demande {
      display: flex;
      justify-content: flex-end;
      padding-top: 30px;
      font-size: 12px;
      .add-team {
        cursor: pointer;
        border: 0;
        border-radius: 7px;
        padding: 10px 13px;
        font-size: 12px;
        background-color: var(--primary-color);
        color: white;
      }

      .add-team-disabled {
        font-size: 12px;
        cursor: pointer;
        border: 0;
        border-radius: 7px;
        padding: 10px 13px;
        background-color: var(--primary-color);
        color: white;
        opacity: 0.4;
      }
    }

    .blc-content-simple {
      border-radius: 10px !important;
      padding: 20px 20px 20px 20px;
      background-color: white;

      .contour-item {
        margin-bottom: 10px;
        margin-top: 10px;
        border: 1px solid rgba(128, 128, 128, 0.2);
        border-radius: 10px;
        padding: 5px 10px 0px 10px;

        .aidem-content {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          padding-left: 3.3px;
          padding-top: 3.3px;
          margin-bottom: 8px;
          display: inline-block;

          .aidem-img {
            width: 90%;
            height: 90%;
          }
        }


        .aidem-label {
          display: inline-block;
          vertical-align: top;
          padding-left: 10px;
          margin-top: 6px;
          color: #595959;
        }
      }
    }

    .blc-content {
      padding-top: 35px !important;
      padding-bottom: 35px !important;
      padding-left: 25px !important;

      .cadre-chef {
        margin-left: 40px;
        margin-right: 10px;
        display: inline-block;
        width: 120px;
        height: 120px;
        text-align: center;
        border-radius: 50%;

        .chef-img {
          width: 98%;
          height: 98%;
          border-radius: 50%;
          border: 0 !important;
        }
      }

      .name-chef {
        display: inline-block;
        vertical-align: top;
        padding-top: 20px;

        .title {
          font-size: 16px;
          font-weight: bold;
        }

        .subtitle {
          margin-top: -5px;
          font-size: 15px;
          color: rgba(128, 128, 128, 0.8);
          font-weight: 600;
        }
      }

      .number-chef {
        display: inline-block;
        width: 200px;
        vertical-align: top;
        padding-top: 20px;
        padding-left: 40px;

        .PhoneInputCountrySelectArrow {
          display: none;
        }

        .PhoneInputCountry {
          background-color: #f5f5f5 !important;
          margin-right: 0 !important;
          border-radius: 6px 0 0 6px !important;
          border-right: 0 !important;
        }

        .PhoneInputInput {
          padding: 9px 0 5px 0 !important;
          border-radius: 0 6px 6px 0 !important;
          border-left: 0 !important;
        }
      }

      .done {
        text-align: center;
        vertical-align: top;

        .icone-done {
          vertical-align: top;
          display: inline-block;
        }

        .text-done {
          margin-top: 7px;
          margin-left: 10px;
          display: inline-block;
          text-align: left;

          .title {
            font-size: 15px;
            font-weight: bold;
          }

          .subtitle {
            color: rgba(128, 128, 128, 0.6);
          }
        }
      }

      .steps {
        .active {
          background-color: #5AB379 !important;
          border: 3px solid #5AB379 !important;
        }

        .desactive {
          background-color: rgb(241, 240, 240) !important;
          border: 3px solid rgb(241, 240, 240) !important;
        }

        .line-active {
          background-color: #5AB379 !important;
        }

        .line-desactive {
          background-color: rgb(241, 240, 240) !important;
        }

        .step1 {
          display: inline-block;
          width: 20%;
          text-align: center;

          .label {
            margin-bottom: 25px;

            span {
              font-size: 20px;
              background-color: rgba(255, 127, 10, 0.2);
              color: #5AB379;
              padding: 7px 10px 7px 10px;
              border-radius: 5px;
            }
          }

          .circle {
            padding-left: 43%;

            .circle-objet {
              width: 25px;
              height: 25px;
              border: 3px solid #5AB379;
              border-radius: 50%;
              background-color: #5AB379;
              position: relative;

              img {
                width: 100%;
              }
            }

            .line-object {
              margin-top: -15%;
              margin-left: 10%;
              width: 90%;
              height: 3px;
              background-color: #5AB379;
            }
          }
        }

        .step2 {
          display: inline-block;
          width: 20%;
          text-align: center;

          .label {
            margin-bottom: 25px;

            span {
              font-size: 20px;
              background-color: rgba(255, 127, 10, 0.2);
              color: var(--primary-color);
              padding: 7px 10px 7px 10px;
              border-radius: 5px;
            }
          }

          .circle {
            .circle-objet {
              margin-left: 43%;
              width: 25px;
              height: 25px;
              border: 3px solid #DFDFDF;
              border-radius: 50%;
              position: relative;
              background-color: white;

              img {
                width: 100%;
              }
            }

            .line-object {
              margin-top: -8.5%;
              width: 100%;
              height: 3px;
              background-color: #DFDFDF;
            }
          }
        }

        .step3 {
          text-align: center;
          display: inline-block;
          width: 20%;

          .label {
            margin-bottom: 25px;

            span {
              font-size: 20px;
              background-color: rgba(255, 127, 10, 0.2);
              color: var(--primary-color);
              padding: 7px 10px 7px 10px;
              border-radius: 5px;
            }
          }

          .circle {
            padding-right: 43%;

            .circle-objet {
              margin-left: 75%;
              width: 25px;
              height: 25px;
              border: 3px solid #DFDFDF;
              border-radius: 50%;
              position: relative;
              background-color: white;

              img {
                width: 100%;
              }
            }

            .line-object {
              margin-top: -15%;
              margin-right: 10%;
              width: 90%;
              height: 3px;
              background-color: #DFDFDF;
            }
          }
        }
      }

      .step-text {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
        padding-top: 15px;

        .step-text-title {
          width: 120px;
        }

        .step-text-title-1 {
          padding-left: 17%;
        }

        .step-text-title-2 {
          padding-left: 13%;
        }

        .step-text-title-3 {
          padding-left: 9%;
        }

        .step-text-title-4 {
          padding-left: 5%;
        }
      }

      .button-add {
        padding-left: 40px;
        padding-top: 30px;
        vertical-align: top;
        display: inline-block;
      }

      .leftNC {
        display: inline-block;
        text-align: center;
        margin-left: 10px;

        .nc {
          color: rgba(128, 128, 128, 0.6);
          font-weight: 400;
          font-size: 16px;
          text-align: center;
          display: block;
        }

        .nc-label {
          font-size: 12px;
        }

        .set {
          color: black !important;
        }
      }

      .traitMiddle {
        width: 0.3px;
        height: 40px;
        margin-right: 25px;
        margin-left: 25px;
        background-color: var(--disabled-light);
        display: inline-block;
      }

      .rightNC {
        display: inline-block;
        text-align: center;

        .nc {
          color: rgba(128, 128, 128, 0.6);
          font-weight: 400;
          font-size: 16px;
          text-align: center;
          display: block;

          .rightNC-img {
            width: 19px;
            height: 19px;
            margin-left: 10px;
            vertical-align: top;
            margin-top: 1px;
          }
        }

        .nc-label {
          font-size: 12px;
        }
      }

    }

    .button-dropdown-team {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 10px;

      .menu-img {
        width: 30px;
      }
    }

    .button-dropdown-team-alone {
      position: absolute;
      top: 15px;
      right: 0;
      margin-right: 20px;

      .menu-img {
        width: 30px;
      }
    }

    .set {
      color: black !important;
      font-weight: 500;
    }

    .paiement-tier-table{
      .ant-table-thead{
        tr:first-child >*:first-child{
          border-start-start-radius: 15px !important;
        }
        tr:first-child >*:last-child{
          border-start-end-radius: 15px !important;
        }
        tr{
          th{
            padding: 8px 8px;
            font-size: 12px;
          }
        }
        .ant-table-cell:before{
          display: none;
        }
        .ant-table-cell{
          color: var(--text-gray-color);
          font-weight: 600;
        }
      }
      .actif-inactif{
        font-size: 11px;
        color: var(--disabled);
      }
      .card-zone {
        border: 1px solid rgba(241, 240, 240, 1);
        border-radius: 5px;
        padding: 2px 10px 1px 2px;
        height: 20px;
        width: 70%;
        .card-image {
          height: 20px;
          vertical-align: top;
          margin-top: 2px;
          border-radius: 5px;
          width: 100%;
          margin-left: 5px;
          img {
            margin-top: 0;
            width: 90%;
            border-radius: 5px;
          }
        }

        .card-number {
          padding-left: 8px;
          vertical-align: top;
          font-size: 11px;
          margin-top: 0;
          margin-left: 5px;
          color: var(--disabled);
        }

        .check-image {
          padding-top: 12px;
          width: 15px;

          img {
            width: 100%;
          }
        }
      }
    }
  }


  .add-equipe-modal {

    .add-equipe {
      padding: 20px;
      height: 450px;

      .title {
        font-weight: 500;
        font-size: 20px;

        .title-img {
          width: 40px;
          border-radius: 50%;
        }

        .title-label {
          display: inline-block;
          vertical-align: top;
          padding-top: 7px;
          padding-left: 10px;
        }
      }

      .dateSelect {
        font-size: 13px;
      }

      .info-equipe {
        position: absolute;
        right: 10px;
        width: 12px;
        height: 12px;
        top: 2px;

        img {
          width: 100%;
        }
      }

      .ant-picker {
        margin-top: 3px;
      }

      .ant-picker-outlined:hover {
        border-color: var(--primary-color) !important;
      }

      .ant-picker-outlined:focus-within {
        border-color: var(--primary-color) !important;
        box-shadow: 0 0 0 !important;
      }

      .steps {
        .step1 {
          display: inline-block;
          width: 33%;
          text-align: center;

          .label {
            margin-bottom: 25px;

            span {
              font-size: 20px;
              background-color: rgba(255, 127, 10, 0.2);
              color: var(--primary-color);
              padding: 7px 10px 7px 10px;
              border-radius: 5px;
            }
          }

          .circle {
            padding-left: 43%;

            .circle-objet {
              width: 25px;
              height: 25px;
              border: 3px solid var(--primary-color);
              border-radius: 50%;
              background-color: white;
              position: relative;
            }

            .line-object {
              margin-top: -15%;
              margin-left: 10%;
              width: 90%;
              height: 2px;
              background-color: var(--primary-color);
            }
          }
        }

        .step2 {
          display: inline-block;
          width: 33%;
          text-align: center;

          .label {
            margin-bottom: 25px;

            span {
              font-size: 20px;
              background-color: rgba(255, 127, 10, 0.2);
              color: var(--primary-color);
              padding: 7px 10px 7px 10px;
              border-radius: 5px;
            }
          }

          .circle {
            .circle-objet {
              margin-left: 43%;
              width: 25px;
              height: 25px;
              border: 3px solid rgba(128, 128, 128, 0.6);
              border-radius: 50%;
              position: relative;
              background-color: white;
            }

            .line-object {
              margin-top: -8.5%;
              width: 100%;
              height: 2px;
              background-color: rgba(128, 128, 128, 0.6);
            }
          }
        }

        .step3 {
          text-align: center;
          display: inline-block;
          width: 33%;

          .label {
            margin-bottom: 25px;

            span {
              font-size: 20px;
              background-color: rgba(255, 127, 10, 0.2);
              color: var(--primary-color);
              padding: 7px 10px 7px 10px;
              border-radius: 5px;
            }
          }

          .circle {
            padding-right: 43%;

            .circle-objet {
              margin-left: 75%;
              width: 25px;
              height: 25px;
              border: 3px solid rgba(128, 128, 128, 0.6);
              border-radius: 50%;
              position: relative;
              background-color: white;
            }

            .line-object {
              margin-top: -15%;
              margin-right: 10%;
              width: 90%;
              height: 2px;
              background-color: rgba(128, 128, 128, 0.6);
            }
          }
        }
      }

      .step-text-title {
        display: block;
        text-align: center;
        padding-top: 20px;
        font-size: 14px;
        font-weight: 600;
      }

      .step-text-describe {
        padding: 0 10px;
        display: block;
        text-align: center;
        padding-top: 5px;
        font-size: 11px;
        font-weight: 400;
        color: rgba(128, 128, 128, 0.9);
      }

      .ant-picker-input {
        background-color: white;

        input {
          background-color: white !important;
        }
      }

      .footer-modal {
        position: absolute;
        width: 620px;
        bottom: 30px;

        .button-left {
          display: flex;
          justify-content: flex-start;
        }

        .button-right {
          display: flex;
          justify-content: flex-end;
        }

        .suivant {
          background-color: var(--primary-color);
          padding: 15px 20px;
          color: white;
          font-weight: 500;
          border-radius: 10px;
        }

        .annuler {
          padding: 15px 20px;
          color: #404040;
          font-weight: 500;
          cursor: pointer;

          span {
            color: #404040;
            padding-bottom: 2px;
            padding-left: 3px;
            padding-right: 3px;
            border-bottom: 2.3px solid #404040;
          }
        }

        .annuler-red {
          padding: 15px 20px;
          color: red !important;
          font-weight: 500;
          cursor: pointer;

          span {
            padding-bottom: 2px;
            padding-left: 3px;
            padding-right: 3px;
            border-bottom: 3px solid red;
          }
        }
      }
    }
  }

  .prestation-planning {
    .planning-table {
      .ant-table-selection-column {
        padding-left: 15px;
      }

      .ant-table-thead {
        tr {
          .ant-table-cell {
            padding: 4px 16px !important;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            color: #263754;
            font-size: 11px;
          }
        }
      }

      tbody {
        tr {
          td {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 400;
            color: #566F8F !important;
          }
        }
      }
    }


    .blc-filter {
      background-color: white;
      display: inline-block;
      padding: 3px 0px 3px 14px;
      border-radius: 10px;
      border: 1px solid rgba(5, 5, 5, 0.06);
      margin-right: 14px;

      .ant-select-selector {
        padding: 0% 22px !important;
        border-radius: 10px !important;
        border: 0px !important;
      }

      .ant-select-selector:focus-within {
        box-shadow: 0 0 0 !important;
      }

      .ant-select-focused:where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
        box-shadow: none !important;
      }
    }

    .blc-right {
      padding-top: 0 !important;
      height: auto;

      .ant-btn {
        border-radius: 0%;
      }

      .ant-dropdown-trigger {
        background-color: var(--primary-color);
        color: white;
      }

      .ant-btn-compact-first-item {
        background-color: var(--primary-color);
        color: white !important;
        border-radius: 10px 0px 0px 10px !important;
      }

      span {
        color: white !important;
      }

      .ant-btn-compact-last-item {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
    }

    .cree-logement {
      box-shadow: 0 0 5px var(--primary-color);
      border-radius: 10px !important;
      width: 220px;
      font-size: 16px;
      margin-inline-end: 0 !important;

      .icon-logement-add {
        display: inline-block;
        vertical-align: top;
        width: 30px;
        padding-top: 2px;
        margin-right: 0px;

        img {
          width: 100%;
        }
      }

      .ant-btn-compact-first-item:hover {
        background-color: var(--primary-color);
        color: white;
      }

      .ant-btn-compact-last-item:hover {
        background-color: var(--primary-color);
        color: white;
      }

      .ant-btn-compact-last-item {
        .icon-logement-add {
          display: inline-block;
          width: 20px;

          img {
            width: 100%;
          }
        }
      }
    }

    .ant-select-selection-item {
      color: #213856 !important;

      .element-filter-tab {
        .text {
          color: #213856 !important;
        }
      }
    }
  }

  .prestation-planning .blc-right .ant-btn .cree-logement {
    border-radius: 10px !important;
  }

  .empty-Tab-planning {
    text-align: left;
    padding-top: 50px;
    padding-bottom: 100px;

    .plateforme-img {
      width: 40px;
      border-radius: 10%;
    }

    .title {
      color: black;
      font-weight: 600;
      font-size: 23px !important;
    }

    p {
      font-size: 13px !important;
      padding-bottom: 6px !important;
    }

    a {
      font-size: 13px !important;
    }

    .add-prestation {
      cursor: pointer;
      border: 0;
      border-radius: 7px;
      padding: 10px 13px;
      background-color: var(--primary-color);
      color: white;
    }
  }

  .empty-Tab-planning-prestation {
    text-align: center;
    padding-top: 0;
    padding-bottom: 10px;

    .plateforme-img {
      width: 150px;
      border-radius: 10%;
    }

    .title {
      color: var(--disabled);
      font-weight: 300;
      font-size: 14px !important;
    }

    p {
      font-size: 13px !important;
      padding-bottom: 6px !important;
    }

    a {
      font-size: 13px !important;
    }

    .add-prestation {
      cursor: pointer;
      border: 0;
      border-radius: 7px;
      padding: 10px 13px;
      background-color: var(--primary-color);
      color: white;
    }
  }

  .add-logement-item-1 {
    margin-top: 10px;
  }

  .add-logement-item-2:hover {
    background-color: transparent !important;
  }

  .add-logement-item-2 {
    margin-bottom: 8px;
  }

  .add-logement-item {
    padding-top: 0;
    width: 180px;
    padding-left: 20px;
    color: var(--text-grey-color);

    .imageSide {
      display: inline-block;
      width: 20px;
      vertical-align: top;
      padding-right: 10px;

      img {
        width: 100%;
      }
    }
  }

  .ant-dropdown-menu-item:has(.add-logement2-item.disable) {
    background-color: white !important;
    cursor: default !important;
  }

  .add-logement2-item {
    padding-top: 5px;
    width: 250px;
    padding-left: 0;
    color: var(--text-grey-color);

    .imageSide.reduction-taille {
      width: 17px;
    }

    .imageSide {
      display: inline-block;
      width: 20px;
      vertical-align: top;
      padding-right: 10px;

      img {
        width: 100%;
      }
    }
  }


  .ant-select-dropdown {
    border: 1px solid var(--primary-color) !important;
  }

  .disable-primary-button {
    background-color: var(--tertiary-color) !important;
    cursor: default !important;
  }

  .logement-add-drompdown {
    padding-top: 5px;
  }

  .search-input {
    border-color: var(--disabled-light) !important;
  }
  .if1020{
    display: block !important;
  }

  .ifNormal{
    display: none !important;
  }
}