@media screen and (max-width: 1700px){
  .footer-notification{
    position: absolute;
    bottom: 0;
    font-size: 13px;
    font-weight: 300;
    height: 20px;
    text-align: center;
    color: white;
    padding-top: 4px;
    padding-bottom: 6px;
    display: inline-block;
    background-color: limegreen;
    width: 100%;
    a{
      color: white;
      font-weight: 600;
    }
    a:hover{
      color: white;
      font-weight: 600;
    }
  }
}