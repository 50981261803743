.dashboard-page {
  .page-header {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    h1 {
      font-size: 26px;
      font-weight: 600;
    }

    .icon {
      width: 25px;
      padding-right: 5px;
    }

    .iconSide {
      padding-top: 3px;
      display: inline-block;
    }
  }

  .page-content {
    margin-top: 16px;

    .section-1 {
      background-color: white;
      padding: 14px;
      border-radius: 10px;
      border: 1px solid #80808021;
      position: relative;

      .section-title {
        font-size: 16px;
        margin-bottom: 16px;
      }

      .section-title-2 {
        font-size: 16px;
        margin-top: 0px;
        color: black !important;
        font-weight: 600;
      }

      .stepStart {
        font-family: 'Source Sans Pro', sans-serif !important;
        font-weight: 400 !important;
      }

      .blc-left {
        .video {
          iframe {
            border-radius: 10px;
            height: 400px;
          }
        }
      }

      .blc-content {
        border: 1px solid #80808021;
        border-radius: 10px;
        padding: 14px;
        position: absolute;
        height: 90%;

        p {
          margin: auto;
          font-size: 16px;
          color: black !important;
        }

        ul {
          list-style: none;
          padding: 0px 0px;

          li {
            margin-bottom: 16px;
            display: flex;

            .icon {
              margin-top: 6px;
              margin-right: 6px;
              font-size: 18px;
              color: #8080805e;
            }

            a {
              color: black;
              text-decoration-line: underline;
            }

            span {
              color: var(--text-gray-color);
            }
          }
        }
      }
    }

    .section-2 {
      margin-top: 36px;

      .section-title {
        font-size: 16px;
        margin-bottom: 16px;
      }

      .blc {
        background-color: white;
        padding: 18px 15px;
        border-radius: 15px;
        border: 1px solid #80808021;

        img {
          width: 14%;
          height: 100%;
          margin-top: 7px;
        }

        img.i1 {
          width: 9%;
          height: 100%;
          margin-top: 7px;
        }

        img.i2 {
          width: 10%;
          height: 100%;
          margin-top: 7px;
        }

        .blc-text {
          margin-left: 10px;
          margin-right: 18px;
          margin-bottom: 20px;

          h4 {
            font-size: 16px;
            margin: 0px;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            color: #203864;
          }

          p {
            padding: 0px;
            color: var(--text-grey-color);
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 300;
            font-size: 15px;
            margin: 3px 3px 3px 0px;
          }
        }

        .ant-btn {
          height: 42px;
          border: 1px solid #80808021 !important;
        }

        .ant-btn:hover {
          border: 1px solid var(--primary-color) !important;
          color: var(--primary-color);
        }
      }
    }

    .section-3 {
      margin-top: 50px;

      .section-title {
        font-size: 16px;
        margin-bottom: 16px;
      }

      .blc {
        background-color: white;
        padding: 18px;
        border-radius: 15px;
        border: 1px solid #80808021;
        padding-bottom: 14px;

        .img {
          width: 450px;
          background: gray;
          border-radius: 20px;
          height: 252px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        @media screen and (max-width: 1024px){
          .blc-text {
            margin-left: 1px;
            position: relative;
          }
        }
        .blc-text {
          margin-left: 10px;
          position: relative;

          h4 {
            font-size: 25px;
            margin: 0px;
          }

          p {
            padding: 0px;
            margin: 3px;
          }

          .btn-lire {
            background-color: var(--primary-color);
            color: white;
            padding: 10px 20px;
            border-radius: 14px;
            position: absolute;
            right: 0;
            bottom: 0;
            font-weight: 700;
            font-size: 20px;
          }
        }

        .ant-btn {
          height: 42px;
          border: 1px solid #80808021 !important;
        }
      }
    }
  }
}

.evaluation {
  .ant-modal-content {
    padding: 40px;
  }

  h2 {
    margin: 0px;
  }

  h3 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  p {
    font-size: 16px;
  }

  .point-value {
    border: 1.5px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 10px;
    font-size: 20px;
    width: 38px;
    height: 45px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .active {
    color: white;
    border-radius: 10px;
    background-color: var(--primary-color);
    font-size: 20px;
    width: 38px;
    height: 45px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .btn-send {
    height: 55px;
    padding: 0px 30px;
    border-radius: 14px;
  }

  .btn-cancel {
    font-weight: 500;
    font-size: 16px;
    border-bottom: 1px solid #000;
    padding-bottom: 4px;
    cursor: pointer;
  }

  .success-icon {
    width: 120px;
  }
}

.agrandir {

  .agrandir-parc {
    padding: 20px;
    height: 770px;

    .title {
      text-align: center;
      font-weight: 500;
      font-size: 20px;

      .title-img {
        width: 40px;
        border-radius: 50%;
      }

      .title-label {
        display: inline-block;
        vertical-align: top;
        padding-top: 7px;
        padding-left: 10px;
      }
    }

    .ant-input-prefix {
      color: var(--disabled);
    }

    .secteur {
      font-size: 13px;
    }

    #secteur {
      font-family: 'Source Sans Pro', sans-serif;
      background-color: white !important;
      height: 32px;
    }

    #standing {
      font-family: 'Source Sans Pro', sans-serif;
      width: 100%;
    }

    .ant-select {
      width: 100%;
    }

    .ant-select-selector:hover {
      border-color: var(--primary-color) !important;
    }

    .ant-select-focused {
      border-color: var(--primary-color) !important;
    }

    .ant-select-selector:focus-within {
      box-shadow: 0 0 0 !important;
      border-color: var(--primary-color) !important;
    }

    .info-equipe {
      //position: absolute;
      margin-bottom: 15px;
      display: inline-block;
      vertical-align: top;
      width: 12px;
      height: 12px;
      margin-left: 6px;
      padding-top: 2.5px;

      img {
        width: 100%;
      }
    }

    .ant-input-outlined:hover,
    .ant-input-outlined:focus-within {
      border-color: var(--primary-color) !important;
      box-shadow: 0 0 0;
    }

    label {
      margin-bottom: 10px;
    }

    .steps {
      .step1 {
        display: inline-block;
        width: 33%;
        text-align: center;

        .label {
          margin-bottom: 25px;

          span {
            font-size: 20px;
            background-color: rgba(255, 127, 10, 0.2);
            color: var(--primary-color);
            padding: 7px 10px 7px 10px;
            border-radius: 5px;
          }
        }

        .circle {
          padding-left: 43%;

          .circle-objet {
            width: 25px;
            height: 25px;
            border: 3px solid var(--primary-color);
            border-radius: 50%;
            background-color: white;
            position: relative;
          }

          .line-object {
            margin-top: -15%;
            margin-left: 10%;
            width: 90%;
            height: 2px;
            background-color: var(--primary-color);
          }
        }
      }

      .step2 {
        display: inline-block;
        width: 33%;
        text-align: center;

        .label {
          margin-bottom: 25px;

          span {
            font-size: 20px;
            background-color: rgba(255, 127, 10, 0.2);
            color: var(--primary-color);
            padding: 7px 10px 7px 10px;
            border-radius: 5px;
          }
        }

        .circle {
          .circle-objet {
            margin-left: 43%;
            width: 25px;
            height: 25px;
            border: 3px solid rgba(128, 128, 128, 0.6);
            border-radius: 50%;
            position: relative;
            background-color: white;
          }

          .line-object {
            margin-top: -8.5%;
            width: 100%;
            height: 2px;
            background-color: rgba(128, 128, 128, 0.6);
          }
        }
      }

      .step3 {
        text-align: center;
        display: inline-block;
        width: 33%;

        .label {
          margin-bottom: 25px;

          span {
            font-size: 20px;
            background-color: rgba(255, 127, 10, 0.2);
            color: var(--primary-color);
            padding: 7px 10px 7px 10px;
            border-radius: 5px;
          }
        }

        .circle {
          padding-right: 43%;

          .circle-objet {
            margin-left: 75%;
            width: 25px;
            height: 25px;
            border: 3px solid rgba(128, 128, 128, 0.6);
            border-radius: 50%;
            position: relative;
            background-color: white;
          }

          .line-object {
            margin-top: -15%;
            margin-right: 10%;
            width: 90%;
            height: 2px;
            background-color: rgba(128, 128, 128, 0.6);
          }
        }
      }
    }

    .step-text-title {
      display: block;
      text-align: center;
      padding-top: 20px;
      font-size: 14px;
      font-weight: 600;
    }

    .step-text-describe {
      padding: 0 10px;
      display: block;
      text-align: center;
      padding-top: 5px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(128, 128, 128, 0.9);
    }

    .ant-picker-input {
      background-color: white;

      input {
        background-color: white !important;
      }
    }

    .footer-modal {
      position: absolute;
      width: 620px;
      bottom: 30px;

      .button-left {
        display: flex;
        justify-content: flex-start;
      }

      .button-right {
        display: flex;
        justify-content: flex-end;
      }

      .suivant {
        background-color: var(--primary-color);
        padding: 15px 20px;
        color: white;
        font-weight: 500;
        border-radius: 10px;
      }

      .annuler {
        padding: 15px 20px;
        color: #404040;
        font-weight: 500;
        cursor: pointer;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }
  }
}

.demande {

  .ant-modal-content {
    border-radius: 10px !important;
    padding: 10px 25px;
  }

  .demande-cours {
    padding: 5px 5px 5px 5px;
    height: 200px;

    .title {
      text-align: left;
      font-weight: 500;
      font-size: 20px;

      .title-img {
        display: inline-block;
        width: 22px;
        border-radius: 50%;
        padding-top: 4px;
      }

      .title-label {
        display: inline-block;
        vertical-align: top;
        padding-left: 10px;
      }
    }

    span {
      font-size: 13px;
    }

    .footer-modal {
      position: absolute;
      width: 96%;
      bottom: 15px;

      .button-left {
        display: flex;
        justify-content: flex-start;
      }

      .button-right {
        display: flex;
        justify-content: flex-end;
      }

      .suivant {
        height: 20px;
        background-color: var(--primary-color);
        padding: 7px 20px 10px 20px;
        color: white;
        font-weight: 500;
        border-radius: 7px;
      }

      .modifier {
        height: 18px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;
        cursor: pointer;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }
  }
}

.suppressionLogement {
  background-color: red;
  border-radius: 15px !important;
  height: 190px;

  .ant-modal-content {
    border-radius: 10px !important;
    padding: 10px 15px;
  }

  .supprimer {
    padding: 5px 5px 5px 5px;
    height: 150px;
    position: relative;

    .title {
      text-align: left;
      font-weight: 500;
      font-size: 20px;

      .title-img {
        display: inline-block;
        width: 22px;
        //border-radius: 50%;
        padding-top: 4px;
        margin-bottom: 5px;
      }

      .title-label {
        display: inline-block;
        vertical-align: top;
        padding-left: 10px;
      }
    }

    span {
      font-size: 13px;
    }

    .footer-modal {
      position: absolute;
      width: 100%;
      bottom: 15px;

      .button-left {
        display: flex;
        justify-content: flex-start;
      }

      .button-right {
        display: flex;
        justify-content: flex-end;
      }

      .suivant {
        height: 20px;
        background-color: red;
        padding: 7px 20px 10px 20px;
        color: white;
        font-weight: 500;
        border-radius: 7px;
        box-shadow: 0 0 5px red;
      }

      .modifier {
        height: 18px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;
        cursor: pointer;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }
  }


}

.archiverLogement {
  background-color:  var(--primary-color);
  border-radius: 15px !important;
  height: 250px;

  .ant-modal-content {
    border-radius: 10px !important;
    padding: 10px 15px;
  }

  .supprimer {
    padding: 5px 5px 5px 5px;
    height: 210px;
    position: relative;

    .title {
      text-align: left;
      font-weight: 500;
      font-size: 20px;

      .title-img {
        display: inline-block;
        width: 22px;
        //border-radius: 50%;
        padding-top: 4px;
        margin-bottom: 5px;
      }

      .title-label {
        display: inline-block;
        vertical-align: top;
        padding-left: 10px;
      }
    }

    span {
      font-size: 13px;
    }

    .footer-modal {
      position: absolute;
      width: 100%;
      bottom: 15px;

      .button-left {
        display: flex;
        justify-content: flex-start;
      }

      .button-right {
        display: flex;
        justify-content: flex-end;
      }

      .suivant {
        height: 20px;
        background-color:  var(--primary-color);
        padding: 7px 50px 10px 50px;
        color: white;
        font-weight: 500;
        border-radius: 7px;
        box-shadow: 0 0 5px  var(--primary-color);
      }

      .modifier {
        height: 18px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;
        cursor: pointer;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }

    .change-price-option{
      margin-top: 10px;
      width: 100%;

      .ant-select-selector:hover{
        border-color: var(--primary-color) !important;
      }
      .ant-select-selector:focus{
        border-color: var(--primary-color) !important;
      }
    }

  }


}
.ajusterLogement {
  background-color:  var(--primary-color);
  border-radius: 15px !important;
  height: 290px;

  .ant-modal-content {
    border-radius: 10px !important;
    padding: 10px 15px;
  }

  .supprimer {
    padding: 5px 5px 5px 5px;
    height: 250px;
    position: relative;

    .title {
      text-align: left;
      font-weight: 500;
      font-size: 20px;

      .title-img {
        display: inline-block;
        width: 22px;
        //border-radius: 50%;
        padding-top: 4px;
        margin-bottom: 5px;
      }

      .title-label {
        display: inline-block;
        vertical-align: top;
        padding-left: 10px;
      }
    }

    span {
      font-size: 13px;
    }

    .footer-modal {
      position: absolute;
      width: 100%;
      bottom: 15px;

      .button-left {
        display: flex;
        justify-content: flex-start;
      }

      .button-right {
        display: flex;
        justify-content: flex-end;
      }

      .suivant {
        height: 20px;
        background-color:  var(--primary-color);
        padding: 7px 50px 10px 50px;
        color: white;
        font-weight: 500;
        border-radius: 7px;
        box-shadow: 0 0 5px  var(--primary-color);
      }

      .modifier {
        height: 18px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;
        cursor: pointer;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }

    .change-price-option{
      margin-top: 10px;
      width: 100%;

      .ant-select-selector:hover{
        border-color: var(--primary-color) !important;
      }
      .ant-select-selector:focus{
        border-color: var(--primary-color) !important;
      }
    }

    .old-choice{
      position: absolute;
      bottom: -110px;
      list-style-type: none;
      padding: 0;
      li{
        color: red;
        font-size: 12px;
      }
      li::before {
        content: "– ";
        color: red;
      }
    }

  }


}

.suspendreLogement {
  background-color: red;
  border-radius: 15px !important;
  height: 490px;

  .ant-modal-content {
    border-radius: 10px !important;
    padding: 10px 15px;
  }

  .suspendre {
    padding: 5px 20px 5px 20px;
    height: 450px;
    position: relative;

    .title {
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      padding-top: 20px;

      .title-img {
        display: inline-block;
        width: 22px;
        //border-radius: 50%;
        padding-top: 4px;
        margin-bottom: 5px;
      }

      .title-label {
        display: inline-block;
        vertical-align: top;
        padding-left: 10px;
      }
    }

    p {
      margin-top: 20px;
      font-size: 15px !important;
      color: black !important;
    }

    .aregler {
      .titre {
        text-align: center;
        font-weight: 900;
      }

      ul {
        li {
          .facture {
            display: inline-block;
            color: var(--text-grey-color);
            margin-right: 4px;
          }

          .montant {
            margin-left: 4px;
            display: inline-block;
            margin-right: 20px;
          }

          .link {
            display: inline-block;
          }
        }
      }
    }

    .footer-modal {
      position: absolute;
      width: 100%;
      bottom: 15px;

      .button-left {
        display: flex;
        justify-content: flex-start;
      }

      .button-right {
        display: flex;
        justify-content: flex-end;
      }

      .suivant {
        height: 20px;
        background-color: red;
        padding: 7px 20px 10px 20px;
        color: white;
        font-weight: 500;
        border-radius: 7px;
        box-shadow: 0 0 5px red;
      }

      .modifier {
        height: 18px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;
        cursor: pointer;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }
  }


}

.validProgramme {
  background-color: var(--primary-color);
  border-radius: 15px !important;
  height: 190px;

  .ant-modal-content {
    border-radius: 10px !important;
    padding: 10px 15px;
  }

  .programme {
    padding: 5px 5px 5px 5px;
    height: 150px;
    position: relative;

    .title {
      text-align: left;
      font-weight: 500;
      font-size: 20px;

      .title-img {
        display: inline-block;
        width: 22px;
        border-radius: 50%;
        padding-top: 4px;
      }

      .title-label {
        display: inline-block;
        vertical-align: top;
        padding-left: 10px;
      }
    }

    span {
      font-size: 13px;
    }

    .footer-modal {
      position: absolute;
      width: 100%;
      bottom: 15px;

      .button-left {
        display: flex;
        justify-content: flex-end;
      }

      .button-right {
        display: flex;
        justify-content: flex-start;
      }

      .suivant {
        height: 20px;
        background-color: var(--primary-color);
        padding: 7px 20px 10px 20px;
        color: white;
        font-weight: 500;
        border-radius: 7px;
        box-shadow: 0 0 5px var(--primary-color);
      }

      .modifier {
        height: 18px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: limegreen;
        font-weight: 500;
        border: 1px solid limegreen;
        border-radius: 7px;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;
        cursor: pointer;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }
  }


}

.validProgramme2 {
  background-color: rgb(0, 169, 70);
  border-radius: 15px !important;
  height: 190px;

  .ant-modal-content {
    border-radius: 10px !important;
    padding: 10px 15px;
  }

  .programme {
    padding: 5px 5px 5px 5px;
    height: 150px;
    position: relative;

    .title {
      text-align: left;
      font-weight: 500;
      font-size: 20px;

      .title-img {
        display: inline-block;
        width: 22px;
        border-radius: 50%;
        padding-top: 4px;
      }

      .title-label {
        display: inline-block;
        vertical-align: top;
        padding-left: 10px;
      }
    }

    span {
      font-size: 13px;
    }

    .footer-modal {
      position: absolute;
      width: 100%;
      bottom: 15px;

      .button-left {
        display: flex;
        justify-content: flex-end;
      }

      .button-right {
        display: flex;
        justify-content: flex-start;
      }

      .suivant {
        height: 20px;
        background-color: black;
        padding: 7px 20px 10px 20px;
        color: white;
        font-weight: 500;
        border-radius: 7px;
        box-shadow: 0 0 5px black;
      }

      .modifier {
        height: 18px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: rgb(0, 169, 70);
        font-weight: 500;
        border: 1px solid rgb(0, 169, 70);
        border-radius: 7px;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;
        cursor: pointer;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }
  }


}

.validPaie {
  background-color: var(--primary-color);
  border-radius: 15px !important;
  height: 230px;

  .ant-modal-content {
    border-radius: 10px !important;
    padding: 10px 15px;
  }

  .paie {
    padding: 5px 5px 5px 5px;
    height: 190px;
    position: relative;

    .title {
      text-align: left;
      font-weight: 500;
      font-size: 20px;

      .title-img {
        display: inline-block;
        width: 22px;
        border-radius: 50%;
        padding-top: 4px;
      }

      .title-label {
        display: inline-block;
        vertical-align: top;
        padding-left: 10px;
      }
    }

    span {
      font-size: 13px;
    }

    .footer-modal {
      position: absolute;
      width: 100%;
      bottom: 15px;

      .button-left {
        display: flex;
        justify-content: flex-start;
      }

      .button-right {
        display: flex;
        justify-content: flex-start;
      }

      .suivant {
        height: 20px;
        background-color: var(--primary-color);
        padding: 7px 20px 10px 20px;
        color: white;
        font-weight: 500;
        border-radius: 7px;
        box-shadow: 0 0 5px var(--primary-color);
      }

      .modifier {
        height: 18px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: limegreen;
        font-weight: 500;
        border: 1px solid limegreen;
        border-radius: 7px;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;
        cursor: pointer;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }
  }


}

.suppressionLogementNotPossible {
  background-color: var(--primary-color);
  border-radius: 15px !important;
  height: 250px;

  .ant-modal-content {
    border-radius: 10px !important;
    padding: 10px 15px;
  }

  .supprimer {
    padding: 5px 5px 5px 5px;
    height: 210px;
    position: relative;

    .title {
      text-align: left;
      font-weight: 500;
      font-size: 20px;

      .title-img {
        display: inline-block;
        width: 22px;
        border-radius: 50%;
        padding-top: 4.5px;
      }

      .title-label {
        display: inline-block;
        vertical-align: top;
        padding-left: 5px;
      }
    }

    span {
      font-size: 13px;
    }

    .footer-modal {
      position: absolute;
      width: 98%;
      bottom: 15px;

      .button-left {
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
      }

      .button-right {
        display: flex;
        justify-content: flex-end;
      }

      .suivant {
        height: 20px;
        background-color: var(--primary-color);
        padding: 7px 20px 10px 20px;
        color: white;
        font-weight: 500;
        border-radius: 7px;
        box-shadow: 0 0 5px var(--primary-color);
      }

      .modifier {
        height: 18px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;
        cursor: pointer;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }
  }


}

.controleFinPrestation {
  //background-color: var(--primary-color);
  border-radius: 15px !important;
  //height: 650px;

  .ant-modal-content {
    border-radius: 10px !important;
    padding: 50px 30px;
    height: auto;
    .titre{
      text-align: left;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 30px;
    }
    .elementControle{
      padding: 20px;
      margin-bottom: 10px;
      .question{
        display: inline-block;
        font-weight: 700;
        border-bottom: 1px solid var(--primary-color);
      }
      .direct{
        vertical-align: top;
        margin-top: -5px;
        background-color: var(--primary-color);
        padding: 5px 10px;
        color: white;
        font-weight: 500;
        display: inline-block;
        border-radius: 10px;
        float: right;
      }
      .reponse{
        margin-top: 10px;

      }
    }
  }

}

.pub-cgu-page {
  .ant-modal-content {
    border-radius: 8px !important;
    background-color: transparent !important;
    border: 0;
    box-shadow: 0 0 0 0 !important;
    .pubCGU {
      padding: 0px 0px !important;
      height: 500px !important;
      overflow-y: hidden !important;
      background-color: transparent !important;
      .CGUModalPage{
       display: flex;
       justify-content: space-between;
       padding: 0 15px;
        background-color: transparent !important;
        .button-carousel-left{
          display: inline-block;
          vertical-align: top;
          background-color: white ;
          width: 28px;
          height: 27px;
          padding-top: 2px;
          border-radius: 50%;
          text-align: center;
          font-size: 16px;
          color: #7F7F7F;
          margin-top: 25%;
          cursor: pointer;
        }
        .button-carousel-right{
          display: inline-block;
          vertical-align: top;
          background-color: white ;
          width: 28px;
          height: 27px;
          padding-top: 2px;
          border-radius: 50%;
          text-align: center;
          font-size: 16px;
          color: #7F7F7F;
          margin-top: 25%;
          cursor: pointer;
        }
        .main-carousel{
          display: inline-block;
          padding: 20px 24px;
          background-color: white;
          width: 80%;
          height: 450px;
          border-radius: 8px !important;
          .ant-carousel{
            height: 100% !important;
            .slick-slider.slick-initialized{
              height: 100%;
              .slick-list{
                height: 100%;
                .slick-track, .slick-track > div, .slick-track > div > div{
                  height: 100%;
                }
              }
            }
            .caroussel-page{
              height: 100%;
              background-color: white;
              .Slide1CGUModalPage{
                .headTitle{
                  img{
                    display: inline-block;
                    margin-right: 20px;
                    width: 43px;
                    border-radius: 50%;
                    border: 1px solid var(--primary-color);
                    background-color: var(--primary-color);
                  }
                  .h1{
                    vertical-align: top;
                    display: inline-block;
                    margin-top: 5px;
                    font-weight: 800;
                    font-size: 22px;
                  }
                }
                .content{
                  padding: 40px 0 0 0 !important;
                    .imageSide{
                      width: 100%;
                      text-align: center;
                      img{
                        margin-left: 30px;
                        margin-top: 20px;
                        width: 75%;
                      }
                    }
                    .textSide{
                        .h3{
                          font-size: 15px;
                          font-weight: 400;
                          margin-bottom: 15px;
                        }
                        .h2{
                          line-height: 27px;
                          font-weight: 500;
                          font-size: 21px;
                          span{
                            color: var(--primary-color);
                          }
                        }
                      .listElement{
                        margin-top: 30px;
                        .element{
                          margin-bottom: 20px;
                          .image{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 10px;
                            img{
                              margin-top: 1px;
                              width: 15px;
                            }
                          }
                          .list{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 10px;
                            color: var(--primary-color);
                          }
                          .text{
                           display: inline-block;
                            font-size: 12px;
                            max-width: calc(100% - 30px);
                            word-wrap: break-word;
                          }
                        }
                      }
                    }
                    .condition{
                      margin-top: -40px;
                      padding: 20px 20px;
                      .content-condition{
                        padding: 10px;
                        border: 1px solid rgba(128, 128, 128, 0.4196078431);
                        border-radius: 15px;
                        height: 290px;
                        overflow-y: scroll;
                        color: var(--text-color) !important;
                        p, div{
                          color: var(--text-color) !important;
                        }
                      }
                    }
                }
              }
            }
            .slick-dots{
              margin-bottom: -25px;
              li{
                width: 10px;
                height: 11px;
                margin-right: 10px;
                button{
                  background-color: #7F7F7F;
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }
              .slick-active{
                button{
                  background-color: var(--primary-color);
                }
              }
            }
          }
        }

        /* Style pour le fade-in */
        .fade-in {
          opacity: 0;
          animation: fadeIn 0.5s forwards; /* 0.5s pour l'effet fade-in, ajustable */
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        /* Style pour le fade-in */



        .barvalidation{
          position: absolute;
          bottom: 0;
          margin-bottom: 30px;
          margin-left: 110px;
          width: 695px;
          .accepter {
            float: right;
            background-color: var(--primary-color);
            padding: 10px 25px 10px 25px;
            color: white;
            font-size: 16px;
            font-weight: 500;
            border-radius: 7px;
            border: 2px solid var(--primary-color);
            box-shadow: 0 0 5px var(--primary-color);
          }
          .imprimer {
            float: left;
            background-color: white;
            padding: 10px 25px 10px 25px;
            color: var(--primary-color);
            font-size: 16px;
            font-weight: 500;
            border-radius: 7px;
            border: 2px solid var(--primary-color);
            box-shadow: 0 0 0 transparent;
          }
          .refuser {
            float: right;
            margin-right: 20px;
            background-color: white;
            padding: 10px 25px 10px 25px;
            color: red;
            font-size: 16px;
            font-weight: 500;
            border-radius: 7px;
            border: 2px solid white;
            box-shadow: 0 0 0 transparent;
          }
        }
      }
    }
  }
}
.pub-page {
  .ant-modal-content {
    .pub {
      padding: 20px 24px;
      height: 1000px;
      overflow-y: scroll;

      //padding-bottom: 30px;
      .menu {
        text-align: center;

        .menu-in {
          display: inline-block;
          border-radius: 7px;
          border: 1px solid var(--disabled);
          color: var(--disabled);
          padding: 8px 13px;
          margin-left: 30px;
          font-size: 17px;
          cursor: pointer;
        }

        .active {
          color: var(--primary-color);
          border-color: var(--primary-color);
        }
      }

      .AccueilPubPage {
        .titre {
          margin-top: 100px;
          font-size: 70px;
          line-height: 1.2;
          text-align: center;

          span {
            color: var(--primary-color);
          }
        }

        .subTitre {
          margin-top: 30px;
          text-align: center;
          font-size: 29px;
          margin-bottom: 100px;
        }

        .search-box {
          margin-bottom: 100px;

          .search-input {
            height: 75px;
            border-radius: 20px;
            border: 3px solid var(--primary-color) !important;
            box-shadow:
              0 0 8px #ffb53a,
              0 0 8px #d56e00;

            input {
              font-size: 23px;
            }

            button {
              padding: 14px 20px;
              height: 55px;

              span {
                font-size: 20px;
              }

              .imageButton {
                vertical-align: top;
                display: inline-block;
                width: 30px;
                padding-left: 10px;

                img {
                  width: 100%;
                }
              }
            }
          }
        }

        .promotion {
          text-align: center;
          margin-bottom: 120px;

          .head {
            font-size: 30px;
          }

          .description {
            font-size: 18px;
          }
        }

        .fonctionnalite {
          color: var(--primary-color);
          font-size: 25px;
        }

        .subHead {
          margin-top: 20px;
          margin-bottom: 50px;
          font-size: 42px;
          line-height: 1.2;
        }

        .block-fonction {
          margin-bottom: 120px;

          .block-in {
            border: 1px solid grey;
            margin: 0 20px 30px 0;
            padding: 15px 15px 10px 15px;
            border-radius: 10px;
            height: 270px;

            .icone {
              width: 40px;

              img {
                width: 100%;
              }
            }

            .head {
              font-size: 22px;
            }

            .description {
              p {
                height: 120px;
              }
            }
          }
        }

        .avis {
          .text {
            text-align: center;

            .ant-col {
              font-size: 20px;
              line-height: 1.4;
            }
          }

          .name {
            margin-top: 30px;
            text-align: center;
            font-size: 23px;
          }

          .fonction {
            margin-top: -10px;
            font-style: italic;
            text-align: center;
            font-size: 20px;
          }
        }

        .banniere {
          background: linear-gradient(to right, white 0px, white 20%, #ff7f0a 100%);
          border: 2px solid var(--primary-color);
          border-radius: 10px;
          padding: 30px 30px 50px 40px;
          margin-top: 90px;

          .head {
            font-size: 40px;
          }

          .description {
            font-size: 18px;
          }

          .search-input {
            margin-top: 20px;
            height: 55px;
            border-radius: 10px;
            border-color: transparent !important;

            input {
              font-size: 19px;
            }

            button {
              padding: 7px 20px;
              height: 42px;
              border-radius: 5px !important;

              span {
                font-size: 18px;
              }

              .imageButton {
                vertical-align: top;
                display: inline-block;
                padding-left: 10px;
                width: 25px;

                img {
                  width: 100%;
                }
              }
            }
          }

          .ant-input-outlined:focus-within {
            box-shadow: 0 0 0 !important;
            border-color: transparent !important;
          }
        }
      }

      .DashboardPubPage {
        padding: 70px 50px;

        .rigthBox {
          .title {
            font-size: 17px;

            span {
              font-style: italic;
              font-weight: 300;
              font-size: 15px;
            }
          }

          .sub {
            margin-top: -4px;
            color: var(--disabled);
            font-size: 13px;
          }

          .box-info {
            margin-top: 20px;
            border-radius: 10px;
            border: 1px solid var(--disabled-light);

            .element:last-child {
              border-bottom: 0px solid var(--disabled-light);
            }

            .element {
              border-bottom: 1px solid var(--disabled-light);
              padding: 25px 15px 20px 30px;

              .ville {
                font-size: 17px;
                display: inline-block;
              }

              .percent {
                margin-left: 15px;
                font-size: 13px;
                color: var(--disabled);
                display: inline-block;
              }

              .action {
                float: right;

                a {
                  color: #0a94ff;
                  vertical-align: top;

                  svg {
                    vertical-align: top;
                    padding-top: 4px;
                  }
                }

                button {
                  height: 28px;
                  border-radius: 20px !important;
                }
              }
            }
          }
        }

        .leftBox {
          .title {
            font-size: 17px;
            margin-bottom: 25px;

            .filter {
              margin-left: 20px;
              border-radius: 10px;
              border: 1px solid var(--disabled-light);
              padding: 5px;
              display: inline-block;

              .date-start {
                display: inline-block;
                font-size: 12px;
                padding: 0 10px;
              }

              .date-end {
                display: inline-block;
                font-size: 12px;
                padding: 0 10px;
              }

              .icone {
                vertical-align: top;
                width: 15px;
                margin-top: 2px;
                display: inline-block;

                img {
                  width: 100%;
                }
              }

              svg {
                vertical-align: top;
                margin-top: 5px;
              }
            }
          }

          .stats {
            .stats-in {
              margin-right: 20px;
              margin-bottom: 20px;
              box-shadow: 1px 1px 10px 1px var(--disabled-light);
              border-radius: 5px;
              padding: 10px 10px;

              .head {
                vertical-align: top;

                .icone {
                  display: inline-block;
                  width: 15px;
                  margin-right: 10px;

                  img {
                    width: 100%;
                  }
                }

                .text {
                  vertical-align: top;
                  margin-top: 2px;
                  font-size: 12px;
                  display: inline-block;
                }
              }

              .content-stats {
                font-size: 20px;
                padding: 10px 20px;
              }
            }
          }

          .graph {
            margin-top: 35px;

            .graph-stats {
              box-shadow: 1px 1px 10px 1px var(--disabled-light);
              margin-right: 20px;
              border-radius: 30px;
              padding-bottom: 50px;

              .head {
                padding: 25px 25px 10px 25px;
                vertical-align: top;

                .icone {
                  display: inline-block;
                  width: 47px;
                  height: 47px;
                  margin-right: 10px;

                  img {
                    width: 100%;
                    border-radius: 50%;
                  }
                }

                .text {
                  vertical-align: top;
                  margin-top: 10px;
                  margin-left: 12px;
                  font-size: 17px;
                  display: inline-block;
                }
              }

              .element:last-child {
                border-bottom: 0 solid var(--disabled-light);
              }

              .element {
                border-bottom: 1px solid var(--disabled-light);
                padding: 15px 20px;

                .number {
                  display: inline-block;
                  border: 1px solid var(--disabled-light);
                  width: 50px;
                  border-radius: 50%;
                  text-align: center;
                  vertical-align: top;
                  color: var(--disabled);
                  padding-top: 14px;
                  padding-bottom: 14px;
                }

                .zone {
                  display: inline-block;
                  margin-left: 15px;
                  vertical-align: top;
                  padding-top: 8px;

                  .ville {
                    font-weight: 400;
                  }

                  .nbreservation {
                    color: var(--disabled);
                    font-size: 12px;
                  }
                }

                .checker {
                  vertical-align: top;
                  margin-top: 15px;
                  float: right;
                  width: 25px;

                  img {
                    width: 100%;
                  }
                }
              }
            }

            .graph-line {
              box-shadow: 1px 1px 10px 1px var(--disabled-light);
              margin-right: 70px;
              border-radius: 50px;
              padding: 10px 10px;

              .head {
                padding: 25px 25px 10px 25px;
                vertical-align: top;

                .icone {
                  display: inline-block;
                  width: 47px;
                  height: 47px;
                  margin-right: 10px;

                  img {
                    width: 100%;
                    border-radius: 50%;
                  }
                }

                .text {
                  vertical-align: top;
                  margin-top: 10px;
                  margin-left: 12px;
                  font-size: 17px;
                  display: inline-block;

                  span {
                    font-size: 13px;
                    color: grey;
                  }
                }
              }

              .myGraph {
                padding: 5px 30px 30px 30px;

                .element {
                  margin-right: 19px;
                  display: inline-block;

                  .bar {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    width: 50px;
                    height: 220px;

                    .nb {
                      text-align: center;
                      margin-bottom: 4px;
                    }

                    .bar-in {
                      border-radius: 20px;
                      width: 100%;
                      background-color: var(--tertiary-color);
                    }

                    .bar-in-current {
                      border-radius: 20px;
                      width: 100%;
                      background-color: var(--primary-color);
                    }
                  }

                  .month {
                    margin-top: 4px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }
    .pubCGU{
      padding: 20px 24px;
      height: 400px;
      overflow-y: scroll;
    }
  }


  .pub::-webkit-scrollbar {
    width: 0;
  }

  .pub::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.pub-page-special {
  .ant-modal-content {
    padding: 0 0 !important;

    .pub2 {
      height: 1000px;
      padding-bottom: 30px;

      .menu {
        text-align: center;

        .menu-in {
          display: inline-block;
          border-radius: 7px;
          border: 1px solid var(--disabled);
          color: var(--disabled);
          padding: 8px 13px;
          margin-left: 30px;
          font-size: 17px;
          cursor: pointer;
        }

        .active {
          color: var(--primary-color);
          border-color: var(--primary-color);
        }
      }

      .PubLogementModalPage {
        .header {
          border-radius: 30px 30px 0 0;
          background-color: rgb(244, 244, 244);
          padding: 20px 34px 12px 34px;
          border-bottom: 1px solid var(--disabled);

          .title {
            font-size: 22px;
            margin-bottom: 20px;
          }

          .config {
            .left {
              display: inline-block;

              .base {
                display: inline-block;
                font-weight: 300;
                font-size: 16px;
                margin-right: 10px;
                cursor: pointer;
              }

              .ville {
                display: inline-block;
                margin-right: 15px;

                .ant-select {
                  height: 24px;
                  border: 0 !important;

                  .ant-select-selector {
                    border-radius: 20px;
                    border: 0 !important;
                    padding: 1px 30px;

                    .ant-select-selection-search-input {
                      height: 24px;
                    }

                    .ant-select-selection-item {
                      padding: 0 10px 0 0 !important;
                      width: 100px;
                    }
                  }
                }
              }

              .statut {
                display: inline-block;
              }
            }

            .rigth {
              float: right;

              button {
                margin-top: -13px;
                border: 0 !important;
                height: 35px;
                border-radius: 5px !important;
                font-size: 15px;
                background-color: black;
                color: white;
              }
            }
          }
        }

        .contentPubLogement {
          padding: 20px 50px;

          .rigthBox {
            .title {
              font-size: 13px;
            }

            .apercuPub {
              width: 80%;
              height: 560px;
              background-color: var(--disabled-light);
            }

            .sub {
              margin-top: -4px;
              color: var(--disabled);
              font-size: 13px;
            }

            .box-info {
              margin-top: 20px;
              border-radius: 10px;
              border: 1px solid var(--disabled-light);

              .element:last-child {
                border-bottom: 0px solid var(--disabled-light);
              }

              .element {
                border-bottom: 1px solid var(--disabled-light);
                padding: 25px 15px 20px 30px;

                .ville {
                  font-size: 17px;
                  display: inline-block;
                }

                .percent {
                  margin-left: 15px;
                  font-size: 13px;
                  color: var(--disabled);
                  display: inline-block;
                }

                .action {
                  float: right;

                  a {
                    color: #0a94ff;
                    vertical-align: top;

                    svg {
                      vertical-align: top;
                      padding-top: 4px;
                    }
                  }

                  button {
                    height: 28px;
                    border-radius: 20px !important;
                  }
                }
              }
            }
          }

          .leftBox {
            padding-top: 30px;

            .title {
              font-size: 17px;
              margin-bottom: 25px;

              .filter {
                margin-left: 20px;
                border-radius: 10px;
                border: 1px solid var(--disabled-light);
                padding: 5px;
                display: inline-block;

                .date-start {
                  display: inline-block;
                  font-size: 12px;
                  padding: 0 10px;
                }

                .date-end {
                  display: inline-block;
                  font-size: 12px;
                  padding: 0 10px;
                }

                .icone {
                  vertical-align: top;
                  width: 15px;
                  margin-top: 2px;
                  display: inline-block;

                  img {
                    width: 100%;
                  }
                }

                svg {
                  vertical-align: top;
                  margin-top: 5px;
                }
              }
            }

            .stats {
              .stats-in {
                margin-right: 10px;
                margin-bottom: 20px;
                box-shadow: 1px 1px 10px 1px var(--disabled-light);
                border-radius: 5px;
                padding: 10px 10px;

                .head {
                  vertical-align: top;

                  .icone {
                    display: inline-block;
                    width: 15px;
                    margin-right: 10px;

                    img {
                      width: 100%;
                    }
                  }

                  .text {
                    vertical-align: top;
                    margin-top: 2px;
                    font-size: 12px;
                    display: inline-block;
                  }
                }

                .content-stats {
                  font-size: 20px;
                  padding: 10px 20px;
                }
              }

              .stats-off {
                margin-right: 10px;
                margin-bottom: 20px;
                box-shadow: 1px 1px 10px 1px var(--disabled-light);
                border-radius: 5px;
                padding: 10px 10px;

                .head {
                  vertical-align: top;

                  .icone {
                    display: inline-block;
                    width: 15px;
                    margin-right: 10px;

                    img {
                      width: 100%;
                    }
                  }

                  .price {
                    float: right;
                    vertical-align: top;
                    font-size: 15px;
                    margin-top: -5px;
                    font-weight: 600;
                  }

                  .text {
                    vertical-align: top;
                    margin-top: 0px;
                    font-size: 12px;
                    display: inline-block;
                  }
                }

                .content-stats {
                  font-size: 16px;
                  padding: 0 20px;

                  span {
                    font-weight: 300;
                  }

                  .progresscontent {
                    position: relative;

                    .ant-progress-bg {
                      height: 7px !important;
                    }
                  }

                  .indicateur {
                    margin-top: 2px;
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: var(--primary-color);
                    text-align: center;
                    z-index: 999;
                    padding-left: 5px;
                    padding-top: 5px;

                    .inner {
                      width: 15px;
                      height: 15px;
                      background-color: white;
                      border-radius: 50%;
                    }
                  }
                }
              }
            }

            .configuration {
              margin-top: 20px;
              margin-bottom: 20px;
              margin-right: 10px;

              .title {
                margin-bottom: 5px;

                .icone {
                  float: right;
                  width: 35px;

                  img {
                    vertical-align: top;
                    margin-top: -5px;
                    width: 100%;
                  }
                }
              }

              .listConfig {
                border: 1px solid var(--disabled-light);
                border-radius: 5px;

                .element.reducImage {
                  .icone {
                    display: inline-block;
                    width: 16px;
                  }

                  .titleConf {
                    margin-left: 20px;
                  }
                }

                .element.augImage {
                  .icone {
                    display: inline-block;
                    width: 25px;
                  }

                  .titleConf {
                    margin-left: 10px;
                  }
                }

                .element {
                  padding: 15px 20px;
                  border-bottom: 1px solid var(--disabled-light);

                  .icone {
                    display: inline-block;
                    width: 20px;

                    img {
                      width: 100%;
                    }
                  }

                  .titleConf {
                    vertical-align: top;
                    margin-top: 0px;
                    margin-left: 15px;
                    width: 170px;
                    display: inline-block;
                  }

                  .valueConf {
                    vertical-align: top;
                    margin-top: 3px;
                    margin-left: 50px;
                    display: inline-block;
                    color: var(--disabled);

                    .icone {
                      margin-left: 20px;
                      vertical-align: top;
                      display: inline-block;
                      width: 30px;

                      img {
                        width: 100%;
                      }
                    }
                  }

                  .action {
                    float: right;

                    .icone {
                      margin-left: 20px;
                      vertical-align: top;
                      display: inline-block;
                      width: 20px;

                      img {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .pub::-webkit-scrollbar {
    width: 0;
  }

  .pub::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.dashboard-page-padding{
  padding: 0 10%;
}
@media screen and (max-width: 1700px){
  .dashboard-page-padding{
    padding: 0 0 !important;
  }
}