@media screen and (max-width: 1700px){
  .ant-layout-header {
    background-color: white;
    height: 65px;
  }

  .search-input {
    background-color: white;
    height: 30px;
    input {
      background-color: white !important;
    }
  }


  .topBarSearch{
    padding-left: 5%;
    padding-top: 0;
    .search-input:hover {
      border-color: transparent !important;
      box-shadow: 0 0 0 !important;
    }
    .search-input:focus {
      border-color: transparent !important;
      box-shadow: 0 0 0 !important;
    }
    .search-input {
      background-color: rgb(245,246,248);
      height: 30px;
      border-color: transparent !important;
      box-shadow: 0 0 0 !important;
      input {
        font-size: 11px;
        background-color: rgb(246,248,250) !important;
      }
      input::placeholder {
        color: #616B7B !important;
      }
    }
    .slash{
      width: 20px;
      height: 20px;
      background-color: #E5E8EC;
      border-radius: 4px;
      padding-left: 8px;
      div{
        transform: rotate(10deg);
        opacity: 0.4;
      }
    }
  }

  .icons-head {
    font-size: 20px;
    cursor: pointer;
    svg {
      margin: 0px 8px;
    }
  }

  .headerBarClass{
    .resultResearch{
      margin-left: 6%;
      padding: 10px 15px;
      z-index: 999;
      margin-top: 0;
      position: absolute;
      border-radius: 10px;
      background-color: white;
      width: 100%;
      box-shadow: 1px 1px 5px 5px var(--disabled);
      .ant-tabs-tab:hover{
        color: var(--primary-color) !important;
      }
      .ant-tabs-tab{
        padding: 8px 10px;
        font-size: 10px;
      }
      .ant-tabs-tab+ .ant-tabs-tab{
        margin: 0 0 0 30px;
      }
      .ant-tabs-tab-btn{
        color: #A6A6A6;
        font-size: 11px;
        font-weight: 400;
        .nbResult{
          text-align: center;
          .nbResult-in{
            display: inline-block;
            background-color: var(--disabled-light);
            padding: 1px 10px;
            border-radius: 5px;
            font-size: 15px;
            margin-bottom: 12px;
            margin-top: 3px;
          }
        }
      }
      .ant-tabs-tab-active{
        .ant-tabs-tab-btn{
          color: var(--primary-color) !important;
        }
      }
      .ant-tabs-ink-bar{
        background-color: var(--primary-color) !important;
      }
    }
    .resultZone{
      padding: 20px 15px;
      .empty{
        color: grey;
        font-weight: 400;
        font-size: 11px;
      }
    }
  }
}