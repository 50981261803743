/*.logistique-page{
  padding-top: 130px;
  padding-left: 30px;
  font-size: 80px;
  font-family: "Times New Roman";
  .underline{
    border-bottom: 3px solid black;
  }
  .normal{
    color: #8080806b;
    opacity: 0.8;
  }
  .line{
    margin-top: 85px;
    width: 90%;
    height: 8px;
    background-color: var(--disabled-light);
    border-radius: 5px;
    position: relative;
  }
  .coverLine{
    position: relative;
    height: 8px;
    border-radius: 5px;
    width: 77%;
    background-color: white;
    box-shadow: 2px 2px 10px 2px var(--disabled-light);
    z-index: 1;
  }
  img{
    position: relative;
    z-index: 999;
    vertical-align: top;
    margin-top: -26px;
    margin-left: 76%;
    width: 45px;
  }
}*/


.logistique-page{
  margin: 0px 18px;

  .page-header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    h1 {
      font-size: 22px;
      font-weight: 600;
    }
  }

  .switch-menu{
    margin-top: 30px;
    .menu{
      border: 1px solid #d9d9d9;
      display: inline-block;
      border-radius: 10px;
      padding: 0 0;
      .left{
        display: inline-block;
        padding: 15px 25px;
        margin-right: 5px;
      }
      .right{
        display: inline-block;
        padding: 10px 25px;
        margin-left: 5px;
      }
      .active{
        background-color: var(--primary-color);
        color: white;
        border-radius: 9px;
      }
    }
  }

  .header{
    margin-top: 30px;
    margin-bottom: 25px;
    .blc-left {
      display: inline-block;
    }
    .blc-right {
      .cree-planning{
        box-shadow: 0 0 5px var(--primary-color);
        border-radius: 10px;
        .ant-btn-compact-first-item{
          width: 250px;
          font-size: 16px;
          border-right: 0 solid white !important;
          margin-inline-end: 0 !important;
          .icon-logement-add{
            display: inline-block;
            vertical-align: top;
            width: 30px;
            padding-top: 2px;
            margin-right: 10px;
            img{
              width: 100%;
            }
          }
        }
        .ant-dropdown-trigger{
          display: none;
        }
        .ant-btn-compact-first-item:hover{
          background-color: var(--primary-color);
          color: white;
        }
        .ant-btn-compact-last-item:hover{
          background-color: var(--primary-color);
          color: white;
        }
        .ant-btn-compact-last-item{
          .icon-logement-add{
            display: inline-block;
            width: 20px;
            img{
              width: 100%;
            }
          }
        }
      }
      .ant-btn {
        border-radius: 0% !important
      }

      .ant-dropdown-trigger {
        background-color: var(--primary-color);
        color: white;
      }

      .ant-btn-compact-first-item {
        background-color: var(--primary-color);
        color: white;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }

      .ant-btn-compact-last-item {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
    }
    .blc-filter {
      background-color: white;
      display: inline-block;
      padding: 3px 0px 3px 14px;
      border-radius: 10px;
      border: 1px solid rgba(5, 5, 5, 0.06);
      margin-right: 14px;

      .ant-select-selector {
        padding: 0% 22px !important;
        border-radius: 10px !important;
        border: 0px !important;
        box-shadow: 0 0 0 !important;
      }

      .ant-select-focused:where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
        box-shadow: none !important;
      }
    }
    .icon{
      display: inline-block;
      vertical-align: top;
      padding-top: 2px;
      margin-right: 5px;
      width: 20px;
      height: 20px;
      img{
        width: 100%;
      }
    }
  }

  .stats{
    .content{
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      padding: 20px 20px;
      .head{
        margin-bottom: 12px;
        .image{
          display: inline-block;
          width: 45px;
          height: 45px;
          border: 1px solid #F6F6F6;
          background-color: #F6F6F6;
          text-align: center;
          border-radius: 5px;
          padding-top: 2px;
          img{
            width: 90%;
            border-radius: 5px;
          }
        }
        .text{
          margin-left: 15px;
          vertical-align: top;
          display: inline-block;
          .title{
            font-size: 20px;
          }
          .description{

          }
        }
      }
      .statistique{
        .blockBlue{
          background-color: #EBF3FE;
          border: 1px solid #BAC8FB;
          margin-right: 10px;
        }
        .blockBlue2{
          background-color: #EBF3FE;
          border: 1px solid #BAC8FB;
          margin-left: 10px;
        }
        .blockOrange{
          background-color: #FCF2ED;
          border: 1px solid var(--tertiary-color);
          margin-left: 10px;
        }
        .blockGreyLeft{
          background-color: #FBFBFB;
          border: 1px solid var(--disabled-light);
          margin-right: 10px;
        }
        .blockGreyRight{
          background-color: #FBFBFB;
          border: 1px solid var(--disabled-light);
          margin-left: 10px;
        }
        .blocStat{
          border-radius: 5px;
          padding: 6px 10px;
          .indication{
            position: relative;
            .info-equipe {
              position: absolute;
              right: 0;
              width: 12px;
              height: 12px;
              top: 1px;

              img {
                width: 100%;
              }
            }
          }
          .number{
            font-size: 25px;
            text-align: center;
            padding-top: 10px;
            margin-bottom: 10px;
          }
          .label{
            text-align: center;
            margin-bottom: 10px;
          }
        }
      }
    }
    .statistiqueLeft{
      margin-right: 8px;
      background-color: white;
    }
    .statistiqueRight{
      margin-left: 8px;
      background-color: white;
    }
  }
  .statistique-tabContent{
    margin-top: 20px;
    background-color: white;
    padding: 15px 15px !important;
    border-radius: 10px;
    border: 1px solid var(--disabled-light);
    .head{
      margin-bottom: 12px;
      .image{
        display: inline-block;
        width: 45px;
        height: 45px;
        border: 1px solid #F6F6F6;
        background-color: #F6F6F6;
        text-align: center;
        border-radius: 5px;
        padding-top: 2px;
        img{
          width: 90%;
          border-radius: 5px;
        }
      }
      .text{
        margin-left: 15px;
        vertical-align: top;
        margin-top: 10px;
        display: inline-block;
        .title{
          font-size: 18px;
        }
      }
    }
    .indication{
      text-align: right;
      margin-top: 15px;
    }
  }
  .photo{
    margin-top: 30px;
    .head{
      font-size: 15px;
      margin-bottom: 30px;
    }
    .rapport_photo{
      border: 1px solid var(--disabled-light);
      background-color: white;
      border-radius: 10px;
      margin-bottom: 15px;
      padding: 15px;
      height: 90px;
      position: relative;
      .type{
        width: 60px;
        img{
          width: 100%;
          border-radius: 10px;
        }
      }
      .type-mini-photo{
        position: absolute;
        width: 45px;
        left: 15px;
        top: 15px;
        background-color: white;
        border-radius: 10px;
        img{
          width: 100%;
          border-radius: 10px;
        }
      }
      .type-mini-video{
        position: absolute;
        width: 45px;
        background-color: white;
        border-radius: 10px;
        img{
          width: 100%;
          border-radius: 10px;
        }
      }
      .date{
        cursor: pointer;
        vertical-align: top;
        padding-top: 20px;
      }
      .prestation{
        vertical-align: top;
        padding-top: 21px;
        font-size: 12px;
        color: grey;
      }
      .menu{
        float: right;
        vertical-align: top;
        padding-top: 21px;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .linge-a-recuperer{
      border: 1px solid var(--disabled-light);
      background-color: white;
      height: auto;
      border-radius: 10px;
      margin-left: 30px;
      .titre{
        background-color: var(--disabled-light);
        padding: 10px 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .lingeList{
        padding: 20px 20px;
        .element{
          .jourJ{
            display: inline-block;
            vertical-align: top;
            padding-top: 30px;
            color: red;
            font-size: 20px;
          }
          .info{
            margin-left: 20px;
            display: inline-block;
            .head{
              padding: 5px 0;
              margin: 0;
            }
            .min-info{
              padding: 2px 0;
              font-size: 13px;
              color: grey;
            }
            .min-info-colored{
              padding: 2px 0;
              font-size: 13px;
              color: var(--text-grey-color);
            }
            .min-info-link{
              padding: 5px 0;
              .min-info-link-image{
                vertical-align: top;
                margin-top: 2px;
                display: inline-block;
                width: 15px;
                img{
                  width: 100%;
                }
              }
              .min-info-link-text{
                display: inline-block;
                margin-left: 5px;
                text-decoration-line: underline;
              }
            }
          }
        }
      }
    }
  }
  .content{
    padding: 40px 0;
    .equipe-table {
      .wait{
        .info-equipe {
          display: inline-block;
          margin-left: 10px;
          width: 12px;
          height: 12px;
          img {
            width: 100%;
          }
        }
      }
      .avatar{
        display: inline-block;
        width: 40px;
        img{
          width: 100%;
        }
      }
      .info{
        vertical-align: top;
        padding-left: 10px;
        display: inline-block;
        .desc{
          font-size: 12px;
        }
      }
      .icon{
        display: inline-block;
        vertical-align: top;
        width: 21px;
        height: 21px;
        img{
          width: 100%;
        }
      }
      .nbLogement{
        color: var(--primary-color);
      }
      .ant-table-selection-column {
        padding-left: 15px;
        padding-inline-start: 0;
        padding-inline-end: 0;
      }
      .ant-table-selection-column{
        padding: 0 5px !important;
        width: 10px !important;
      }
      .ant-table-thead {
        tr {
          .ant-table-cell {
            padding: 5px 10px !important;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            font-size: 12px;
            color: #263754;
          }
          .ant-table-selection-column{
            padding: 0 5px !important;
            width: 10px !important;
          }
        }
      }

      tbody {
        tr {
          td {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 400;
            color: #566F8F !important;
          }
        }
      }

      .tab-option{
        cursor: pointer;
        padding-top: 10px;
        padding-left: 10px;
        border-radius: 5px;
      }
      .tab-option:hover{
        background-color: var(--tertiary-color);
      }
    }
  }
}

.RapportPhoto{
  .ant-modal-content{
    height: 900px;
    border-radius: 10px !important;
    .ant-modal-close{
      border: 1px solid var(--disabled-light);
    }
    .rapport{
      padding: 0 10px;
      .head{
        font-size: 20px;
        .dateSelector{
          float: right;
          margin-right: 50px;
          .image{
            display: inline-block;
            vertical-align: top;
            margin-top: 5px;
            width: 20px;
            img{
              width: 100%;
            }
          }
          .ant-select-selector{
            border: 0 !important;
            .ant-select-selection-item{
              vertical-align: top;
              margin-top: -30px;
            }
          }
          .ant-select-arrow{
            margin-top: -9px;
          }
        }
      }
      .select{
        .libelle{
          display: inline-block;
        }
        .selecteur{
          display: inline-block;
          .ant-select-selector{
            border: 0 !important;
            .ant-select-selection-item{
              font-size: 13px;
              color: var(--primary-color);
            }
          }
          .ant-select-arrow{
            margin-top: -2px;
          }
        }
      }
      .appartement{
        .button{
          display: inline-block;
          margin-right: 20px;
          button{
            border-radius: 5px !important;
            height: 30px;
          }
          button:hover{
            color: var(--primary-color);
          }
          button.active{
            background-color: var(--primary-color);
            color: white;
          }
        }
      }
      .apercuPhoto{
        height: 600px;
        .prev{
          cursor: pointer;
          vertical-align: top;
          font-size: 100px;
          margin-top: 200px;
        }
        .next{
          vertical-align: top;
          font-size: 100px;
          margin-top: 200px;
          cursor: pointer;
        }
      }
      .avant-apres{
        margin-top: 50px;
        text-align: center;
        .avant{
          margin-right: 20px;
          color: var(--disabled);
        }
        .apres{
          margin-left: 20px;
          color: var(--disabled);
        }
        .active{
          color: var(--primary-color);
        }
      }
    }
  }
}