

@media screen and (max-width: 1700px){
  body {
    font-size: 13px !important;
  }
  .brMobile{
    display: block;
  }
  .nombre {
    user-select: none;
    width: 75px !important;
    display: inline-block;
    vertical-align: inherit;
    margin-bottom: 0;
    text-align: center;
    font-size: 12px;

    .nombre-input:hover {
      border-color: rgba(255, 127, 10, 0.3) !important;
    }

    .nombre-input:focus-within {
      border-color: rgba(255, 127, 10, 0.3) !important;
      box-shadow: 0 0 0;
    }

    .nombre-input {
      height: 25px;
      width: 100%;
      text-align: center;
      background-color: white !important;
      border-radius: 10px;
      border: 1px solid rgba(128, 128, 128, 0.2);

      .ant-input {
        background-color: white !important;
        padding-top: 5px;
        font-size: 12px;
        padding-left: 2px;
      }
    }

    .valeur {
      background-color: var(--tertiary-color);
      width: 25px !important;
      height: 20px !important;
      font-size: 12px !important;
      margin-right: 5px;
      border-radius: 7px;
      padding-left: 2px;
      padding-top: 1px;
      margin-left: -6px;
    }
  }



  .moins {
    user-select: none;
    padding: 10.5px 3px 3.5px 5px !important;
    border-radius: 10px 0 0 10px;
    border: 1px solid rgba(128, 128, 128, 0.2);
    cursor: pointer;
    margin-top: 10px;
    font-size: 5px !important;
  }

  .plus {
    user-select: none;
    padding: 10.5px 6px 3.5px 2px !important;
    border-radius: 0 10px 10px 0;
    border: 1px solid rgba(128, 128, 128, 0.2);
    cursor: pointer;
    font-size: 5px !important;
  }

  .nombre4 {
    user-select: none;
    width: 30px !important;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
    text-align: center;

    .ant-input {
      border-radius: 0 !important;
    }

    .nombre-input:hover {
      border-color: rgba(255, 127, 10, 0.3) !important;
    }

    .nombre-input:focus-within {
      border-color: rgba(255, 127, 10, 0.3) !important;
      box-shadow: 0 0 0;
    }

    .nombre-input {
      vertical-align: top !important;
      margin-top: 0 !important;
      height: 21px !important;
      text-align: center;
      background-color: white !important;
      border-top: 1px solid rgba(128, 128, 128, 0.2);
      border-bottom: 1px solid rgba(128, 128, 128, 0.2);
      border-left: 0;
      border-right: 0;

      .ant-input {
        background-color: white !important;
        padding-top: 5px;
      }
    }

    .nombre-input-alter {
      vertical-align: top !important;
      margin-top: 0 !important;
      height: 22px !important;
      text-align: center;
      background-color: white !important;
      border-top: 1px solid rgba(128, 128, 128, 0.2);
      border-bottom: 1px solid rgba(128, 128, 128, 0.2);
      border-left: 0;
      border-right: 0;

      .ant-input {
        background-color: white !important;
        padding-top: 5px;
      }
    }

    .valeur {
      background-color: var(--tertiary-color);
      width: 35px;
      height: 24px;
      margin-right: 5px;
      font-size: 15px;
      padding-left: 2px;
      padding-top: 1px;
      margin-left: -6px;
    }
  }


}
