.profil-page {
  .page-header {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    h1 {
      font-size: 26px;
      font-weight: 600;
    }

    .icon {
      width: 24px;
    }

    .iconSide {
      padding-top: 2px;
      display: inline-block;
    }
  }

  .page-content {
    margin-top: 16px;
    background-color: white;
    border-radius: 20px;

    .profilSidebar {
      padding: 50px 0;

      .inContent {
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        height: 100%;
        padding-top: 10px;

        ul {
          list-style: none;
          padding-inline-start: 25px;

          li {
            cursor: pointer;
            color: var(--text-grey-color);
            padding: 15px 20px;
            width: fit-content;
            border-radius: 30px;
            margin-bottom: 10px;
            font-weight: 300;
          }

          li.active {
            background-color: var(--tertiary-color);
            color: var(--primary-color);
            font-weight: 600;
          }

          li.disabled {
            color: var(--disabled);
          }
        }
      }
    }

    .profilContent {
      padding: 50px 50px;

      .inCOntent {
        padding-top: 10px;

        h2 {
          font-size: 20px;
          margin-bottom: 15px;
        }
        .sub-H2{
          margin-bottom: 40px;
          img{
            vertical-align: top;
            margin-top: 5px;
            width: 20px;
            margin-right: 10px;
            display: inline-block;
          }
          div{
            display: inline-block;
          }
        }

        .profil-part-page {
          .headprofil {
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 20px;
            padding: 26px 25px;
            margin-bottom: 30px;

            .head {
              padding-top: 10px;
              font-weight: 600;
              font-size: 15px;

              .icone {
                margin-top: -5px;
                float: right;
              }
            }

            .imageSide {
              display: inline-block;
              width: 100px;
              height: 100px;

              img {
                width: 100%;
                border-radius: 50%;
              }
            }

            .infSide {
              display: inline-block;
              vertical-align: top;
              padding-left: 25px;
              padding-top: 10px;

              .name {
                font-weight: 600;
                margin-bottom: 10px;
                font-size: 17px;
              }

              .function {
                font-weight: 400;
                color: var(--text-grey-color);
                margin-bottom: 10px;
                opacity: 0.8;
              }

              .location {
                font-weight: 200;
                color: var(--text-grey-color);
                margin-bottom: 10px;
                opacity: 0.8;
              }
            }

            .statusSide {
              float: right;
              display: inline-block;
              vertical-align: top;
              padding-top: 25px;
              padding-right: 10px;

              .vip {
                background-color: green;
                color: white;
                font-weight: 600;
                font-size: 11px;
                padding: 8px 15px;
                border-radius: 20px;
              }
            }

            .content-head {
              margin-top: 30px;

              .titleHead {
                font-weight: 400;
                font-size: 16px;
              }

              span {
                font-size: 13px;
                color: var(--text-grey-color);
              }

              .ant-switch {
                margin-top: 15px;
              }
            }

            .facture-table {
              thead {
                border-radius: 10px;

                tr {
                  th {
                    padding: 8px 16px;
                    font-size: 12px;
                  }
                }

                tr:first-child {
                  *:first-child {
                    border-start-start-radius: 14px !important;
                    border-end-start-radius: 14px !important;
                  }
                }

                tr:first-child {
                  *:last-child {
                    border-start-end-radius: 14px !important;
                    border-end-end-radius: 14px !important;
                  }
                }
              }

              tbody {
                display: none;
              }

              .download {
                img {
                  width: 20px;
                }
              }
            }

            .factures-listes {
              padding: 15px 20px;
              box-shadow: 1px 1px 5px #d9d9d9;
              margin: 20px 0;
              border-radius: 15px;

              .download {
                img {
                  width: 20px;
                }
              }
            }
          }

          .contentProfil {
            margin-bottom: 30px;
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 20px;
            padding: 26px 26px 0;

            .head {
              padding-top: 10px;
              font-weight: 600;
              font-size: 15px;

              .icone {
                margin-top: -5px;
                float: right;
                cursor: pointer;
              }

              .aHead {
                font-size: 14px;
                font-weight: 400 !important;
                margin-right: 50px;
              }
            }

            .content {
              .ant-col {
                padding: 20px 0;

                .label {
                  color: var(--text-grey-color);
                  padding: 12px 0;
                  font-size: 13px;
                }

                .value {
                  font-size: 16px;
                  font-weight: 600;

                  .number-chef {
                    margin-top: -10px;
                    margin-left: -10px;
                    display: inline-block;
                    width: 200px;
                    vertical-align: top;
                    padding-top: 0;
                    padding-left: 0;

                    .PhoneInputCountrySelectArrow {
                      display: none;
                    }

                    .PhoneInputCountry {
                      background-color: white !important;
                      margin-right: 0 !important;
                      border: 0 !important;
                      ;
                      border-radius: 6px 0 0 6px !important;
                      border-right: 0 !important;
                      color: #595959;
                      font-weight: 400;
                      font-family: 'Source Sans Pro', sans-serif;
                    }

                    .PhoneInputInput {
                      font-size: 17px;
                      color: #595959;
                      font-weight: 400;
                      font-family: 'Source Sans Pro', sans-serif !important;
                      background-color: white !important;
                      padding: 9px 0 5px 0 !important;
                      border-radius: 0 6px 6px 0 !important;
                      border-left: 0 !important;
                    }
                  }
                }
              }

            }

            .content-align {
              padding-top: 30px;
              padding-bottom: 30px;

              .default-select {
                padding: 20px 20px;
                border: 1px solid var(--primary-color);
                border-radius: 20px;
                vertical-align: top;

                .image {
                  margin-top: 8px;
                  display: inline-block;
                  vertical-align: top;
                  width: 50px;
                  border-radius: 5px;
                  text-align: center;
                  height: 40px;
                  background-color: var(--disabled-light);

                  img {
                    margin-top: 7px;
                    width: 57%;
                    border-radius: 10px;
                  }
                }

                .desc {
                  margin-left: 15px;
                  display: inline-block;
                  vertical-align: top;
                  padding-top: 5px;

                  .title {
                    font-size: 15px;
                    font-weight: 400;
                  }

                  .subtitle {
                    padding-top: 3px;
                    font-size: 12px;
                    margin-bottom: 8px;
                  }
                }

                .point {
                  float: right;
                  text-align: right;
                  padding-top: 20px;
                  display: inline-block;
                  vertical-align: top;
                }
              }
            }

            .other-card {
              border: 1px solid var(--disabled-light);
              border-radius: 5px;
              padding: 10px;
              margin-bottom: 15px;
              margin-top: 5px;

              .image {
                vertical-align: top;
                width: 20%;
                display: inline-block;
                text-align: center;
                background-color: #FBFBFB;
                padding-top: 5.5px;
                padding-bottom: 3.5px;
                border-radius: 5px;

                img {
                  width: 50%;
                }
              }

              .card-name {
                vertical-align: top;
                padding-top: 7px;
                display: inline-block;
                padding-left: 10px;
                width: 70%;
                color: black;
              }

              .icone.disable {
                a{
                  color: var(--disabled) !important;
                }
              }
              .icone {
                width: 10%;
                padding-top: 5px;
                vertical-align: top;
                font-size: 20px;
                display: inline-block;
              }

            }
          }

          .fermeture-compte {
            .icone {
              display: inline-block;
              width: 70px;
              border-radius: 50%;

              img {
                width: 100%;
                border-radius: 100%;
              }
            }

            .text {
              display: inline-block;
              vertical-align: top;
              padding-left: 30px;
              padding-top: 10px;

              .label {
                margin-bottom: 8px;
                font-size: 18px;
              }

              .indication {
                font-size: 15px;
                color: red;
              }
            }

            .button {
              display: inline-block;
              float: right;
              vertical-align: top;
              padding-top: 13px;

              .button-supprimer {
                border-color: #d9d9d9 !important;
                color: red !important;
                height: 48px;
                padding-left: 20px;
                padding-right: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.editer-information {
  .informations {
    .ant-modal-content {
      border-radius: 25px !important;
      padding: 15px 25px;
    }

    input {
      font-family: Calibri, serif;
      font-weight: 300;
    }

    .contentIfon {
      padding: 15px 20px 15px 20px;
      height: 390px;
      position: relative;

      .title {
        margin-bottom: 40px;

        .icone {
          vertical-align: top;
          display: inline-block;
          width: 20px;
          margin-top: 7px;

          img {
            width: 100%;
          }
        }

        .text {
          padding-left: 10px;
          display: inline-block;
          font-size: 20px;
          margin-bottom: 15px;
        }
      }

      .form-edit-profil {
        .input-barrier {
          position: relative;

          input {
            height: 55px;
            padding-top: 15px;
            background-color: white !important;
            font-size: 19px;
            font-weight: 300 !important;
          }

          .label-effet {
            position: absolute;
            left: 5px;
            padding-left: 5px;
            padding-right: 5px;
            top: 15px;
            font-size: 15px;
            z-index: 999;
            transition: font-size 0.3s ease, top 0.3s ease;
          }

          .force-label-effet {
            position: absolute;
            left: 5px;
            top: -10px;
            font-size: 13px;
            z-index: 999;
            background-color: white;
            padding-left: 5px;
            padding-right: 5px;
            transition: font-size 0.3s ease, top 0.3s ease;
          }
        }

        .input-barrier-number {
          position: relative;

          .ant-form-item-control-input-content {
            border: 1px solid #d9d9d9;
            border-radius: 7px;
            height: 55px;
            background-color: white !important;
            font-size: 19px;

            .PhoneInputCountrySelectArrow {
              display: none;
            }

            .PhoneInputCountry {
              background-color: white !important;
              margin-right: 0 !important;
              border: 0 !important;
              ;
              padding: 8px 10px 5px 15px !important;
              border-radius: 6px 0 0 6px !important;
              border-right: 0 !important;
            }

            .PhoneInputInput {
              font-size: 17px;
              background-color: white !important;
              padding: 16px 0 5px 0 !important;
              border-radius: 0 6px 6px 0 !important;
              border: 0 !important;
            }
          }

          .label-effet {
            position: absolute;
            left: 5px;
            padding-left: 5px;
            padding-right: 5px;
            top: 15px;
            font-size: 15px;
            z-index: 999;
            transition: font-size 0.3s ease, top 0.3s ease;
          }

          .force-label-effet {
            position: absolute;
            left: 5px;
            top: -11px;
            font-size: 13px;
            z-index: 999;
            background-color: white;
            padding-left: 5px;
            padding-right: 5px;
            transition: font-size 0.3s ease, top 0.3s ease;
          }
        }

        .input-barrier:focus-within {
          .label-effet {
            position: absolute;
            left: 5px;
            top: -11px;
            font-size: 13px;
            z-index: 999;
            background-color: white;
            padding-left: 5px;
            padding-right: 5px;
            transition: font-size 0.3s ease, top 0.3s ease;
          }
        }
      }
    }

    .footer-modal {
      position: absolute;
      width: 90%;
      bottom: 20px;

      .button-left {
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
      }

      .button-right {
        padding-left: 15px;
        display: flex;
        justify-content: flex-end;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;

        span {
          color: black;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid black;
        }
      }

      .suivant {
        height: 45px;
        background-color: var(--primary-color);
        padding: 10px 20px 10px 20px;
        color: white;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        border-radius: 7px;
        width: 100%;
        //box-shadow: 0 0 5px var(--primary-color);
      }

      .disable {
        background-color: rgba(241, 240, 240, 1) !important;
      }

      .modifier {
        height: 25px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }
    }
  }
}

.editer-information-facturation {
  .informations {
    .ant-modal-content {
      border-radius: 25px !important;
      padding: 15px 25px;
    }

    input,
    select {
      font-family: Calibri, serif;
      font-weight: 300;
    }

    .contentIfon {
      padding: 15px 20px 15px 20px;
      height: 600px;
      position: relative;

      .title {
        margin-bottom: 40px;

        .icone {
          vertical-align: top;
          display: inline-block;
          width: 20px;
          margin-top: 7px;

          img {
            width: 100%;
          }
        }

        .text {
          padding-left: 10px;
          display: inline-block;
          font-size: 20px;
          margin-bottom: 15px;
        }
      }

      .form-edit-profil {
        .first-block {
          border-right: 1px solid #d9d9d9;
          padding-right: 20px;
        }

        .second-block {
          padding-left: 20px;
        }

        .input-barrier {
          position: relative;

          .ant-select {
            margin-bottom: 10px;
            height: 55px;
          }

          input {
            height: 55px;
            background-color: white !important;
            font-size: 19px;
          }

          .label-effet {
            position: absolute;
            left: 5px;
            padding-left: 5px;
            padding-right: 5px;
            top: 15px;
            font-size: 15px;
            z-index: 999;
            transition: font-size 0.3s ease, top 0.3s ease;
          }

          .force-label-effet {
            position: absolute;
            left: 5px;
            top: -11px;
            font-size: 13px;
            z-index: 999;
            background-color: white;
            padding-left: 5px;
            padding-right: 5px;
            transition: font-size 0.3s ease, top 0.3s ease;
          }
        }

        .input-barrier-number {
          position: relative;

          .ant-form-item-control-input-content {
            border: 1px solid #d9d9d9;
            border-radius: 7px;
            height: 55px;
            background-color: white !important;
            font-size: 19px;

            .PhoneInputCountrySelectArrow {
              display: none;
            }

            .PhoneInputCountry {
              background-color: white !important;
              margin-right: 0 !important;
              border: 0 !important;
              ;
              padding: 8px 10px 5px 15px !important;
              border-radius: 6px 0 0 6px !important;
              border-right: 0 !important;
            }

            .PhoneInputInput {
              font-size: 17px;
              background-color: white !important;
              padding: 13px 0 5px 0 !important;
              border-radius: 0 6px 6px 0 !important;
              border: 0 !important;
            }
          }

          .label-effet {
            position: absolute;
            left: 5px;
            padding-left: 5px;
            padding-right: 5px;
            top: 15px;
            font-size: 15px;
            z-index: 999;
            transition: font-size 0.3s ease, top 0.3s ease;
          }

          .force-label-effet {
            position: absolute;
            left: 5px;
            top: -11px;
            font-size: 13px;
            z-index: 999;
            background-color: white;
            padding-left: 5px;
            padding-right: 5px;
            transition: font-size 0.3s ease, top 0.3s ease;
          }
        }

        .input-barrier:focus-within {
          .label-effet {
            position: absolute;
            left: 5px;
            top: -11px;
            font-size: 13px;
            z-index: 999;
            background-color: white;
            padding-left: 5px;
            padding-right: 5px;
            transition: font-size 0.3s ease, top 0.3s ease;
          }
        }
      }
    }

    .footer-modal {
      position: absolute;
      width: 90%;
      bottom: 20px;

      .button-left {
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
      }

      .button-right {
        padding-left: 25px;
        display: flex;
        justify-content: flex-end;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;

        span {
          color: black;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid black;
        }
      }

      .suivant {
        height: 45px;
        background-color: var(--primary-color);
        padding: 10px 20px 10px 20px;
        color: white;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        border-radius: 7px;
        width: 100%;
        //box-shadow: 0 0 5px var(--primary-color);
      }

      .disable {
        background-color: rgba(241, 240, 240, 1) !important;
      }

      .modifier {
        height: 25px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }
    }
  }
}


.profil-page-padding{
  padding: 0 10%;
}
@media screen and (max-width: 1820px){
  .profil-page-padding{
    padding: 0 0 !important;
  }
}