.ant-modal-content {
    border-radius: 28px !important;
    padding: 10px;

    h2 {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 25px;
    }
}

.ant-modal-title {
    font-weight: 700;
    font-size: 18px;
}