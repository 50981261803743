.ant-btn {
    height: 37px;
    border-radius: 10px !important;
    font-family: 'Source Sans Pro' !important;

    .icon {
        margin-right: 5px;
    }
}


.ant-btn-icon {
    display: flex;
    align-items: center;
}

.ant-btn-primary {
    color: #fff;
    border-radius: 5px;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
    font-family: 'Source Sans Pro' !important;
}

.ant-btn-secondary {
    color: #fff;
    border-radius: 5px;
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
}

.ant-btn-default {
    border-radius: 5px;
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color);
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    // padding: 3px 22px;
    font-size: 0.8125rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 16px;
    height: auto;
    border: none;
    border-radius: 5px;
    transition: color .4s, background-color .4s, border-color .4s, box-shadow .4s
}

.btn-lg {
    width: -webkit-fill-available;
}

.btn-sm {
    padding: 0.25rem 0.5rem !important;
    font-size: 0.710938rem;
    line-height: 1.5;
}

.btn-primary {
    background-color: var(--primary-color);
    color: #fff !important;
    padding: 0.6rem .75rem;
}

.btn-primary:hover {
    color: #fff !important;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.btn-default {
    border: 1px solid #8080805e !important;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;
}

.btn-default-gray{
    border: 1px solid #8080805e !important;
}

.btn-default-gray:hover{
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.btn-primary-effect{
    background-color: var(--primary-color);
    transition: background-color 0.3s ease;
}

.btn-primary-effect:hover {
    background-color: #CE6608 !important;
}

.btn-link {
    font-weight: 600;
    cursor: pointer;
    color: var(--primary-color);
}

.btn-primary.focus,
.btn-primary:focus {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    box-shadow: 0 0 0 .2rem rgba(105, 136, 228, .5)
}

.btn-secondary:focus,
.btn-secondary:active {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    color: #fff;
}

.btn-primary-outline {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}



.btn-secondary {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
}

.btn-secondary:hover {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    color: #fff;
}

.edit-link {
    color: var(--primary-color) !important;
}

.delete-link {
    color: red !important;
}

.ant-alert-message {
    font-family: 'Source Sans Pro' !important;
}

.btn-gray{
    color: black !important;
    padding: 0.1rem .25rem !important;
    border: 1px solid black;
}


.btn-no-cursor{
    cursor: default !important;
}