.auth {
    min-height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);

    .login-page {
        min-height: 97vh;
        width: 98%;
        border-radius: 20px;
        background-color: white;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;


        h1 {
            font-size: 27px;
            margin: initial;
            font-weight: 600;
        }

        .logo {
            width: 30%;
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-bottom: 40px;
        }

        .forget-password {
            margin-top: 5px;
            color: var(--primary-color);
            display: flex;
            justify-content: flex-end;
        }

        .text-info {
            color: var(--text-gray-color);
            margin-bottom: 30px;
        }

        .blc-content {
            width: 340px;

            @media (max-width: 767px) {
                padding: 43px;
            }

            @media (max-width: 400px) {
                padding: 43px;
            }

            p {
                color: #0000008c;
            }

            .confidentialite {
                font-size: 13px;
                text-align: center;
            }
        }

        .ant-col-offset-10 {
            @media (max-width: 767px) {
                margin-left: inherit
            }

            @media (max-width: 400px) {
                margin-left: inherit
            }
        }

        .login-logo-topleft {
            position: absolute;
            top: 3vh;
            left: 2%;
            width: 40px;
        }
        label{
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 400;
        }
    }

    .section-reset-password {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;


        h1 {
            text-align: center;
            font-size: 30px;
            margin-bottom: 40px;
        }

        .logo {
            width: 30%;
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-bottom: 40px;
        }

        .text-info {
            color: var(--text-gray-color);
            margin-bottom: 30px;
        }

        .ant-form-item {
            margin-bottom: 8px;
        }
    }

    .ant-input {
        background-color: #f5f5f5 !important;
        border-color: #efefef6e !important;
        border: 1.9px solid #efefef6e !important;
    }

    .ant-input-password {
        background-color: #efefefa8 !important;
        border-color: #efefef6e !important;
        border: 1.9px solid #efefef6e !important;

        .ant-input {
            border: 0px !important;
        }
    }

    .ant-input-password:focus {
        border-color: red !important;
    }

    .ant-input:placeholder-shown {
        text-overflow: ellipsis;
        font-family: 'Source Sans Pro' !important;
    }

    .ant-input:focus {
        border: 1.9px solid var(--primary-color) !important;
    }

    .ant-input-password {
        .ant-input:focus {
            border: 0px !important;
        }
    }

    :where(.css-mzwlov).ant-input-outlined:focus,
    :where(.css-mzwlov).ant-input-outlined:focus-within {
        border: 1.9px solid var(--primary-color) !important;
    }
}

.radio-btn {
    display: flex;
    justify-content: space-between;
    box-shadow: #00000036 0px 2px 6px;
    border-radius: 10px;
    padding: 8px 20px;
    margin-bottom: 9px;
    cursor: pointer;
    font-family: 'Source Sans Pro' !important;

    .icon {
        font-size: 19px;
    }
}

.radio-btn-active {
    .icon {
        color: var(--primary-color);
    }
}

.radio-btn-disabled {
    color: gray;
}


.ant-divider {
    margin: 34px 0px
}

.ant-divider-inner-text {
    color: gray;
    font-weight: 400;
}

.text-cgu {
    font-size: 12px;
    color: #80808091;

    a {
        color: var(--primary-color);
    }
}

input,
.ant-input-password {
    background-color: #EFEFEF !important;
    border: none;
}

.PhoneInput {
    background-color: #EFEFEF !important;
    padding: 3px 10px;
    border-radius: 7px;
    border: 0px;
}


.PhoneInputCountryIcon--border {
    background-color: transparent;
    box-shadow: none;
}


.countdown {
    color: var(--primary-color);
}

.auth-content {
    background: white;
    border-radius: 20px;
    height: 100vh;
}

.btn-resend-mail {
    margin-top: 43px;
    cursor: pointer;
    // text-align: center;

    .icon {
        margin-left: 6px;
    }
}

.footer {
    position: absolute;
    text-align: center;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: inherit;

    a {
        color: var(--primary-color);
    }
}



@media (max-width: 668px) {
    .auth-page {
        margin-bottom: 100px;
    }
}

.auth {
    min-height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);

    .login-page {
        min-height: 97vh;
        width: 98%;
        border-radius: 20px;
        background-color: white;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;


        h1 {
            font-size: 27px;
            margin: initial;
            font-weight: 600;
        }

        .logo {
            width: 30%;
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-bottom: 40px;
        }

        .forget-password {
            margin-top: 5px;
            color: var(--primary-color);
            display: flex;
            justify-content: flex-end;
        }

        .text-info {
            color: var(--text-gray-color);
            margin-bottom: 30px;
        }

        .blc-content {
            width: 340px;

            @media (max-width: 767px) {
                padding: 43px;
            }

            @media (max-width: 400px) {
                padding: 43px;
            }

            p {
                color: #0000008c;
            }

            .confidentialite {
                font-size: 13px;
                text-align: center;
            }
        }

        .ant-col-offset-10 {
            @media (max-width: 767px) {
                margin-left: inherit
            }

            @media (max-width: 400px) {
                margin-left: inherit
            }
        }

        .login-logo-topleft {
            position: absolute;
            top: 3vh;
            left: 2%;
            width: 40px;
        }
    }

    .section-reset-password {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;


        h1 {
            text-align: center;
            font-size: 30px;
            margin-bottom: 40px;
        }

        .logo {
            width: 30%;
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-bottom: 40px;
        }

        .text-info {
            color: var(--text-gray-color);
            margin-bottom: 30px;
        }

        .ant-form-item {
            margin-bottom: 8px;
        }
    }

}

.radio-btn {
    display: flex;
    justify-content: space-between;
    box-shadow: #00000036 0px 2px 6px;
    border-radius: 10px;
    padding: 8px 20px;
    margin-bottom: 9px;
    cursor: pointer;

    .icon {
        font-size: 19px;
    }
}

.radio-btn-active {
    .icon {
        color: var(--primary-color);
    }
}

.radio-btn-disabled {
    color: gray;
}


.ant-divider {
    margin: 34px 0px
}

.ant-divider-inner-text {
    color: gray;
    font-weight: 400;
}

.text-cgu {
    font-size: 12px;
    color: gray;

    a {
        color: var(--primary-color);
    }
}

input,
.ant-input-password {
    background-color: #EFEFEF !important;
    border: none;
}

.PhoneInput {
    //background-color: #EFEFEF !important;
    background-color: white !important;
    //padding: 3px 10px;
    border-radius: 7px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: 0 !important;
}

.PhoneInputCountry {
    background-color: #f5f5f5 !important;
    border: 1px solid rgba(239, 239, 239, 0.431372549) !important;
    margin-right: 20px !important;
    padding: 2px 15px;
    border-radius: 6px;
    height: 35px;
}

.PhoneInputInput {
    background-color: #f5f5f5 !important;
    border: 1px solid rgba(239, 239, 239, 0.431372549) !important;
    border-radius: 6px;
    height: 24px;
    padding-left: 60px !important;
    padding-right: 34px !important;
}

.PhoneInputCountrySelectArrow {
    margin-left: 15px !important;
}

.PhoneInputCountryIcon--border {
    background-color: transparent;
    box-shadow: none;
}

.PhoneInputCountryIcon--border {
    background-color: transparent !important;
    box-shadow: 0 0 0 transparent !important;
}

.countdown {
    color: var(--primary-color);
}

.auth-content {
    background: white;
    border-radius: 20px;
    height: 100vh;
}

.btn-resend-mail {
    margin-top: 43px;
    cursor: pointer;
    // text-align: center;

    .icon {
        margin-left: 6px;
    }
}

.footer {
    position: absolute;
    text-align: center;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: inherit;

    a {
        color: var(--primary-color);
        text-decoration: none;
    }
}



@media (max-width: 668px) {
    .auth-page {
        margin-bottom: 100px;
    }
}