.planning-page {
  margin: 0px 18px;

  .page-header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    h1 {
      font-size: 22px;
      font-weight: 600;
    }
  }

  .calendar-zone {
    padding: 25px;
    background-color: white;
    border-radius: 20px;
    height: 900px;
  }

  .apercu-zone {
    padding-left: 25px;

    .content-zone {
      background-color: white;
      border-radius: 20px;
      width: 100%;
      height: 900px;
      padding-top: 60px;
      padding-left: 30px;
      padding-right: 30px;

      .title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 50px;
      }
    }
  }

  .empty-blc {
    justify-content: center;
    margin-bottom: 60px;
    margin-top: 60px;
    display: flex;

    .blc {
      text-align: left;
      width: 350px;
    }

    h4 {
      font-size: 20px;
      color: black;
      margin: inherit;
    }

    p {
      margin: 0px;
      margin-bottom: 9px;
    }

    .en-savoir-plus {
      margin-bottom: 20px;
      display: block;
    }

    .home-icon {
      margin-bottom: 6px;
      background: #f5f5f5;
      display: inline-block;
      border-radius: 7px;
      padding: 7px 11px;

      img {
        width: 30px;
      }
    }

  }

  .blc-left {
    display: inline-block;
  }

  .blc-right {
    .cree-planning {
      box-shadow: 0 0 5px var(--primary-color);
      border-radius: 10px;

      .ant-btn-compact-first-item {
        width: 220px;
        font-size: 16px;
        border-right: 1px solid white !important;
        margin-inline-end: 0 !important;

        .icon-logement-add {
          display: inline-block;
          vertical-align: top;
          width: 30px;
          padding-top: 2px;
          margin-right: 10px;

          img {
            width: 100%;
          }
        }
      }

      .ant-btn-compact-first-item:hover {
        background-color: var(--primary-color);
        color: white;
      }

      .ant-btn-compact-last-item:hover {
        background-color: var(--primary-color);
        color: white;
      }

      .ant-btn-compact-last-item {
        .icon-logement-add {
          display: inline-block;
          width: 20px;

          img {
            width: 100%;
          }
        }
      }
    }

    .ant-btn {
      border-radius: 0% !important
    }

    .ant-dropdown-trigger {
      background-color: var(--primary-color);
      color: white;
    }

    .ant-btn-compact-first-item {
      background-color: var(--primary-color);
      color: white;
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    .ant-btn-compact-last-item {
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }
  }

  .blc-filter {
    background-color: white;
    display: inline-block;
    padding: 3px 0px 3px 14px;
    border-radius: 10px;
    border: 1px solid rgba(5, 5, 5, 0.06);
    margin-right: 14px;

    .ant-select-selector {
      padding: 0% 22px !important;
      border-radius: 10px !important;
      border: 0px !important;
      box-shadow: 0 0 0 !important;
    }

    .ant-select-focused:where(.css-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
      box-shadow: none !important;
    }
  }

  .planning-calendar {
    .ant-picker-content {
      thead {
        display: none;
      }
    }

    .ant-picker-calendar-date {
      border-top: 0px !important;
    }

    .ant-picker-cell {
      border: 1px solid rgba(0, 0, 0, 0.1);

      .ant-picker-calendar-date-value {
        color: rgba(0, 0, 0, 0.2);
      }

      .ant-picker-cell-inner {
        .line {
          background-color: var(--primary-color);
        }
      }
    }

    .ant-picker-cell-in-view {
      .ant-picker-calendar-date-value {
        color: var(--primary-color) !important;
      }
    }

    .ant-picker-cell-selected {
      background-color: var(--primary-color);

      .ant-picker-calendar-date-value {
        color: white !important;
      }

      .ant-picker-cell-inner {
        border: 0 !important;
        background-color: var(--primary-color) !important;
      }

      .ant-picker-cell-inner {
        .line {
          background-color: white !important;
        }
      }
    }

    .ant-picker-cell-today {
      .ant-picker-cell-inner {
        border: 0 !important;
      }
    }

    .ant-picker-cell-inner {
      padding-left: 20px;

      .ant-picker-calendar-date-value {
        position: absolute;
        font-size: 23px;
        padding-top: 27px;
        padding-bottom: 17px;
        text-align: center;
        width: 65%;
        z-index: 1;
      }
    }

    .ant-picker-calendar-date-content {
      margin-top: 10px;
      position: relative;
    }
  }

  .events {
    padding-left: 0;

    .line {
      position: absolute;
      width: 85%;
      bottom: 20px;
      height: 8px;
      z-index: 999;
    }
  }

  .custom-weekdays {
    padding-top: 50px;
    padding-bottom: 20px;
    text-transform: uppercase;

    div {
      display: inline-block;
      text-align: center;
      font-size: 17px;
      width: 14.286%;
      font-weight: bold;
    }
  }

  .monthhead {
    padding-top: 35px;

    .left {
      text-align: left;
      font-size: 35px;
      display: inline-block;
      width: 5%;
      cursor: pointer;
    }

    .month {
      vertical-align: top;
      padding-top: 13px;
      font-weight: bold;
      text-align: center;
      width: 90%;
      font-size: 22px;
      display: inline-block;
    }

    .right {
      text-align: right;
      width: 5%;
      font-size: 35px;
      display: inline-block;
      cursor: pointer;
    }
  }

  .titleTable {
    font-weight: 600;
  }

  .subtitleTable {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.2);
  }

  .imageTableContent {
    text-align: center;

    .imageTable {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .badgeTable {
    .ant-badge {
      width: 70px;

      .ant-badge-count {
        height: 35px;
        padding: 7px;
        border-radius: 20px;
      }
    }
  }

  .planning-table {
    .ant-table-selection-column {
      padding-left: 15px;
    }

    .ant-table-thead {
      tr {
        .ant-table-cell {
          padding: 10px 16px !important;
          font-family: 'Source Sans Pro', sans-serif;
          font-weight: 600;
          color: #263754;
        }
      }
    }

    tbody {
      tr {
        td {
          font-family: 'Source Sans Pro', sans-serif;
          font-weight: 400;
          color: #566F8F !important;
        }
      }
    }
  }
}

.programmer-manuellement {
  background-color: var(--primary-color);
  border-radius: 25px !important;
  height: 250px;

  // :where(.css-dev-only-do-not-override-kghr11).ant-select-single .ant-select-selector .ant-select-selection-search {
  // position: relative !important;
  // }

  .ant-modal-content {
    border-radius: 25px !important;
    padding: 10px 20px;
  }

  .programmer-m {
    padding: 25px 5px 5px 5px;
    height: 950px;
    position: relative;

    .researchInput {
      margin-right: 10px;

      // border: 1px solid var(--disabled-light);
      .input {
        border: 1px solid var(--disabled);
        border-radius: 8px;
        padding: 3px 10px;

        .label {
          background-color: var(--tertiary-color);
          display: inline-block;
          font-size: 15px;
          padding: 2px 10px;
          border-radius: 5px;

          .close {
            vertical-align: top;
            display: inline-block;
            margin-left: 10px;
            margin-top: 3px;
          }
        }
      }
    }

    .title-desc {
      padding-top: 6px;

      .title-label {
        font-size: 18px;
        margin-bottom: 15px;
      }

      .liste {
        .point-label {
          vertical-align: top;
          margin-top: 5px;

          .point {
            vertical-align: top;
            margin-right: 15px;
            display: inline-block;
            width: 20px;

            img {
              width: 100%;
            }
          }

          .label {
            vertical-align: top;
            display: inline-block;
          }
        }

        .trait-explication {
          height: 35px;

          .trait {
            display: inline-block;
            width: 1px;
            margin-left: 9px;
            margin-right: 23px;
            height: 85%;
            background-color: var(--disabled-light);
          }

          .trait-invisible {
            display: inline-block;
            background-color: white !important;
          }

          .explication {
            vertical-align: top;
            margin-top: -6px;
            display: inline-block;
          }
        }
      }

      .gray-zone {
        padding: 17px 20px;
        background-color: rgba(241, 240, 240, 1);
        border-radius: 30px;
        height: 140px;

        .point-label {
          vertical-align: top;
          margin-top: 5px;

          .point {
            vertical-align: top;
            margin-right: 15px;
            display: inline-block;
            width: 25px;

            img {
              width: 100%;
            }
          }

          .label {
            margin-top: 3px;
            vertical-align: top;
            display: inline-block;
          }
        }

        .trait-explication {

          //height: 35px;
          .trait {
            display: inline-block;
            width: 1px;
            margin-left: 14px;
            margin-right: 23px;
            height: 85%;
            background-color: var(--disabled);
          }

          .trait-invisible {
            display: inline-block;
            background-color: transparent !important;
          }

          .explication {
            vertical-align: top;
            margin-top: -1px;
            display: inline-block;
            font-size: 12px;
          }
        }
      }
    }

    input {
      background-color: white !important;
    }

    .choix-date {
      margin-top: 15px;

      .date {
        display: inline-block;
        color: #98A1AC;
      }

      .date-choix {
        margin-left: 20px;
        display: inline-block;
        color: var(--primary-color);
        cursor: pointer;
      }
    }

    .select-service {
      margin-top: 15px;
      margin-bottom: 7.5px;
      color: #98A1AC;
    }

    .contour-selection {
      border: 1px solid var(--disabled-light);
      border-radius: 20px;

      .ant-radio {
        width: auto !important;
      }

      .radio {
        .ant-radio-group {
          width: 100%;

          .ant-radio-wrapper {
            width: 100%;
          }
        }

        .ant-radio-input {
          height: 10px;
          width: 10px;
        }

        .ant-radio-inner {
          width: 20px;
          height: 20px;
        }

        .ant-radio-inner::after {
          width: 30px;
          height: 30px;
          margin-top: -15px;
          margin-left: -15px;
        }

        .radio-mode-medium-border {
          border-bottom: 0 !important;

          .ant-radio {
            margin-top: -40px;
          }
        }

        .radio-mode {
          width: 100%;
          padding: 10px;
          border-bottom: 1px solid var(--disabled-light);
          vertical-align: top;

          .icone1 {
            margin-top: 12px;
            margin-left: 15px;
            width: 35px;
          }

          .icone2 {
            margin-top: 12px;
            margin-left: 20px;
            width: 30px;
          }

          .icone3 {
            margin-top: 16px;
            margin-left: 20px;
            width: 30px;
          }

          .title {
            margin-top: 7px;
            font-size: 14px;
            margin-bottom: 0;
          }

          .subtitle {
            font-size: 13px;
          }
        }

        .check-zone {
          text-align: center;
          padding-bottom: 10px;
          margin-top: -25px;

          .choice {
            cursor: pointer;
            margin: 10px 10px 10px 10px;
            display: inline-block;
            border-radius: 50px;
            border: 1px solid darkgrey;
            padding: 5px 20px 5px 20px;
            color: #A6A6A6;
          }

          .disabled-option {
            background-color: var(--disabled-light);
          }

          .active {
            background-color: var(--primary-color);
            color: white;
            border: 1px solid var(--primary-color);
          }
        }
      }
    }

    .title {
      margin-bottom: 15px;
      text-align: left;
      font-weight: 500;
      font-size: 25px;

      .title-img {
        display: inline-block;
        width: 22px;
        border-radius: 50%;
        padding-top: 4.5px;
      }

      .title-label {
        display: inline-block;
        vertical-align: top;
        padding-left: 5px;
      }
    }

    span {
      font-size: 13px;
      width: 100%;
    }

    .footer-modal {
      position: absolute;
      width: 98%;
      bottom: 15px;

      .button-left {
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
      }

      .button-right {
        display: flex;
        justify-content: flex-end;
      }

      .suivant {
        height: 20px;
        background-color: var(--primary-color);
        padding: 7px 20px 10px 20px;
        color: white;
        font-weight: 500;
        border-radius: 7px;
        box-shadow: 0 0 5px var(--primary-color);
      }

      .modifier {
        height: 18px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;

        span {
          color: red;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid red;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }
  }

  .depot-linge {
    padding: 30px 50px;
    height: 780px;

    .contour-point-gris {
      height: auto !important;
    }

    .ant-divider-horizontal {
      margin: 5px 0 !important;
    }

    .gestion-complete {
      .subTitle {
        margin-bottom: 10px;
      }
    }

    .blc-content {
      border: 1px solid rgba(241, 240, 240, 1);
      border-radius: 20px;
      padding: 0px 30px;

      .contour-point-gris {
        .counter-side {
          vertical-align: top;
          padding-top: 14px;
        }
      }
    }

    .qualite-linge-image-zone {
      width: 100%;
      margin-top: 10px;
      height: 60px;
      border-radius: 10px;
      overflow: hidden;
      border: 3px solid transparent;

      .qualite-linge-image {
        width: 100%;
        background-position: center;
      }
    }

    .footer-modal {
      position: absolute;
      width: 100%;
      bottom: 40px;

      .button-left {
        display: flex;
        justify-content: flex-start;
      }

      .button-right {
        display: flex;
        justify-content: flex-start;
      }

      .suivant {
        height: 20px;
        background-color: var(--primary-color);
        padding: 7px 20px 10px 20px;
        color: white;
        font-weight: 500;
        border-radius: 7px;
        //box-shadow: 0 0 5px red;
      }

      .modifier {
        height: 18px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }
  }

  .kit-linge {
    background-color: var(--primary-color);
    border-radius: 15px !important;
    height: 250px;

    .ant-modal-content {
      border-radius: 25px !important;
      padding: 10px 20px;
    }

    .kit {
      padding: 1px 1px 1px 1px;
      height: 450px;
      position: relative;

      .head {
        vertical-align: top;

        .arrow {
          width: 5%;
          font-weight: bold;
          display: inline-block;
        }

        .title {
          width: 85%;
          font-weight: bold;
          margin-left: 20px;
          display: inline-block;
          vertical-align: top;

          .title-in {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 0px;
          }
        }
      }

      .moins {
        user-select: none;
        padding: 5.5px 3px 5.5px 5px;
        border-radius: 5px 0 0 5px;
        border: 1px solid rgba(128, 128, 128, 0.2);
        cursor: pointer;
        font-size: 10px;
      }

      .plus {
        user-select: none;
        padding: 5.5px 6px 5.5px 2px;
        border-radius: 0 5px 5px 0;
        border: 1px solid rgba(128, 128, 128, 0.2);
        cursor: pointer;
        font-size: 10px;
      }

      .nombre {
        user-select: none;
        width: 30px;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 0;
        text-align: center;

        .ant-input {
          border-radius: 0 !important;
        }

        .nombre-input:hover {
          border-color: rgba(255, 127, 10, 0.3) !important;
        }

        .nombre-input:focus-within {
          border-color: rgba(255, 127, 10, 0.3) !important;
          box-shadow: 0 0 0;
        }

        .nombre-input {
          margin-top: 0px;
          height: 26px;
          text-align: center;
          background-color: white !important;
          border-top: 1px solid rgba(128, 128, 128, 0.2);
          border-bottom: 1px solid rgba(128, 128, 128, 0.2);
          border-left: 0;
          border-right: 0;

          .ant-input {
            background-color: white !important;
            padding-top: 5px;
          }
        }

        .valeur {
          background-color: var(--tertiary-color);
          width: 35px;
          height: 24px;
          margin-right: 5px;
          font-size: 15px;
          padding-left: 2px;
          padding-top: 1px;
          margin-left: -6px;
        }
      }

      .separator {
        text-align: center;
        padding-top: 10px;
      }

      .footer-modal {
        position: absolute;
        width: 98%;
        bottom: 10px;

        .button-left {
          display: flex;
          justify-content: flex-start;
          padding-left: 10px;
        }

        .button-right {
          display: flex;
          justify-content: flex-end;
        }

        .suivant {
          height: 20px;
          background-color: var(--primary-color);
          padding: 7px 20px 10px 20px;
          color: white;
          font-size: 16px;
          text-align: center;
          font-weight: 500;
          border-radius: 7px;
          width: 100%;
          //box-shadow: 0 0 5px var(--primary-color);
        }

        .modifier {
          height: 18px;
          background-color: white;
          padding: 7px 20px 10px 20px;
          color: var(--primary-color);
          font-weight: 500;
          border: 1px solid var(--primary-color);
          border-radius: 7px;
        }
      }
    }

    .depot-linge {
      padding: 30px 50px;
      height: 780px;

      .ant-divider-horizontal {
        margin: 5px 0 !important;
      }

      .gestion-complete {
        .subTitle {
          margin-bottom: 10px;
        }
      }

      .blc-content {
        border: 1px solid rgba(241, 240, 240, 1);
        border-radius: 20px;
        padding: 0px 30px;

        .contour-point-gris {
          .counter-side {
            vertical-align: top;
            padding-top: 14px;
          }
        }
      }

      .qualite-linge-image-zone {
        width: 100%;
        margin-top: 10px;
        height: 60px;
        border-radius: 10px;
        overflow: hidden;
        border: 3px solid transparent;

        .qualite-linge-image {
          width: 100%;
          background-position: center;
        }
      }

      .footer-modal {
        position: absolute;
        width: 100%;
        bottom: 40px;

        .button-left {
          display: flex;
          justify-content: flex-start;
        }

        .button-right {
          display: flex;
          justify-content: flex-start;
        }

        .suivant {
          height: 20px;
          background-color: var(--primary-color);
          padding: 7px 20px 10px 20px;
          color: white;
          font-weight: 500;
          border-radius: 7px;
          //box-shadow: 0 0 5px red;
        }

        .modifier {
          height: 18px;
          background-color: white;
          padding: 7px 20px 10px 20px;
          color: var(--primary-color);
          font-weight: 500;
          border: 1px solid var(--primary-color);
          border-radius: 7px;
        }

        .annuler {
          height: 20px;
          padding: 7px 0 10px 0;
          color: #404040;
          font-weight: 500;

          span {
            color: #404040;
            padding-bottom: 2px;
            padding-left: 3px;
            padding-right: 3px;
            border-bottom: 2.3px solid #404040;
          }
        }

        .annuler-red {
          padding: 15px 20px;
          color: red !important;
          font-weight: 500;

          span {
            padding-bottom: 2px;
            padding-left: 3px;
            padding-right: 3px;
            border-bottom: 3px solid red;
          }
        }
      }
    }
  }

  .kit-accueil {
    background-color: var(--primary-color);
    border-radius: 25px !important;
    height: 250px;

    .ant-modal-content {
      border-radius: 15px !important;
      padding: 10px 20px;
    }

    .kit {
      padding: 1px 1px 1px 1px;
      height: 570px;
      position: relative;

      .head {
        vertical-align: top;

        .arrow {
          width: 5%;
          font-weight: bold;
          display: inline-block;
        }

        .title {
          width: 85%;
          font-weight: bold;
          margin-left: 20px;
          display: inline-block;
          vertical-align: top;

          .title-in {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 0px;
          }
        }
      }

      .moins {
        user-select: none;
        padding: 5.5px 3px 5.5px 5px;
        border-radius: 5px 0 0 5px;
        border: 1px solid rgba(128, 128, 128, 0.2);
        cursor: pointer;
        font-size: 10px;
      }

      .plus {
        user-select: none;
        padding: 5.5px 6px 5.5px 2px;
        border-radius: 0 5px 5px 0;
        border: 1px solid rgba(128, 128, 128, 0.2);
        cursor: pointer;
        font-size: 10px;
      }

      .nombre {
        user-select: none;
        width: 30px;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 0;
        text-align: center;

        .ant-input {
          border-radius: 0 !important;
        }

        .nombre-input:hover {
          border-color: rgba(255, 127, 10, 0.3) !important;
        }

        .nombre-input:focus-within {
          border-color: rgba(255, 127, 10, 0.3) !important;
          box-shadow: 0 0 0;
        }

        .nombre-input {
          margin-top: 0px;
          height: 26px;
          text-align: center;
          background-color: white !important;
          border-top: 1px solid rgba(128, 128, 128, 0.2);
          border-bottom: 1px solid rgba(128, 128, 128, 0.2);
          border-left: 0;
          border-right: 0;

          .ant-input {
            background-color: white !important;
            padding-top: 5px;
          }
        }

        .valeur {
          background-color: var(--tertiary-color);
          width: 35px;
          height: 24px;
          margin-right: 5px;
          font-size: 15px;
          padding-left: 2px;
          padding-top: 1px;
          margin-left: -6px;
        }
      }

      .separator {
        text-align: center;
        padding-top: 10px;
      }

      .footer-modal {
        position: absolute;
        width: 98%;
        bottom: 10px;

        .button-left {
          display: flex;
          justify-content: flex-start;
          padding-left: 10px;
        }

        .button-right {
          display: flex;
          justify-content: flex-end;
        }

        .suivant {
          height: 20px;
          background-color: var(--primary-color);
          padding: 7px 20px 10px 20px;
          color: white;
          font-size: 16px;
          text-align: center;
          font-weight: 500;
          border-radius: 7px;
          width: 100%;
          //box-shadow: 0 0 5px var(--primary-color);
        }

        .modifier {
          height: 18px;
          background-color: white;
          padding: 7px 20px 10px 20px;
          color: var(--primary-color);
          font-weight: 500;
          border: 1px solid var(--primary-color);
          border-radius: 7px;
        }
      }
    }

    .depot-linge {
      padding: 30px 50px;
      height: 780px;

      .ant-divider-horizontal {
        margin: 5px 0 !important;
      }

      .gestion-complete {
        .subTitle {
          margin-bottom: 10px;
        }
      }

      .blc-content {
        border: 1px solid rgba(241, 240, 240, 1);
        border-radius: 20px;
        padding: 0px 30px;

        .contour-point-gris {
          .counter-side {
            vertical-align: top;
            padding-top: 14px;
          }
        }
      }

      .qualite-linge-image-zone {
        width: 100%;
        margin-top: 10px;
        height: 60px;
        border-radius: 10px;
        overflow: hidden;
        border: 3px solid transparent;

        .qualite-linge-image {
          width: 100%;
          background-position: center;
        }
      }

      .footer-modal {
        position: absolute;
        width: 100%;
        bottom: 40px;

        .button-left {
          display: flex;
          justify-content: flex-start;
        }

        .button-right {
          display: flex;
          justify-content: flex-start;
        }

        .suivant {
          height: 20px;
          background-color: var(--primary-color);
          padding: 7px 20px 10px 20px;
          color: white;
          font-weight: 500;
          border-radius: 7px;
          //box-shadow: 0 0 5px red;
        }

        .modifier {
          height: 18px;
          background-color: white;
          padding: 7px 20px 10px 20px;
          color: var(--primary-color);
          font-weight: 500;
          border: 1px solid var(--primary-color);
          border-radius: 7px;
        }

        .annuler {
          height: 20px;
          padding: 7px 0 10px 0;
          color: #404040;
          font-weight: 500;

          span {
            color: #404040;
            padding-bottom: 2px;
            padding-left: 3px;
            padding-right: 3px;
            border-bottom: 2.3px solid #404040;
          }
        }

        .annuler-red {
          padding: 15px 20px;
          color: red !important;
          font-weight: 500;

          span {
            padding-bottom: 2px;
            padding-left: 3px;
            padding-right: 3px;
            border-bottom: 3px solid red;
          }
        }
      }
    }
  }



  .detail-presta-dropdown {
    padding: 5px !important;

    span {
      margin-left: 10px;
    }
  }

  .detail-presta-dropdown:hover {
    background-color: red !important;
  }

  .ant-dropdown-menu-submenu .ant-dropdown-menu:has(.reporter-prestation) {
    margin-top: -5px;
    margin-left: 15px;
  }

  .ant-dropdown-menu-submenu .ant-dropdown-menu:has(.annuler-prestation) {
    margin-top: -36px;
    margin-left: 15px;
  }

  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:has(.annuler-prestation) {
    cursor: default;
  }

  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:has(.annuler-prestation):hover {
    background-color: transparent;
  }

  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active:has(.annuler-prestation) {
    background-color: transparent;
  }

  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:has(.reporter-prestation):hover {
    background-color: transparent;
  }

  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active:has(.reporter-prestation) {
    background-color: transparent;
  }

  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:has(.reporter-prestation) tr {
    height: 27px !important;
  }

  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:has(.reporter-prestation) thead {
    display: none;
  }

  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:has(.detail-presta-dropdown):hover {
    background-color: var(--tertiary-color);
  }

  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active:has(.detail-presta-dropdown) {
    background-color: var(--tertiary-color);
  }

  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:has(.detail-presta-dropdown):hover {
    background-color: var(--tertiary-color);
  }

  .ant-dropdown-menu-submenu-expand-icon {
    display: none;
  }


  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-weight: bold;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #f6f6f6;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu-item {
    padding-left: 16px;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
    text-align: center;
    cursor: default;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu {
    max-height: 300px;
  }
}

.apercu-programmer {

  //background-color: var(--primary-color);
  //border-radius: 25px !important;
  //height: 250px;
  .ant-modal-content {
    background: #f5f5f5;
  }

  .programmer-a {
    padding: 10px 15px 10px 15px;
    height: 1000px;
    position: relative;

    .title {
      margin-bottom: 25px;

      .indice {
        color: red;
        margin-bottom: 5px;
      }

      .title-label {
        font-size: 20px;
        display: inline-block;
      }

      .edit-icon {
        margin-left: 15px;
        display: inline-block;
        width: 20px;

        img {
          width: 100%;
        }
      }

      .status {
        margin-left: 30px;
        display: inline-block;
        vertical-align: top;

        .ant-badge .ant-badge-count {
          height: 27px;
        }

        .ant-badge .ant-badge-multiple-words {
          padding: 3px 16px;
        }
      }
    }

    .content-box-padding-left {
      padding-right: 30px;
    }

    .content-min-box-padding-left {
      padding-right: 10px;
    }

    .content-min-box-padding-right {
      padding-left: 10px;
    }

    .content-box-top {
      height: 420px;
    }

    .content-box-bottom {
      height: 400px;
    }
    .content-box-bottom.controle-qualite-box {
      height: 110px;
    }
    .content-box-bottom.activite-box {
      height: 230px;
    }
    .content-box-bottom.lit-kit-box{
      height: 170px;
    }
    .lit-kit-box{
      .info-supp.first{
        margin-bottom: 25px;
        margin-top: 20px;
      }
      .info-supp{
        margin-top: 12px;
        .icone-enveloppe{
          position: relative;
          display: inline-block;
          width: 45px;
          height: 28px;
          border-radius: 50%;
          border: 1px solid var(--disabled-light);
          text-align: center;
          padding: 8px 0;
          img{
            width: 50%;
          }
          .counter{
            position: absolute;
            bottom: -5px;
            right: -8px;
            width: 22px;
            height: 22px;
            font-size: 15px;
            vertical-align: top;
            padding-top: 1px;
            background-color: var(--disabled-light);
            border-radius: 50%;
          }
        }
        .icone-call{
          display: inline-block;
          margin-left: 20px;
          width: 45px;
          height: 28px;
          border-radius: 50%;
          border: 1px solid var(--disabled-light);
          text-align: center;
          vertical-align: top;
          padding: 8px 0;
          position: relative;
          img{
            width: 50%;
          }
          .counter{
            position: absolute;
            bottom: -5px;
            right: -8px;
            width: 22px;
            height: 22px;
            font-size: 15px;
            vertical-align: top;
            padding-top: 1px;
            background-color: var(--disabled-light);
            border-radius: 50%;
          }
        }
        .icone-call2{
          display: inline-block;
          margin-left: 20px;
          width: 45px;
          height: 28px;
          border-radius: 50%;
          border: 1px solid var(--disabled-light);
          text-align: center;
          padding: 8px 0;
          position: relative;
          img{
            width: 50%;
          }
          .counter{
            position: absolute;
            bottom: -5px;
            right: -8px;
            width: 22px;
            height: 22px;
            font-size: 15px;
            vertical-align: top;
            padding-top: 1px;
            background-color: var(--disabled-light);
            border-radius: 50%;
          }
        }
      }
      .kit-list{
        .line{
          margin-left: 14px;
          margin-top: 20px;
          height: 100px;
          width: 1px;
          background-color: #7F7F7F;
          opacity: 0.2;
        }
        .info-supp{
          .title{
            color: grey;
            font-size: 13px;
            margin-bottom: 10px;
            font-weight: 500;
          }
          .iconHead{
            display: flex;
            justify-content: center;
            .icone{
              overflow: hidden;
              position: relative;
              display: inline-block;
              width: 40px;
              height: 20px;
              border-radius: 50%;
              border: 1px solid var(--disabled);
              background-color:  #F9FAFB;
              text-align: center;
              padding: 10px 0;
              img{
                margin-top: 1px;
                width: 50%;
              }
            }
            .counter{
              position: absolute;
              bottom: -8px;
              right: 0px;
              width: 22px;
              height: 22px;
              font-size: 15px;
              vertical-align: top;
              padding-top: 1px;
              text-align: center;
              background-color: var(--disabled-light);
              border-radius: 50%;
            }
          }
          .label{
            padding: 3px 2px;
            font-size: 8px;
            text-align: center;
          }
        }
      }
    }
    .content-box {
      background-color: white;
      border-radius: 15px;
      border: 1px solid rgba(241, 240, 240, 1);
      padding: 18px;

      .mintitle {
        font-size: 18px;
        margin-bottom: 30px;
      }

      .mintitle.custom-padding {
        font-size: 18px;
        margin-bottom: 10px;
      }

      .icon-content {
        display: inline-block;
        vertical-align: top;
        text-align: center;

        .icon {
          padding: 7px;
          width: 28px;
          font-size: 25px;
          color: #494949;
          height: 28px;
          border-radius: 50%;
          background-color: rgba(241, 240, 240, 1);
        }
      }

      .content {
        padding: 5px 0 0 19px;
        width: 70%;
        vertical-align: top;
        display: inline-block;

        .head {
          .iconMore {
            display: inline-block;
            cursor: pointer;
          }

          img {
            vertical-align: top;
            margin-left: 10px;
            width: 20px;
            cursor: pointer;
          }

          img.more {
            vertical-align: top;
            margin-left: 10px;
            margin-top: 2px;
            width: 17px;
            cursor: pointer;
          }
        }

        .element {
          margin: 13px 0;
          font-size: 13px;

          span {
            color: var(--disabled);
            margin-right: 5px;
          }

          img {
            vertical-align: top;
            margin-left: 10px;
            width: 20px;
            cursor: pointer;
          }
        }
      }

      .content-responsable {
        margin-bottom: 20px;
        border: 1px solid rgba(241, 240, 240, 1);
        border-radius: 15px;
        padding: 15px 15px;

        .user-info {
          width: 152px;
          display: inline-block;
          vertical-align: top;
          padding-left: 16px;
          padding-top: 13px;
        }

        .user-icon {
          width: 80px;
          height: 80px;
          display: inline-block;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .user-button {
          display: inline-block;
          vertical-align: top;
          padding-top: 30px;

          .ant-badge .ant-badge-count {
            font-size: 13px;
            height: 30px;
          }

          .ant-badge .ant-badge-multiple-words {
            padding: 4px 20px;
          }
        }
      }

      .content-aide {
        border: 1px solid rgba(241, 240, 240, 1);
        border-radius: 10px;
        padding: 5px 15px 1px 25px;
        margin-bottom: 30px;

        .user-info {
          width: 185px;
          display: inline-block;
          vertical-align: top;
          padding-left: 12px;
          padding-top: 10px;
        }

        .user-icon {
          width: 40px;
          height: 40px;
          display: inline-block;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .user-button {
          display: inline-block;
          vertical-align: top;
          padding-top: 5px;

          .ant-badge .ant-badge-count {
            font-size: 12px;
            height: 30px;
          }

          .ant-badge .ant-badge-multiple-words {
            padding: 4px 15px;
          }
        }
      }

      .instruction {
        padding-left: 2px;
        font-size: 13.4px;
        margin-bottom: 50px;

        span {
          color: var(--disabled);
          margin-right: 10px;
        }
      }

      .send-message {
        text-align: center;
        margin-bottom: 20px;

        .icon {
          display: inline-block;
          vertical-align: top;
          width: 20px;
          height: 20px;

          img {
            width: 100%;
          }
        }
      }

      .log {
        font-size: 13px;
        margin-bottom: 15px;

        .image {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(86, 111, 143, 0.1);
          text-align: center;
          vertical-align: top;
          padding: 3px;
          font-size: 15px;
          font-weight: bold;
          color: var(--text-grey-color);
        }

        .description {
          width: 330px;
          padding-left: 15px;
          display: inline-block;

          .libelle {
            color: var(--text-grey-color);

            span {
              color: black;
            }
          }

          .timer {
            color: var(--disabled);
          }
        }
      }

      .title-ttc {
        font-weight: bold;
        margin-bottom: 10px;
      }

      .price {
        font-size: 25px;
        font-weight: bold;
      }

      .a-regler {
        border-color: gray !important;
        background-color: rgba(241, 240, 240, 1);
        color: black;
      }

      .hors-taxe {
        color: var(--disabled);
        font-size: 12px;
      }

      .indication-facturation {
        vertical-align: top;
        color: var(--disabled);
        margin-top: 5px;
        font-size: 12px;
        margin-bottom: 10px;
      }

      .card-zone {
        border: 1px solid rgba(241, 240, 240, 1);
        border-radius: 10px;
        padding: 2px 15px 1px 2px;
        margin-bottom: 20px;
        height: 40px;

        .card-image {
          background-color: var(--disabled-light);
          height: 30px;
          vertical-align: top;
          margin-top: 5px;
          border-radius: 5px;
          width: 100%;
          margin-left: 5px;
          text-align: center;

          img {
            margin-top: -2px;
            width: 80%;
            border-radius: 5px;
          }
        }

        .card-number {
          padding-left: 15px;
          padding-top: 6px;
          vertical-align: top;
          margin-top: 5px;
          color: var(--disabled);
        }

        .check-image {
          padding-top: 12px;
          width: 15px;

          img {
            width: 100%;
          }
        }
      }

      .avis-text {
        margin-top: 50px;
        font-size: 13px;
        font-weight: bold;
      }

      .sticker {
        margin-top: 2px;
        padding: 10px;

        .only-sticker {
          cursor: pointer;
          font-size: 25px;
          width: 40px;
          height: 40px;
          text-align: center;
          border-radius: 50%;
          background-color: rgba(86, 111, 143, 0.1);
        }

        .only-sticker.active {
          background-color: var(--primary-color);
        }
      }

      .description-controle {
        font-size: 12px;
        color: var(--disabled);
      }

      .status-progress {
        padding-right: 10px;
        margin-top: 17px;
        margin-bottom: 25px;

        .label {
          width: 15%;
          display: inline-block;
          font-weight: 600;
        }

        .progresscontent {
          display: inline-block;
          width: 85%;
        }
      }

      .bloc-status-performence {
        margin-top: 10px;
        border-radius: 25px;
        border: 1px solid rgba(241, 240, 240, 1);
        padding: 0 10px 0 10px;

        .image-arrow {
          vertical-align: top;
          width: 100%;
          padding-top: 8px;
          font-size: 35px;
          color: gray;

          img {
            width: 100%;
            border-radius: 50%;
          }
        }

        .image-status-check {
          width: 100%;
          vertical-align: top;
          padding-top: 10px;

          img {
            width: 90%;
          }
        }

        .text {
          vertical-align: top;
          padding-left: 20px;
          padding-top: 18px;
          color: var(--disabled);
        }
      }

      .linkBottom {
        text-align: right;
        width: 100%;
        margin-top: 20px;

        a {
          float: right;
          margin-right: 5px;
          text-decoration: underline;
        }
      }
    }
  }
}


.evaluation-service {
  .evaluation {
    height: 700px;
    position: relative;

    .mintitle.custom-padding {
      font-size: 25px;
      margin-bottom: 0;
    }

    .description-controle {
      font-size: 15px;
      margin-bottom: 7px;
    }

    .choice-amelioration{
      .element{
        padding: 10px 20px;
        display: inline-block;
        border-radius: 50px;
        border: 1px solid gray;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 12px;
      }
      .element.activeMotif{
        color: var(--primary-color);
        border: 3px solid var(--primary-color);
        font-weight: bold;
      }

    }
    .sticker {
      margin-top: 2px;
      padding: 10px;

      .only-sticker {
        font-size: 35px;
        width: 50px;
        height: 50px;
        text-align: center;
        cursor: pointer;
        border-radius: 50%;
        background-color: rgba(86, 111, 143, 0.1);
      }

      .only-sticker.active {
        background-color: var(--primary-color);
      }

      .text {
        color: var(--disabled);
      }
    }

    .add-file {
      padding: 17px 10px;
      background-color: rgba(241, 240, 240, 1);
      border-radius: 10px;
      vertical-align: top;

      .image {
        vertical-align: top;
        padding-top: 6px;
        display: inline-block;
        width: 40px;

        img {
          width: 100%;
          border-radius: 10px;
        }
      }

      .text {
        vertical-align: top;
        padding-left: 10px;
        padding-top: 10px;
        display: inline-block;
      }
    }

    .footer-modal {
      position: absolute;
      width: 100%;
      bottom: 10px;

      .button-left {
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
      }

      .button-right {
        display: flex;
        justify-content: flex-end;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;

        span {
          color: black;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid black;
        }
      }

      .suivant {
        height: 30px;
        background-color: var(--primary-color);
        padding: 10px 20px 10px 20px;
        color: white;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        border-radius: 7px;
        width: 100%;
        //box-shadow: 0 0 5px var(--primary-color);
      }

      .disable {
        background-color: rgba(241, 240, 240, 1) !important;
      }

      .modifier {
        height: 25px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }
    }
  }
}

.annuler-prestation {
  padding: 11px;
  width: 225px;

  .head {
    .arrow {
      font-weight: bold;
      display: inline-block;
    }

    .title {
      font-weight: bold;
      margin-left: 20px;
      display: inline-block;
    }
  }

  .main-image {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;

    img {
      width: 40px;
    }
  }

  .annulation-button {
    margin-top: 50px;
    width: 100%;
    border: 0 !important;
    border-radius: 5px !important;
    background-color: red;
    color: white;
  }

  .annulation-button:hover {
    background-color: red !important;
    color: white !important;
  }
}

.reporter-prestation {
  padding: 11px;
  width: 235px;

  .head {
    .arrow {
      font-weight: bold;
      display: inline-block;
    }

    .title {
      font-weight: bold;
      margin-left: 20px;
      display: inline-block;
    }
  }

  .mintitlehead {
    padding: 25px 0;
  }

  .main-image {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;

    img {
      width: 40px;
    }
  }

  .primary-button {
    margin-top: 10px;
    width: 100%;
    border: 0 !important;
    border-radius: 5px !important;
    background-color: var(--primary-color);
    color: white;
  }

  .primary-button:hover {
    background-color: var(--primary-color) !important;
    color: white !important;
  }

  .ant-picker-calendar-header {
    display: none;
  }

  tr,
  td {
    height: 0px !important;
  }

  .ant-picker-calendar .ant-picker-cell {
    padding: 0 0 !important;
  }

  .custom-weekdays {
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;

    div {
      display: inline-block;
      text-align: center;
      font-size: 11px;
      width: 14.286%;
      font-weight: 600;
    }
  }

  .monthhead {
    padding-top: 5px;

    .left {
      border: 1px solid rgba(241, 240, 240, 1);
      padding: 1px 5px;
      text-align: left;
      font-size: 15px;
      display: inline-block;
      width: 8%;
      cursor: pointer;
      border-radius: 5px;
    }

    .month {
      vertical-align: top;
      padding-top: 5px;
      font-weight: bold;
      text-align: center;
      width: 72%;
      font-size: 17px;
      display: inline-block;
    }

    .right {
      text-align: right;
      width: 8%;
      font-size: 15px;
      display: inline-block;
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid rgba(241, 240, 240, 1);
      padding: 1px 5px;
    }
  }

  .ant-picker-calendar .ant-picker-cell {
    color: rgba(0, 0, 0, 0) !important;
  }

  .ant-picker-calendar .ant-picker-cell-in-view {
    color: rgba(0, 0, 0, 1) !important;
  }
}

.changer-creneau {
  background-color: var(--primary-color);
  border-radius: 25px !important;
  height: 250px;

  .ant-modal-content {
    border-radius: 15px !important;
    padding: 10px 20px;
  }

  .creneau {
    padding: 1px 1px 1px 1px;
    height: 350px;
    position: relative;

    .head {
      vertical-align: top;

      .arrow {
        width: 5%;
        font-weight: bold;
        display: inline-block;
      }

      .title {
        width: 85%;
        font-weight: bold;
        margin-left: 20px;
        display: inline-block;
        vertical-align: top;

        .title-in {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 0px;
        }
      }
    }

    .indication-creneau-inteervantion-modal {
      font-size: 13px;
      color: grey;
    }

    .separator {
      text-align: center;
      padding-top: 10px;
    }

    .footer-modal {
      position: absolute;
      width: 98%;
      bottom: 10px;

      .button-left {
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
      }

      .button-right {
        display: flex;
        justify-content: flex-end;
      }

      .suivant {
        height: 20px;
        background-color: var(--primary-color);
        padding: 7px 20px 10px 20px;
        color: white;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        border-radius: 7px;
        width: 100%;
        //box-shadow: 0 0 5px var(--primary-color);
      }

      .modifier {
        height: 18px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }
    }
  }

  .depot-linge {
    padding: 30px 50px;
    height: 780px;

    .ant-divider-horizontal {
      margin: 5px 0 !important;
    }

    .gestion-complete {
      .subTitle {
        margin-bottom: 10px;
      }
    }

    .blc-content {
      border: 1px solid rgba(241, 240, 240, 1);
      border-radius: 20px;
      padding: 0px 30px;

      .contour-point-gris {
        .counter-side {
          vertical-align: top;
          padding-top: 14px;
        }
      }
    }

    .qualite-linge-image-zone {
      width: 100%;
      margin-top: 10px;
      height: 60px;
      border-radius: 10px;
      overflow: hidden;
      border: 3px solid transparent;

      .qualite-linge-image {
        width: 100%;
        background-position: center;
      }
    }

    .footer-modal {
      position: absolute;
      width: 100%;
      bottom: 40px;

      .button-left {
        display: flex;
        justify-content: flex-start;
      }

      .button-right {
        display: flex;
        justify-content: flex-start;
      }

      .suivant {
        height: 20px;
        background-color: var(--primary-color);
        padding: 7px 20px 10px 20px;
        color: white;
        font-weight: 500;
        border-radius: 7px;
        //box-shadow: 0 0 5px red;
      }

      .modifier {
        height: 18px;
        background-color: white;
        padding: 7px 20px 10px 20px;
        color: var(--primary-color);
        font-weight: 500;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
      }

      .annuler {
        height: 20px;
        padding: 7px 0 10px 0;
        color: #404040;
        font-weight: 500;

        span {
          color: #404040;
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 2.3px solid #404040;
        }
      }

      .annuler-red {
        padding: 15px 20px;
        color: red !important;
        font-weight: 500;

        span {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 3px;
          border-bottom: 3px solid red;
        }
      }
    }
  }
}

.planning-add-dorpdown {
  padding-top: 5px;

  ul {
    li {
      padding: 10px 10px !important;
      height: 40px !important;
      cursor: pointer;

      .disable {
        background-color: transparent !important;
        color: var(--disabled-light) !important;
      }
    }
  }

  .ant-space-gap-col-small {
    column-gap: 0;
  }

  .ant-dropdown-menu-title-content {
    padding: 3px 0 !important;
  }

  .ant-dropdown-menu-item {
    padding: 10px 10px !important;
  }

  .text {
    padding-left: 10px;
  }
}

.controle-qualite {
  .controle {
    .description-controle {
      font-size: 12px;
      color: var(--disabled);
    }

    .status-progress {
      padding-right: 10px;
      margin-top: 17px;
      margin-bottom: 25px;

      .label {
        width: 15%;
        display: inline-block;
        font-weight: 600;
      }

      .progresscontent {
        display: inline-block;
        width: 85%;
      }
    }

    .bloc-status-performence {
      margin-top: 10px;
      border-radius: 25px;
      border: 1px solid rgba(241, 240, 240, 1);
      padding: 0 10px 0 10px;

      .image-arrow {
        vertical-align: top;
        width: 100%;
        padding-top: 8px;
        font-size: 35px;
        color: gray;

        img {
          width: 100%;
          border-radius: 50%;
        }
      }

      .image-status-check {
        width: 100%;
        vertical-align: top;
        padding-top: 10px;

        img {
          width: 90%;
        }
      }

      .text {
        vertical-align: top;
        padding-left: 20px;
        padding-top: 18px;
        color: var(--disabled);
      }
    }

    .mintitle.custom-padding {
      font-size: 18px;
      margin-bottom: 10px;
    }

    .listKit{
      .label{
        font-size: 13px;
        color: rgba(0,0,0,0.7);
      }
      .subtitle{
        font-weight: 600;
        margin-bottom: 10px;
      }
      .contentKit{
        padding: 0 10px;
      }
    }
  }
}

.detail-presta-dropdown {
  padding: 3px !important;
  border-radius: 4px;

  span {
    margin-left: 1px;
  }
}

.detail-presta-dropdown:hover {
  background-color: var(--disabled-light) !important;
}

.prestation-catalog {
  background-color: var(--primary-color);
  height: 100vh;
  overflow-y: scroll;
  padding-top: 120px;

  .catalog {
    margin-bottom: 20px;

    .catalog-title {
      color: white;
      font-size: 20px;
      font-weight: 600;
    }

    .catalog-content {
      margin-bottom: 10px;
    }

    .date {
      color: white;
      text-align: center;
      font-size: 14px;
    }
  }
}