.footer-notification{
  position: absolute;
  bottom: 0;
  font-size: 18px;
  font-weight: 300;
  height: 40px;
  text-align: center;
  color: white;
  padding-top: 11px;
  display: inline-block;
  background-color: limegreen;
  width: 100%;
  a{
    color: white;
    font-weight: 600;
  }
  a:hover{
    color: white;
    font-weight: 600;
  }
}