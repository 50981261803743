.sidebar {
    position: fixed;
    height: 100vh;

    .logo {
        height: 56px;

        img {
            height: 100%;
            margin-left: 20px;
        }

    }

    .icon {
        color: black;
    }

    .disable {
        .icon {
            color: var(--disabled);
        }

        border: 0px !important;
    }

    .disable:hover {
        .icon {
            color: var(--disabled);
        }

        a {
            color: var(--disabled) !important;
        }
    }

    .menu {
        padding: 0px 5px;

        .beforBottom {
            position: absolute;
            bottom: 0;
            width: 240px;

            .isImage {
                display: inline-block;
                vertical-align: top;

                img {
                    width: 16px;
                }
            }

            .mb-toBottom {
                margin-bottom: 50%;
            }

            .userBox {
                background-color: #f5f5f5;
                padding: 6px 8px;
                margin: 10px 10px;
                border-radius: 5px;
                cursor: default;

                .imgSide {
                    width: 35px;
                    height: 35px;
                    display: inline-block;
                    position: relative;

                    img {
                        width: 100%;
                        border-radius: 50%;
                    }

                    .online {
                        width: 10px;
                        height: 10px;
                        background-color: green;
                        position: absolute;
                        border-radius: 50%;
                        bottom: 0;
                        right: 0;
                    }
                }

                .nameSide {
                    font-weight: bold;
                    display: inline-block;
                    vertical-align: top;
                    padding-left: 10px;
                    padding-top: 10px;
                }
            }
        }

        ul {
            list-style: none;
            padding: 0px;

            li {
                margin: 7px 0px;
                padding: 4px 7px;

                a {
                    display: block;
                    border-radius: 5px;
                    padding: 10px 10px;
                    color: var(--text-grey-color);
                    font-family: 'Source Sans Pro', sans-serif !important;

                    span {
                        margin-left: 7px;
                    }
                }

                .active {
                    font-family: 'Source Sans Pro', sans-serif;
                    font-weight: 600;
                    background-color: rgba(251, 224, 209, 0.5);
                    color: #F67504;
                    //border: 1px solid rgba(255,127,10, 0.2);
                }
            }

            li:hover {
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 600;

                a {
                    color: var(--primary-color);
                }
            }
        }
    }

    .app-version {
        padding: 20px;
        color: #8080807d;
    }
}

.menuProfil-dropdown {

    //display: block !important;
    .ant-dropdown-menu {
        margin-bottom: 5px;
    }

    .ant-dropdown-menu-item {
        background-color: white !important;
    }

    .ant-dropdown-menu-item {
        padding: 8px 15px !important;

        .menuProfil {
            color: red !important;

            span {
                color: red;
            }

            svg {
                float: right;
            }
        }
    }
}